import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { Button, FormInput } from "../../../../components/common";
import { AuthNavbar, AuthSwitchLink, Container } from "../../components";
import { MainContainer, ResetPasswordContainer } from "./elements";
import { resetPasswordSchema } from "../../../../utils/validation";
import {
  authSelector,
  resetPassword,
  setError,
} from "../../../../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ToastMessage } from "../../../../components/common/ToastMessage";

export const ResetPassword = () => {
  const { isLoading, error } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data: { email: string }) => {
    dispatch(
      resetPassword(data, () => {
        navigate(`success?email=${data?.email}`);
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>Reset Password | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <MainContainer>
        <div className="w-50">
          <AuthNavbar />
          <ResetPasswordContainer>
            <div className="reset-password-container">
              <div className="title-container">
                <div className="title">Reset Your Password?</div>
                <div className="sub-title">
                  No worries, we'll send you reset instructions.
                </div>
              </div>

              <div className="form-container">
                <form
                  className="align-items-center d-flex flex-column w-100"
                  onSubmit={handleSubmit(onSubmit)}
                  method="POST"
                  noValidate
                >
                  <div className="input-field">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="user-email-id"
                          type="email"
                          width="100%"
                          placeholder="Enter your Email"
                          errorMessage={errors.email?.message}
                          hasError={!!errors.email}
                          label="Email"
                        />
                      )}
                      name="email"
                    />
                  </div>

                  <Button
                    label="Reset Password"
                    type="submit"
                    isLoading={isLoading}
                    className="w-100 reset-password"
                  />
                </form>
              </div>
              <AuthSwitchLink
                linkHref="/auth/login"
                linkText="Log In"
                text="Already have an account?"
                className="justify-content-center"
              />
            </div>
          </ResetPasswordContainer>
        </div>
        <div className="left-container">
          <Container />
        </div>
      </MainContainer>

      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
