import classNames from "classnames";
import { Link } from "../../../../components/common";
import { AuthSwitchLinkContainer } from "./elements";

export interface AuthSwitchLinkProps {
  linkHref?: string | undefined;
  linkText?: string;
  text?: string;
  onLinkClick?: () => void;
  className?: string;
}

export const AuthSwitchLink = ({
  linkHref,
  linkText,
  onLinkClick,
  text,
  className,
}: AuthSwitchLinkProps) => (
  <AuthSwitchLinkContainer
    className={classNames("align-items-center d-flex", className)}
  >
    <span className="mr-1 text">{text}</span>
    <div className="href-container">
      <Link className="href-text" onClick={onLinkClick} to={linkHref}>
        {linkText}
      </Link>
    </div>
  </AuthSwitchLinkContainer>
);
