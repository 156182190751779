import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const AddWorkflowRunModalContainer = styled(Modal)`
  .header {
    display: flex;
    width: 100%;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 1.25rem;
      color: rgb(0, 0, 0);
      padding-bottom: 2rem;
      button {
        background: transparent;
        outline: none;
        border: none;
        padding: unset;
      }
    }
  }
  .run-now-btn {
    margin-left: 1rem;
  }
  .workflow-run-modal-body {
    padding: 2.5rem;
  }
`;
