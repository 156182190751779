import { useForm } from "react-hook-form";
import { Button, FormInput } from "../../../../../../components/common";

import { TypeImport } from "../../../../../../utils/types";
import {
  importAPI,
  projectSelector,
} from "../../../../../../redux/slices/projectSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { useState } from "react";
import UploadFileIcon from "../../../../../../assets/icons/upload-file.svg";
import RemoveIcon from "../../../../../../assets/icons/close.svg";

interface InputProps {
  onAddManualy: () => any;
  onHide: () => any;
}

export const Import = ({ onAddManualy, onHide }: InputProps) => {
  const { apiCreateError, isLoading } = useAppSelector(projectSelector);
  const [onDrag, setOnDrag] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const dispatch = useAppDispatch();
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm({
    // resolver: yupResolver(),
    defaultValues: {
      file: "",
      url: "",
    },
  });

  const onUpload = (e: any) => {
    setUploadFile(e.target.files[0].name);
  };

  const isValidUrl = (urlString: string) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const onSubmit = (data: TypeImport) => {
    console.log("onSubmit", data);
    console.log(data.url);

    if (!data.url && !data.file) {
      setError("url", { type: "custom", message: "Please enter a url" });
      setError("file", { type: "custom", message: "Please select a file" });
      return;
    }

    if (data.url) {
      if (isValidUrl(data.url)) {
        dispatch(
          importAPI("url", data.url, () => {
            onHide();
          }),
        );
        return;
      } else {
        setError("url", {
          type: "custom",
          message: "Please enter a valid url",
        });
      }
      return;
    }

    if (data.file) {
      dispatch(
        importAPI("file", data.file, () => {
          onHide();
        }),
      );
      return;
    }
  };

  const onError = (errors: any, e: any) => {
    console.log("onError");
    console.log(errors, e);
  };

  return (
    <>
      {/* <h5>Add API</h5> */}
      <form className="d-flex flex-column w-100 form" method="POST" noValidate>
        <div className="d-flex flex-row align-items-center justify-content-center flex-column">
          <div className="input-field">
            <FormInput
              type="input"
              label="Enter the full URL to your OpenAPI spec file"
              width="100%"
              placeholder="https://example.com/openapi.yaml"
              errorMessage={errors?.url?.message}
              hasError={!!errors.url}
              {...register("url")}
            />
          </div>

          {/* <div className="m-3">
            <span>or</span>
          </div>

          <label htmlFor="formFileLg" className="form-label">
            Upload Swagger File
          </label> */}
          {/* <div className="w-100 mt-4">
            <input
              className="form-control"
              type="file"
              id="formFile"
              {...register("file")}
            />
          </div> */}
          {uploadFile ? (
            <div className="uploaded-file-container">
              <div>{uploadFile}</div>
              <div className="delete-logo">
                <button
                  type="button"
                  onClick={() => {
                    setUploadFile(null);
                  }}
                  id="delete-logo"
                >
                  <img src={RemoveIcon} alt="logo" />
                </button>
              </div>
            </div>
          ) : (
            <div
              onDragEnter={() => {
                setOnDrag(true);
              }}
              onDragLeave={() => {
                setOnDrag(false);
              }}
              id="drag-file"
              className="upload-file"
            >
              <div className={`upload-image ${onDrag ? "logo-drag" : ""}`}>
                <div className="upload-logo">
                  <img
                    src={UploadFileIcon}
                    className="drag-and-drop"
                    alt="logo"
                  />
                </div>
                <div className="upload-text">
                  Drag & Drop or
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    {...register("file")}
                    onChange={(e) => {
                      onUpload(e);
                    }}
                  />
                  <span className="drag-and-drop" id="swagger">
                    choose swagger file
                  </span>
                  to upload
                </div>
              </div>
            </div>
          )}
          {errors.file && (
            <p className="error-message">{errors.file.message}</p>
          )}
          <div className="text-danger mt-4">{apiCreateError}</div>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center flex-column">
          <div className="or-container">
            <hr />
            <div className="or">OR</div>
            <hr />
          </div>
          <button
            type="button"
            className="add-manully-button"
            onClick={() => {
              onAddManualy();
            }}
          >
            Add Manullay
          </button>
        </div>
        <div className="d-flex flex-row d-grid justify-content-end import-button-container">
          <div>
            <Button
              label="Cancel"
              type="reset"
              isCancel
              onClick={onHide}
              buttonStyles={{ borderRadius: "0.5rem" }}
            />
          </div>
          <div className="import-api-btn">
            <Button
              label="Import API"
              type="submit"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit, onError)}
              buttonStyles={{ borderRadius: "0.5rem" }}
            />
          </div>
        </div>
      </form>
    </>
  );
};
