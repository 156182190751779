import { useEffect, useMemo } from "react";

import { Helmet } from "react-helmet-async";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import moment from "moment";
import {
  fetchAPIList,
  projectSelector,
} from "../../../../redux/slices/projectSlice";
import { APISContainer } from "./elements";
import { Table } from "../../../../components/common/Table";
import { Button } from "../../../../components/common";
import PlusIcon from "../../../../assets/icons/add-white.svg";
import ViewIcon from "../../../../assets/icons/view-icon.svg";
import Loader from "../../../../components/common/Loader";

export const API = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { apis, isAPILoading } = useAppSelector(projectSelector);

  const apiColumns = useMemo(
    () => [
      {
        Header: "#",
        id: "index",
        accessor: (_row: any, i: number) => i + 1,
      },
      {
        Header: "API Name",
        accessor: "pia_title",
      },
      {
        Header: "URL",
        Cell: ({ value }: any) => (
          <div>{moment(value).format("DD MMMM YYYY")}</div>
        ),
        accessor: "pia_created_at",
      },
      {
        Header: "",
        Cell: ({ value }: any) => (
          <Link to={String(value)}>
            <img src={ViewIcon} alt="view" />
          </Link>
        ),
        accessor: "pia_id",
      },
    ],
    [apis],
  );

  useEffect(() => {
    if (id) dispatch(fetchAPIList(Number(id)));
  }, [id]);

  if (isAPILoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>API | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <APISContainer>
        <div className="title-button">
          {/* <Link to={"new"} className="btn btn-primary">
            + New API
          </Link> */}
          <div className="title">Your APIs</div>
          <Button
            label="New API"
            onClick={() => {
              navigate("new");
            }}
            icon={PlusIcon}
            iconPosition="left"
            buttonStyles={{
              borderRadius: "0.3125rem",
            }}
          />
        </div>
        {apis.length ? (
          <div className="table-container">
            <Table columns={apiColumns} data={apis} itemName="API" type={1} />
          </div>
        ) : null}
        <Outlet />
      </APISContainer>
    </>
  );
};
