import { useEffect, useState } from "react";
import classNames from "classnames";
import { Routes, Route, useParams } from "react-router-dom";

import { useAppDispatch } from "../../redux/hooks";
import { setActiveOrganisationId } from "../../redux/slices/organisationSlice";

import { BasicDetails, Billing, SideNavBar, Users } from "./components";
import { SettingsRoutesContainer } from "./elements";

export const SettingsRoutes = () => {
  const { orgId } = useParams();

  const dispatch = useAppDispatch();

  const [collapse, setCollapse] = useState<any | null>("");

  useEffect(() => {
    if (orgId) {
      dispatch(setActiveOrganisationId(Number(orgId)));
    }
  }, [orgId]);

  return (
    <>
      <SettingsRoutesContainer>
        <SideNavBar setCollapse={setCollapse} collapse={collapse} />
        <div
          className={classNames("main-container", {
            "is-collapsed": collapse,
          })}
        >
          {/* <Header /> */}
          <Routes>
            <Route path="/basic" element={<BasicDetails />} />
            <Route path="/users" element={<Users />} />
            <Route path="/billing" element={<Billing />} />
          </Routes>
        </div>
      </SettingsRoutesContainer>
    </>
  );
};
