import styled from "styled-components";

export const BillingContainer = styled.div`
  padding: 0.75rem 0.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      color: #000;
      font-size: 2rem;
      font-weight: 700;
      text-transform: capitalize;
      text-align: center;
      line-height: 1.1875;
    }
    .sub-title {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        color: #655aec;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        line-height: 1.219;
        max-width: 29.06rem;
      }
    }
  }
  .switch-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2.5rem;
    .switch-tabs-container {
      border-radius: 2.5rem;
      border: 0.0625rem solid #7890b6;
      width: 10.125rem;
      height: 2.1875rem;
      display: flex;
      align-items: center;

      .switch-tab {
        background: transparent;
        width: 49%;
        outline: none !important;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin: 0.1875rem; */
        height: 1.8125rem;
        .text {
          color: #000;
          font-size: 0.875rem;
          font-weight: 600;
        }
        &.is-monthly,
        &.is-yearly {
          border-radius: 2.5rem;
          background: #655aec;
          /* margin: 0rem 0.1875rem; */
          .text {
            color: #fff;
          }
        }
        &.is-monthly {
          width: 49.62%;
          margin: 0.1875rem 0.1875rem 0.1875rem 0;
        }

        &.is-yearly {
          width: 49.62%;
          margin: 0.1875rem 0 0.1875rem 0.1875rem;
        }
      }
    }
  }
  .main-billing-container {
    padding-top: 3rem;
  }
  .billing-container {
    align-items: self-end;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .billing-card {
    border-radius: 1.5625rem;
    border: 0.0625rem solid #d0d5dd;
    background: #fff;
    box-shadow: 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05);
    padding: 1.5rem;
    height: 30.5625rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20.125rem;
    .product-name {
      color: #000;
      font-size: 0.875rem;
      font-weight: 600;
    }
    &.is-team {
      padding: unset;
      width: 20.5rem;
      height: 32.4689375rem;
      border-radius: 1.591625rem;
      background: radial-gradient(
        53.7% 76.07% at 9.63% 17.59%,
        #928af5 0%,
        #665bec 100%
      );
      margin: 0rem 1.5rem;
      box-shadow: 0.127329171rem 0.127329171rem 0.318322927rem 0rem
        rgba(101, 90, 236, 0.25);
      .product-name {
        color: #fff;
        font-size: 0.8913125rem;
        font-weight: 600;
        padding: 1.5rem 0 0 1.5rem;
      }
      .header-left {
        position: relative;
        .tag {
          position: absolute;
          top: 1.5rem;
          right: 0.01rem;
          height: 2rem;
          display: flex;
          align-items: center;
          border-radius: 1.0625rem 0rem 0rem 1.0625rem;
          background: radial-gradient(
            231% 135.8% at 0.9% 2.98%,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          border: 0.0625rem solid #e7e7e7;
          border-right: unset !important;
          backdrop-filter: blur(0.0625rem);

          div {
            color: #fff;
            font-size: 0.875rem;
            font-weight: 600;
            padding: 0rem 0.5rem;
          }
        }
      }
      .team-divider {
        padding: 0rem 1.5rem;
        .divider {
          border-bottom: 0.0625rem solid #dddafa;
          margin: 1.584375 0 1.5rem 0;
        }
      }
      .features-div {
        padding: 0rem 1.5rem;
        div {
          color: #fff;
        }
      }

      .tag {
      }
    }
    .divider {
      border-bottom: 0.0625rem solid #efefef;
      margin: 1.46875rem 0rem 1.53125rem 0;
    }
  }
  .basic-plan-period,
  .team-plan-period {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .basic-plan-period {
    .basic {
      .symbol,
      .cost {
        color: #000;
        font-size: 1.75rem;
        font-weight: 700;
      }
    }
    .period {
      color: #222a38;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      margin-bottom: -0.475rem;
      margin-left: 0.25rem;
    }
  }
  .team-plan-period {
    .team {
      align-items: center;
      .symbol {
        margin-bottom: -0.275rem;
        fill: #fff;
        height: 2rem;
        width: 2rem;
        padding: 0.1875rem 0.5625rem;
        .text {
          color: #fff;
          text-shadow: 0rem 0rem 1.21711707115rem #fff,
            0rem 0rem 0.1273291707rem #9a96cf;
          font-size: 1.404375rem;
          font-weight: 600;
        }
      }

      .cost {
        color: #f0f0ff;
        font-size: 2.6215rem;
        font-weight: 700;
      }
    }
    .period {
      color: #222a38;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      margin-bottom: -0.775rem;
      margin-left: 0.25rem;
      color: #fff;
      font-size: 1.2733125rem;
      display: flex;
      align-items: center;
      .billed-yearly {
        border-radius: 0.5rem;
        border: 0.0625rem solid #7065ee;
        background: #fff;
        display: flex;
        align-items: center;
        height: 1.4375rem;
        margin-left: 1rem;
        .text {
          color: #675ced;
          font-size: 0.75rem;
          font-weight: 500;
          line-height: normal;
          padding: 0.25rem 0.375rem;
        }
      }
    }
  }
  .team-header {
    position: relative;

    .show-plan-team {
      position: absolute;
      top: 3.665625rem;
      left: 0.926875rem;
      line-height: normal;
    }
  }
  .show-plan {
    padding-top: 1rem;
    line-height: normal;
  }
  .enterprise {
    color: #000;
    font-size: 1.75rem;
    font-weight: 700;
  }

  .features-div {
    .features-list:not(:first-child) {
      padding-top: 1rem;
    }

    .features-list {
      display: flex;
      align-items: center;
    }
    .text {
      margin-left: 1rem;
      color: #000;
      font-size: 1rem;
      font-weight: 500;
      line-height: normal;
    }
  }
  .plan-button {
    padding: 0.875rem 0;
    outline: none !important;
    border-radius: 0.5rem;
    border: 0.0625rem solid #655aec;
    background: transparent;
    .plan-btn-text {
      color: #655aec;
      font-size: 1rem;
      font-weight: 700;
    }
    &.is-team {
      margin: 0 1.5rem 1.5rem 1.5rem;
      padding: 0.89125rem 0;
      background: #fff;
      border: none;
      .plan-btn-text {
        color: #655aec;
        font-size: 1.018625rem;
        font-weight: 600;
        line-height: 1.2733125rem;
      }
    }
    &.is-basic {
      background: #868686;
      opacity: 0.5;
      border: none;
      .plan-btn-text {
        color: #fff;
      }
    }
  }
`;
