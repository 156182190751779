import styled from "styled-components";

export const BasicDetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(248, 247, 251);
  display: flex;
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.25rem;
    button {
      padding: 0.625rem 1rem;
    }
  }
  .header {
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .divider {
    border-bottom: 0.0625rem solid #eaecf0;
    margin: 1.5rem 0rem;
  }
  .btn-divider {
    border-bottom: 0.0625rem solid #e3e5ea;
    margin: 1.5rem 0rem;
  }
  .org-logo-container {
    margin-bottom: 2rem;
    .logo-button-container {
      display: flex;
      align-items: center;
      .delete-button {
        margin-left: 1rem;
      }
    }
  }
  #file-input {
    display: none;
  }
  .update-button-div {
    padding: 0.438rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #655aec;

    &.is-change {
      border: 1px solid #655aec;
      background: #655aec;
      label {
        color: #fff !important;
        line-height: 1.25rem;
      }
    }
  }

  #file-input-button {
    color: #655aec;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }
  .logo-button-container {
    margin-top: 1.5rem;
  }
  .image-container {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 0.0625rem solid #fff;
    background: rgba(101, 90, 236, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
    }
    &.is-change {
      background: #fff;
      img {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  .delete-button {
    button {
      /* padding: 0.625rem; */
      .label {
        font-size: 1rem;
      }
      .icon {
        width: unset;
        height: unset;
      }
      :active,
      :hover {
        border: 0.0625rem solid #655aec;
      }
    }
  }
  .input-field {
    max-width: 51.385vw;
    line-height: 1.25rem;
    .label {
      margin-bottom: 0.375rem !important;
    }
    .input-container {
      height: 2.875rem;
    }
  }
  .cancel-btn {
    margin-right: 0.5rem;
  }
  .form {
    justify-content: space-between;
  }
`;
