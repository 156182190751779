import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import ArrowIcon from "../../../../../../assets/icons/way.svg";
import { useAppSelector } from "../../../../../../redux/hooks";
import { projectSelector } from "../../../../../../redux/slices/projectSlice";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;

  .project-title-text {
    color: #959698;
    text-transform: Capitalize !important;
  }
  .icon {
    display: flex;
    padding: 0 1rem;
  }
  .page-title {
    color: #959698;
    &.is-last {
      color: #101828;
    }

    margin-left: unset !important;
  }
  button {
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
  }
`;
interface TitleProps {
  titles: { id: number; title: string; navigation?: () => void }[];
}

export const Title = ({ titles }: TitleProps) => {
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { selectedProject } = useAppSelector(projectSelector);

  return (
    <TitleContainer>
      <button
        className="project-title-text"
        type="button"
        onClick={() => {
          navigate(`/org/${orgId}/project/${selectedProject?.value}/workflows`);
        }}
      >
        {selectedProject?.label}
      </button>
      <div className="icon">
        <img src={ArrowIcon} alt=">" />
      </div>
      {titles?.map((item, index) => {
        return (
          <div key={index + item.id} className="d-flex">
            <button
              type="button"
              className={classNames("page-title", {
                "is-last": titles.length === index + 1,
              })}
              onClick={item.navigation}
            >
              {item.title}
            </button>
            {titles.length > 1 && titles.length !== index + 1 ? (
              <div className="icon">
                <img src={ArrowIcon} alt=">" />
              </div>
            ) : null}
          </div>
        );
      })}
    </TitleContainer>
  );
};
