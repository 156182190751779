import React from "react";
import { Controller } from "react-hook-form";
import {
  Button,
  SelectInput,
} from "../../../../../../../../../components/common";
import { FormInput } from "../../../../../../../../../components/common";
import classNames from "classnames";
import { ScheculeContainer } from "../elements";
import { useAppDispatch } from "../../../../../../../../../redux/hooks";
import { createWorkflowTrigger } from "../../../../../../../../../redux/slices/projectSlice";
import { TriggerData } from "../../../../../../../../../utils/types";

interface ReusableScheduleFormProps {
  control: any;
  scheduleType: number;
  setScheduleType: any;
  setValue: any;
  errors: any;
  formType: "simple" | "cron";
  intervals: any[];
  startFrom: any[];
  Hours: any[];
  Minutes: any[];
  isSidebar: boolean;
  isTriggerLoading: boolean;
  handleSubmit: any;
  workflow: any;
}

const TimeScheduleForm: React.FC<ReusableScheduleFormProps> = ({
  control,
  scheduleType,
  setScheduleType,
  setValue,
  errors,
  formType,
  intervals,
  startFrom,
  Hours,
  Minutes,
  isSidebar,
  isTriggerLoading,
  handleSubmit,
  workflow,
}) => {
  const isSimpleForm = formType === "simple";
  const isCronForm = formType === "cron";

  // const handleSelectChange = (fieldName: string, value: any) => {
  //   setValue(fieldName, value);
  //   clearErrors(fieldName);
  // };

  // schedule type 1 for the simple schedule form
  // schedule type 2 for the cron schedule form

  const dispatch = useAppDispatch();
  const onSubmit = (data: TriggerData) => {
    if (data.scheduleType === 1) {
      clearData(2);
    }
    if (data.scheduleType === 2) {
      clearData(1);
    }

    if (workflow) {
      dispatch(createWorkflowTrigger(data, workflow?.pw_id));
    }
  };

  const clearData = (value: number) => {
    if (value == 1) {
      setValue("interval", null);
      setValue("startFrom", null);
      setValue("hrs", null);
      setValue("mins", null);
    }
    if (value == 2) {
      setValue("cronExpression", "");
    }
  };
  return (
    <>
      <ScheculeContainer
        onSubmit={handleSubmit(onSubmit)}
        isSidebar={isSidebar}
        method="POST"
      >
        <div className="tabs">
          <button
            type="button"
            className={classNames("tabs-button", {
              "is-active": scheduleType === 1,
            })}
            onClick={() => {
              setScheduleType(1);
            }}
            name="scheduleType"
          >
            Simple
          </button>
          <button
            type="button"
            className={classNames("tabs-button", {
              "is-active": scheduleType === 2,
            })}
            onClick={() => {
              setScheduleType(2);
            }}
            name="scheduleType"
          >
            Cron
          </button>
        </div>

        {isSimpleForm && (
          <div className="simple-container">
            <div
              className={classNames(
                { "interval-sidebar": isSidebar, "interval-input": !isSidebar },
                "input-field",
              )}
            >
              <Controller
                control={control}
                render={({ field }) => (
                  <SelectInput
                    {...field}
                    errorMessage={errors.interval?.message}
                    label="Interval"
                    options={intervals}
                    placeholder="Select the Interval"
                    id="interval"
                  />
                )}
                name="interval"
              />
            </div>
            <div
              className={classNames(
                { "start-from-sidebar": isSidebar, "start-from": !isSidebar },
                "input-field",
              )}
            >
              <Controller
                control={control}
                render={({ field }) => (
                  <SelectInput
                    {...field}
                    errorMessage={errors.startFrom?.message}
                    label="Start From"
                    options={startFrom}
                    placeholder="Select the Start Time"
                    id="startFrom"
                  />
                )}
                name="startFrom"
              />
            </div>
            <div
              className={classNames(
                { "hrs-sidebar": isSidebar, "hrs-input": !isSidebar },
                "input-field",
              )}
            >
              <Controller
                control={control}
                render={({ field }) => (
                  <SelectInput
                    {...field}
                    errorMessage={errors.hrs?.message}
                    label="HH"
                    options={Hours}
                    placeholder="Hours"
                    id="hrs"
                  />
                )}
                name="hrs"
              />
            </div>
            <div
              className={classNames(
                { "mins-sidebar": isSidebar, "mins-input": !isSidebar },
                "input-field",
              )}
            >
              <Controller
                control={control}
                render={({ field }) => (
                  <SelectInput
                    {...field}
                    errorMessage={errors.mins?.message}
                    label="MM"
                    options={Minutes}
                    placeholder="Minutes"
                    id="mins"
                  />
                )}
                name="mins"
              />
            </div>
          </div>
        )}

        {isCronForm && (
          <div>
            <Controller
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  id="cronExpression"
                  type="text"
                  width="100%"
                  placeholder="Enter Your Cron Expression"
                  errorMessage={errors.cronExpression?.message}
                  hasError={!!errors.cronExpression}
                  label="Cron Expression"
                />
              )}
              name="cronExpression"
            />
          </div>
        )}
        {isSidebar && (
          <div
            className={classNames("button-container", {
              "is-sidebar": isSidebar,
            })}
          >
            <Button
              label="Save"
              type="submit"
              isLoading={isTriggerLoading}
              isFullWidth={isSidebar}
              className="button"
            />
            <div className="button-divider" />
          </div>
        )}
        {!isSidebar && (
          <>
            <div className="button-divider" />
            <div className="button-container">
              <Button label="Save" type="submit" isLoading={isTriggerLoading} />
            </div>
          </>
        )}
      </ScheculeContainer>
    </>
  );
};

export default TimeScheduleForm;
