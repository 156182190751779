import { ActionsCardContainer } from "./elements";

import TriggerIcon from "../../../../../../../../assets/icons/trigger.svg";
import ApiRequest from "../../../../../../../../assets/icons/api-blue.svg";
import GotoUrl from "../../../../../../../../assets/icons/go-to-url-white.svg";
import { ReactComponent as DeleteIconBlack } from "../../../../../../../../assets/icons/delete-black.svg";
import { ReactComponent as DeleteIconRed } from "../../../../../../../../assets/icons/delete-red.svg";
import Message from "../../../../../../../../assets/icons/message-white.svg";
import WarningIcon from "../../../../../../../../assets/icons/warning.svg";
import ClockIcon from "../../../../../../../../assets/icons/clock-small.svg";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/hooks";
import { useState } from "react";
import { CustomModal } from "../../../../../../../../components/common";
import {
  deleteWorkflowStep,
  projectSelector,
  setIsSelected,
} from "../../../../../../../../redux/slices/projectSlice";
import { useParams } from "react-router-dom";
import { actionCardDataProp } from "../../../../../../../../utils/types";

interface ActionsCardProp {
  data: actionCardDataProp;
  onNodeClick: () => void;
  selected: boolean;
  onCloseIconClick: (event: any) => void;
}

export const ActionsCard = ({
  data,
}: // selected,
// onNodeClick,
ActionsCardProp) => {
  const dispatch = useAppDispatch();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { workflow } = useAppSelector(projectSelector);
  const { workflowId } = useParams();
  const interval =
    workflow?.workflowTrigger?.pwt_trigger_value?.simple?.interval;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const renderIcon = (type: number) => {
    switch (type) {
      case 0:
        return TriggerIcon;

      case 1:
        return ApiRequest;

      case 2:
        return Message;

      case 3:
        return GotoUrl;

      default:
        return;
    }
  };
  console.log(interval);
  return (
    <>
      <ActionsCardContainer>
        <div className="left">
          <div className="icon-container">
            {/* Workflow Steps
       value 0 = Trigger
       value 1 = Make an API Request
       value 2 = Show Message
      value 3 = Go to URL  */}
            <img
              className="icon-container-api"
              src={renderIcon(data.type)}
              alt=""
            />
          </div>
          <div className="titles">
            <div>{data.title}</div>
            {data.type === 0 && interval && (
              <div className="schedule-text">Schedule</div>
            )}
          </div>
          {data.type === 0 && interval && (
            <div className="interval">
              <img src={ClockIcon} alt="" />
              <div className="interval-time">Every {interval}</div>
            </div>
          )}
        </div>
        {data.type !== 0 ? (
          <div
            className="button-del"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button
              type="button"
              onClick={(e) => {
                // stop parent event handlers being executed
                e.stopPropagation();
                setDeleteId(Number(data?.id));
              }}
              className="delete-btn"
            >
              {isHovered ? (
                <DeleteIconRed className="delete-btns" />
              ) : (
                <DeleteIconBlack className="delete-btns" />
              )}
            </button>
          </div>
        ) : null}
        <CustomModal
          show={!!deleteId}
          onHide={() => {
            setDeleteId(null);
          }}
          onClick={() => {
            setIsDeleting(true);
            // setMessage("Workflow Action is deleted successfully");
            dispatch(
              deleteWorkflowStep(Number(workflowId), Number(data.id), () => {
                setIsDeleting(false);
                setDeleteId(null);
              }),
            );
            setTimeout(() => {
              dispatch(setIsSelected(false));
            }, 0);
          }}
          label="Confirm"
          isLoading={isDeleting}
          title="Confirm Delete"
          subTitle="Are you sure you want to delete this action from workflow ?"
          isDelete={true}
          icon={WarningIcon}
        />
      </ActionsCardContainer>
    </>
  );
};
