import styled from "styled-components";

export const SettingsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 3.25rem 2.5rem 2rem 3.25rem;
  .view-logs-btn {
    border: 0.0625rem solid #655aec;
    border-radius: 0.3125rem;
    background: transparent;
  }
  .view-logs {
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.875rem;
    color: #655aec;
  }
  .title-container {
    padding: 1rem 1.25rem;
    background: #ffffff;
    border: 0.0625rem solid #f2f2f2;
    box-shadow: 0px 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.03);
    border-radius: 0.75rem;
  }
  .title {
    font-weight: 500;
    font-size: 1rem;
    color: #000000;
  }
  .recent-activity {
    margin: 2.5rem 0 1rem 0;
    font-weight: 500;
    font-size: 1.25rem;
    color: #000000;
  }
`;
