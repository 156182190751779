import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import { useForm, Controller } from "react-hook-form";
import LockIcon from "../../../../assets/icons/lock.svg";
import { ChangePasswordContainer } from "./elements";
import { Button, FormInput } from "../../../../components/common";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  authSelector,
  updatePassword,
} from "../../../../redux/slices/authSlice";

import { resetPassword } from "../../../../utils/validation";

export const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector(authSelector);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPassword),
    defaultValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = (data: any) => {
    if (user) {
      data.us_id = user.us_id;
      dispatch(
        updatePassword(data, "", () => {
          navigate("/auth/login");
        }),
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>Change Password | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <ChangePasswordContainer>
        {/* <h5 className=" pb-2">Change your password</h5>

        <div className="container">
          <div className="row">
            <div className="col-4">
              <Form onSubmit={handleSubmit(onSubmit)} method="POST">
                <Form.Group className="mb-3 mt-3" controlId="formPassword">
                  <Form.Label>Current Password</Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="password"
                        placeholder="Enter your password"
                        name="password"
                      />
                    )}
                    name="password"
                  />
                  {errors?.newPassword && (
                    <Form.Text className="text-danger">
                      <>{errors?.password?.message}</>
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formNewPassword">
                  <Form.Label>New Password</Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="password"
                        placeholder="Enter new password"
                        name="password"
                      />
                    )}
                    name="newPassword"
                  />
                  {errors?.newPassword && (
                    <Form.Text className="text-danger">
                      <>{errors?.newPassword?.message}</>
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formConfirmNewPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="password"
                        placeholder="Confirm new password"
                        name="password"
                      />
                    )}
                    name="confirmNewPassword"
                  />
                  {errors?.confirmNewPassword && (
                    <Form.Text className="text-danger">
                      <>{errors?.confirmNewPassword?.message}</>
                    </Form.Text>
                  )}
                </Form.Group>

                <Button variant="primary" type="submit">
                  Reset Password
                </Button>
              </Form>
            </div>
          </div>
        </div> */}
        <div className="change-password-container">
          <div className="title-container">
            <div className="icon-container"></div>
            <div className="img-container">
              <img src={LockIcon} alt="" />
            </div>
            <div className="title">Change your password</div>
            <div className="sub-title">
              Enter a new password below to change your password
            </div>
          </div>
          <div className="form-container">
            <form
              className="align-items-center d-flex flex-column w-100"
              onSubmit={handleSubmit(onSubmit)}
              method="POST"
              noValidate
            >
              <div className="input-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      id="user-password"
                      type="password"
                      width="100%"
                      placeholder="Enter your password"
                      errorMessage={errors.currentPassword?.message}
                      hasError={!!errors.currentPassword}
                      label="Current Password"
                    />
                  )}
                  name="currentPassword"
                />
              </div>
              <div className="input-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      id="user-password"
                      type="password"
                      width="100%"
                      placeholder="Enter new password"
                      errorMessage={errors.password?.message}
                      hasError={!!errors.password}
                      label="New Password"
                    />
                  )}
                  name="password"
                />
              </div>
              <div className="input-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      id="user-password"
                      type="password"
                      width="100%"
                      placeholder="Confirm new password"
                      // autoComplete="disabled"
                      errorMessage={errors.confirmPassword?.message}
                      hasError={!!errors.confirmPassword}
                      label="Confirm Password"
                    />
                  )}
                  name="confirmPassword"
                />
              </div>

              <Button
                label="Reset Password"
                type="submit"
                // isLoading={isLoading}
                className="w-100 reset-password"
              />
            </form>
          </div>
        </div>
      </ChangePasswordContainer>
    </>
  );
};
