import React, { useEffect, useState } from "react";
import {
  useForm,
  Controller,
  useFieldArray,
  SubmitHandler,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MakeAPIRequestSChema } from "../../../../../../../../../utils/validation";
import {
  Button,
  FormInput,
  SelectInput,
} from "../../../../../../../../../components/common";
import CloseIcon from "../../../../../../../../../assets/icons/remove-icon.svg";
import PlusIcon from "../../../../../../../../../assets/icons/add.svg";
import { FormTextArea } from "../../../../../../../../../components/common/FormTextArea";
import { MakeAnAPIRequestContainer } from "../elements";
import classNames from "classnames";
import { FormValue } from "../../../../../../../../../utils/types";

const httpMethod = [
  { value: 1, label: "GET" },
  { value: 2, label: "POST" },
  { value: 3, label: "Delete" },
  { value: 4, label: "PUT" },
  { value: 5, label: "PATCH" },
];

const tabs = [
  { tabId: 1, tabName: "Authorisation" },
  { tabId: 2, tabName: "Headers" },
  { tabId: 3, tabName: "Body" },
];

interface ApiRequestFormProps {
  defaultValues: FormValue;
  onSubmit: SubmitHandler<FormValue>;
  isSidebar: boolean;
  isLoading?: boolean;
}

const ApiRequestForm: React.FC<ApiRequestFormProps> = ({
  defaultValues,
  onSubmit,
  isSidebar,
  isLoading,
}) => {
  const [currentValues, setCurrentValues] = useState<FormValue>(defaultValues);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm<FormValue>({
    resolver: yupResolver(MakeAPIRequestSChema),
    defaultValues: currentValues,
  });
  const { fields, append, remove } = useFieldArray({
    name: "requestHeaders",
    control,
  });

  const selectedHttpMethod = getValues("httpMethod")?.value || 1;
  const [tab, setTab] = useState(tabs[0]);

  useEffect(() => {
    setCurrentValues(defaultValues);
  }, [defaultValues]);

  return (
    <MakeAnAPIRequestContainer onSubmit={handleSubmit(onSubmit)}>
      <div className="action-divider" />
      <div className="http-details-container">
        <div className="row">
          <div
            className={classNames("col-3", {
              "is-sidebar": isSidebar,
            })}
          >
            <Controller
              control={control}
              render={({ field }) => (
                <SelectInput
                  {...field}
                  placeholder="Select HTTP Method"
                  label="HTTP Method"
                  errorMessage={errors.httpMethod?.message}
                  options={httpMethod}
                />
              )}
              name="httpMethod"
            />
          </div>
          <div
            className={classNames("col-9", {
              "is-sidebar": isSidebar,
            })}
          >
            <Controller
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  id="http-url-id"
                  label="HTTP Url"
                  placeholder="Enter HTTP URL"
                  errorMessage={errors?.httpUrl?.message}
                  hasError={!!errors.httpUrl}
                />
              )}
              name="httpUrl"
            />
          </div>
        </div>
        <div className="tabs mt-4">
          <button
            type="button"
            className={classNames("tabs-button", {
              "is-active": tab.tabId === 1,
            })}
            onClick={() => {
              setTab(tabs[0]);
            }}
          >
            Authorisation
          </button>
          <button
            type="button"
            className={classNames("tabs-button ms-3", {
              "is-active": tab.tabId === 2,
            })}
            onClick={() => {
              setTab(tabs[1]);
            }}
          >
            Headers
          </button>

          {selectedHttpMethod == 2 ||
          selectedHttpMethod == 4 ||
          selectedHttpMethod == 5 ? (
            <button
              className={classNames("tabs-button ms-3", {
                "is-active": tab.tabId === 3,
              })}
              onClick={() => {
                setTab(tabs[2]);
              }}
              type="button"
            >
              Body
            </button>
          ) : null}
        </div>
        {tab.tabId === 1 ? (
          <div>
            <div className="auth-user">
              <Controller
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    id="auth-username"
                    label="HTTP Basic Auth Username"
                    placeholder="Enter auth username"
                    errorMessage={errors?.authUserName?.message}
                    hasError={!!errors.authUserName}
                  />
                )}
                name="authUserName"
              />
            </div>
            <div className="auth-password">
              <Controller
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    id="auth-password"
                    label="HTTP Basic Auth Password"
                    placeholder="Enter auth password"
                    errorMessage={errors?.authPassword?.message}
                    hasError={!!errors.authPassword}
                  />
                )}
                name="authPassword"
              />
            </div>
          </div>
        ) : null}
      </div>
      {tab.tabId === 2 ? (
        <div className="request-headers">
          <div className="headers-values mb-3">
            <div className="cols">Header</div>
            <div className="cols">Value</div>
          </div>
          <div className="headers-values-div w-100">
            {fields.map((field, index) => {
              return (
                <div key={field.id} className="rows d-flex w-100">
                  {/* <section className={"section"} key={field.id}> */}
                  {/* <td className="headers-values"> */}
                  <div className="cols  w-50 header-div">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="header-id"
                          placeholder="Enter Header"
                          errorMessage={
                            errors?.requestHeaders?.[index]?.header?.message
                          }
                          hasError={!!errors?.requestHeaders?.[index]?.header}
                          className="inputs"
                        />
                      )}
                      name={`requestHeaders.${index}.header` as const}
                    />
                  </div>
                  <div className="cols d-flex w-50 align-items-center">
                    {" "}
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="value-id"
                          placeholder="Enter Value"
                          errorMessage={
                            errors?.requestHeaders?.[index]?.value?.message
                          }
                          hasError={!!errors?.requestHeaders?.[index]?.value}
                          className="inputs"
                        />
                      )}
                      name={`requestHeaders.${index}.value` as const}
                    />
                    <button
                      className="close-button"
                      onClick={() => remove(index)}
                    >
                      <img src={CloseIcon} alt="close" className="close-icon" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="mt-4 add-button-header">
            <Button
              type="button"
              label="Add Header"
              icon={PlusIcon}
              onClick={() =>
                append({
                  header: "",
                  value: "",
                })
              }
              iconPosition="left"
              buttonStyles={{
                borderRadius: "0.3125rem",
              }}
              isCancel
            />
          </div>
        </div>
      ) : null}

      {tab.tabId === 3 ? (
        <div>
          {selectedHttpMethod == 2 ||
          selectedHttpMethod == 4 ||
          selectedHttpMethod == 5 ? (
            <div className="request-body">
              <Controller
                control={control}
                render={({ field }) => (
                  <FormTextArea
                    {...field}
                    id="reuqest-body-id"
                    width="100%"
                    placeholder="Write body content"
                    errorMessage={errors.requestBody?.message}
                    hasError={!!errors.requestBody}
                    label="Request Body"
                    rows={6}
                  />
                )}
                name="requestBody"
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {isDirty ? (
        <>
          <div className="button-divider" />
          <div className="button-container">
            <Button
              className={classNames("button-save", {
                "is-sidebar": isSidebar,
              })}
              label="Save"
              type="submit"
              isLoading={isLoading}
              isDisabled={!isDirty}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </>
      ) : null}
    </MakeAnAPIRequestContainer>
  );
};

export default ApiRequestForm;
