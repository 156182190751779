import { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
  Background,
  Node,
  Edge,
  ConnectionMode,
  ReactFlowProvider,
  EdgeProps,
} from "reactflow";
import "reactflow/dist/style.css";
import { ButtonEdge, EmptyEdge } from "./components/Edges";

import "./actions.css";
import { nodeTypes } from "./components/Nodes";
import { SideBar } from "./components/SideBar";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  projectSelector,
  setIsSelected,
  setSelectedNode,
} from "../../../../../../redux/slices/projectSlice";
import { WorkflowSteps } from "../../../../../../utils/types";

//initial position
const POSITIONY = 140;

//initial nodes
const initialNodes: Node[] = [
  {
    id: "node_1",
    type: "trigger",
    data: { title: "Trigger", type: 0, id: "node_1" },
    position: {
      x: 100,
      y: POSITIONY,
    },
  },
  {
    id: "empty",
    type: "empty",
    data: { title: `Empty Node`, type: 0, id: "empty" },
    position: {
      x: 100,
      y: POSITIONY * 2,
    },
  },
];

//initial edges
const initialEdges: Edge[] = [
  {
    id: "e2-empty",
    source: "node_1",
    target: "empty",
    data: {
      label: "edge label",
    },
    type: "emptyedge",
  },
];

export const Actions = () => {
  const dispatch = useAppDispatch();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { workflowSteps, isSelected, selectedNode } =
    useAppSelector(projectSelector);
  const [workflowStep, setWorkflowStep] = useState<WorkflowSteps>(
    workflowSteps[1],
  );
  const reactFlowWrapper = useRef(null);

  //for creating random edge id
  function getRandomEdgeId(existingEdgeIds: Set<string>): string {
    let edgeId: string;
    do {
      edgeId = Math.floor(Math.random() * 100000).toString(); // Adjust the range as needed
    } while (existingEdgeIds.has(edgeId));
    return edgeId;
  }

  // Memoize nodes, edges, and edgeTypes
  const memoizedNodes = useMemo(() => nodes, [nodes]);
  const memoizedEdges = useMemo(() => edges, [edges]);
  const memoizedEdgeTypes = useMemo(
    () => ({
      buttonedge: (props: EdgeProps) => (
        <ButtonEdge {...props} setIsSelected={setIsSelected} />
      ),
      emptyedge: (props: EdgeProps) => (
        <EmptyEdge {...props} setIsSelected={setIsSelected} />
      ),
    }),
    [],
  );

  // function removeDuplicateEdges(edges: Edge[]) {
  //   const uniqueEdges = [];
  //   const edgeSet = new Set();

  //   for (const edge of edges) {
  //     const edgeKey =
  //       edge.source < edge.target
  //         ? `${edge.source}-${edge.target}`
  //         : `${edge.target}-${edge.source}`;

  //     if (!edgeSet.has(edgeKey)) {
  //       uniqueEdges.push(edge);
  //       edgeSet.add(edgeKey);
  //     }
  //   }

  //   return uniqueEdges;
  // }

  let sortedWorkflowSteps = workflowSteps;

  //useEffect for creating nodes and edges from workflow steps in correct order
  useEffect(() => {
    let updatedNodes: Node[] = [
      {
        id: "node_1",
        type: "trigger",
        data: { title: "Trigger", type: 0, id: "node_1" },
        position: {
          x: isSelected ? 200 : 400,
          y: POSITIONY,
        },
      },
    ];
    let updatedEdges: Edge[] = [];
    let prevNodeId = "node_1";
    const existingEdgeIds = new Set(updatedEdges.map((edge) => edge.id));

    if (workflowSteps && workflowSteps.length > 0) {
      // Sort workflowSteps by pwst_order
      sortedWorkflowSteps = [...workflowSteps].sort(
        (a, b) => a.pwst_order - b.pwst_order,
      );

      // Initialize the Y position
      let yPos = POSITIONY + 140;

      // Iterate over the sorted array
      sortedWorkflowSteps.forEach((workflowStep) => {
        const nodeId = `${workflowStep.pwst_id}`;

        // Create a new node for each workflow step
        const newNode: Node = {
          id: nodeId,
          type: "action",
          position: { x: isSelected ? 200 : 400, y: yPos },
          data: {
            title:
              workflowStep.pwst_step_type === 1
                ? "Make an API Request"
                : workflowStep.pwst_step_type === 2
                ? "Show Message"
                : workflowStep.pwst_step_type === 3
                ? "Go to URL"
                : "Node",
            type: workflowStep.pwst_step_type,
            id: nodeId,
            workflow: workflowStep.pwst_workflow_id,
          },
        };

        updatedNodes.push(newNode);

        // Create an edge between the previous node and the current node
        const edgeId = getRandomEdgeId(existingEdgeIds);
        const newEdge: Edge = {
          id: edgeId,
          source: prevNodeId,
          target: nodeId,
          data: {
            label: "edge label",
          },
          type: "buttonedge",
        };

        updatedEdges.push(newEdge);

        prevNodeId = nodeId;

        // Increment Y position for the next node
        yPos += 140;
      });
    }

    // Remove any nodes and edges involving the "empty" node
    updatedNodes = updatedNodes.filter((node) => node.id !== "empty");
    updatedEdges = updatedEdges.filter(
      (edge) => edge.source !== "empty" && edge.target !== "empty",
    );

    // Create or update the "empty" node and its edge
    const lastActionNodeId =
      sortedWorkflowSteps[sortedWorkflowSteps.length - 1]?.pwst_id.toString() ||
      "node_1";
    const lastActionNode = updatedNodes.find(
      (node) => node.id === lastActionNodeId,
    );
    const emptyNodeY = lastActionNode
      ? lastActionNode.position.y + POSITIONY
      : POSITIONY * 2;
    //add empty node in the last of nodes always
    const emptyNode: Node = {
      id: "empty",
      type: "empty",
      position: { x: isSelected ? 200 : 400, y: emptyNodeY - 14 },
      data: {
        title: "Empty Node",
        type: 2,
        id: "empty",
      },
    };

    updatedNodes.push(emptyNode);

    const newEdgeId = getRandomEdgeId(existingEdgeIds);
    const newEmptyEdge: Edge = {
      id: newEdgeId,
      source: lastActionNodeId,
      target: "empty",
      type: "emptyedge",
    };

    updatedEdges.push(newEmptyEdge);

    // Update the component's state
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  }, [workflowSteps, isSelected]);

  const selectedNodeId = selectedNode?.id;
  const selectedNodeIdAsNumber = parseInt(selectedNodeId);

  // to select workflow step to pass to sidebar
  useEffect(() => {
    if (!isNaN(selectedNodeIdAsNumber)) {
      const matchingWorkflowStep = workflowSteps.find(
        (workflowStep) => workflowStep.pwst_id === selectedNodeIdAsNumber,
      );
      if (matchingWorkflowStep) {
        setWorkflowStep(matchingWorkflowStep);
      }
    }
  }, [selectedNodeIdAsNumber, workflowSteps]);

  //handle nodeclick functionalities
  const handleNodeClick = (event: React.MouseEvent, node: Node) => {
    // If a different node is clicked, deselect the previously selected node
    if (selectedNode && selectedNode.id !== node.id) {
      dispatch(setIsSelected(false));
      dispatch(setSelectedNode(null));
    }
    if (node.id !== "empty") {
      dispatch(setSelectedNode(node));
      dispatch(setIsSelected(true));
    }
  };

  // useEffect(() => {
  //   if (selectedNode) {
  //     const updatedNodes = memoizedNodes.map((node) => {
  //       if (node.id === selectedNode.id) {
  //         return {
  //           ...node,
  //           data: {
  //             ...node.data,
  //             title: nodeName,
  //           },
  //         };
  //       }
  //       return node;
  //     });
  //     setNodes(updatedNodes);
  //   }
  // }, [nodeName, selectedNode]);
  // First useEffect to fetch and update workflow steps

  return (
    <div
      className={classNames("dndflow", {
        "is-selected": isSelected,
      })}
    >
      <ReactFlowProvider>
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={memoizedNodes}
            edges={memoizedEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            snapToGrid={true}
            edgeTypes={memoizedEdgeTypes}
            nodeTypes={nodeTypes}
            attributionPosition="top-right"
            connectionMode={ConnectionMode.Loose}
            nodesDraggable={false}
            onNodeClick={handleNodeClick}
          >
            <Controls />
            <Background />
            <MiniMap />
          </ReactFlow>
        </div>
        {isSelected ? (
          <SideBar workflowStep={workflowStep} selectedNode={selectedNode} />
        ) : null}
      </ReactFlowProvider>
    </div>
  );
};
