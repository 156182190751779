import styled from "styled-components";

export const ProjectsContainer = styled.div`
  /* height: 100%; */
  min-height: 100vh;
  background: rgb(248, 247, 251);
  .main-container {
    display: flex;
  }
  .data-container {
    width: 100%;
  }
  .project-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #000000;
    text-transform: capitalize;
    padding: 2.5rem 2.5rem 0 2.5rem;
  }
  .content {
    background: #f8f7fb;
    padding-top: 5rem;
  }
`;
