import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { forwardRef } from "react";

import CheckIcon from "../../../assets/icons/checked.svg";
import { CheckboxContainer } from "./elements";

interface CheckBoxProps {
  hasError?: boolean;
  label?: string;
  subLabel?: string;
  value?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string | undefined | null;
}
type CheckBoxRef = HTMLInputElement;

export const Checkbox = forwardRef<CheckBoxRef, CheckBoxProps>(
  ({ hasError, label, subLabel, onChange, value, errorMessage }, ref) => (
    <CheckboxContainer type="button">
      <label className="align-items-center d-flex mb-0" htmlFor={label}>
        <div
          className={classNames("icon-container", "mr-2", {
            "has-error": hasError,
            "is-checked": value,
          })}
        >
          <img alt="Checked" className="icon" src={CheckIcon} />
        </div>
        <span className="label">{label}</span>
        {subLabel ? <span className="sub-label">{subLabel}</span> : null}
      </label>
      <input
        checked={value}
        className="d-none"
        id={label}
        onChange={onChange}
        ref={ref}
        type="checkbox"
      />
      <AnimatePresence>
        {errorMessage ? (
          <motion.span
            animate={{
              opacity: 1,
              translateY: 0,
            }}
            className="error-message"
            exit={{
              opacity: 0,
              translateY: "-0.25rem",
            }}
            initial={{
              opacity: 0,
              translateY: "-0.25rem",
            }}
            transition={{
              duration: 0.2,
              type: "keyframes",
            }}
          >
            {errorMessage}
          </motion.span>
        ) : null}
      </AnimatePresence>
    </CheckboxContainer>
  ),
);

Checkbox.displayName = "CheckBox";
