// import { useState } from "react";
import { useDispatch } from "react-redux";
import { PlanAndBillingContainer } from "./elements";

import { Button } from "../../../../../../components/common";

import UpgradeArrow from "../../../../../../assets/icons/upgrade-arrow.svg";
// import WorkflowIcon from "../../../../../../assets/icons/workflow-active.svg";
import { subscribedPlanAction } from "../../../../../../redux/slices/billingSlice";
import { SubscriptionPlan } from "../../../../../../utils/types";

// const Progress = ({ percentage }: { percentage: string }) => {
//   const [style, setStyle] = useState({});

//   setTimeout(() => {
//     const newStyle = {
//       opacity: 1,
//       width: `${percentage}%`,
//     };
//     setStyle(newStyle);
//   }, 200);

//   return (
//     <div className="progress">
//       <div className="progress-done" style={style}></div>
//     </div>
//   );
// };

export const PlanAndBilling = ({
  planName,
  price,
  plan,
  id,
  customer,
  paymentFrequency,
}: SubscriptionPlan) => {
  const dispatch = useDispatch();
  console.log(plan, id, customer);
  let planPrice;

  if (price) {
    planPrice = Number(price);
    // per month cost on yearly package
    const temp = paymentFrequency.split(" ");

    if (temp[1] === "year(s)") {
      planPrice = planPrice / 12;
    }
  }

  const onClick = () => {
    dispatch(
      subscribedPlanAction((redirect_url) => {
        window.location.replace(redirect_url);
      }),
    );
  };

  return (
    <PlanAndBillingContainer>
      <div className="header">Plan And Billing</div>
      <div className="billing-details-container">
        {/* top content */}
        <div className="plan-details-container">
          <div className="plan-details">
            <div className="plan-name">
              <div className="title">Plan</div>
              <div className="value">{planName}</div>
            </div>
            <div className="upgrade-container">
              <button type="button" className="btn-upgrade" onClick={onClick}>
                <div className="text-img">
                  <div className="text">Upgrade</div>
                  <div className="icon-container">
                    <img src={UpgradeArrow} alt="" />
                  </div>
                </div>
              </button>
            </div>
            <div className="payment-details">
              <div className="title">Payment</div>
              <div className="value">
                <div className="cost">${planPrice}</div>
                <div className="period">per month</div>
              </div>
            </div>
          </div>
          <div className="actions">
            <div className="cancel-subscription">
              <button type="button" className="btn-cancel">
                <div className="text">Cancel Subscription</div>
              </button>
            </div>
            <div className="view-payment-history">
              <Button label="View Payment History" onClick={onClick} />
            </div>
          </div>
        </div>
        {/* divider */}
        {/* <div className="divider" /> */}
        {/* bottom content - workflow usage */}
        {/* <div className="workflow-used-container">
          <div className="header">Workflow Used</div>

          <div className="progess-bar-container">
            <Progress percentage="75" />
          </div>

          <div className="progess-details">
            <div className="icon-container">
              <img src={WorkflowIcon} alt="" />
            </div>
            <div className="workflows-used">150/200</div>
          </div>
        </div> */}
      </div>
    </PlanAndBillingContainer>
  );
};
