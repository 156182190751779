import { Handle, Position } from "reactflow";
import { ActionsCard } from "../ActionsCard";
import classNames from "classnames";
import { useAppSelector } from "../../../../../../../../redux/hooks";
import { projectSelector } from "../../../../../../../../redux/slices/projectSlice";
import { useEffect, useState } from "react";

const handleNodeClick = (props: { data: any; id: any }) => {
  const { data, id } = props;
  data.onNodeClickCallback(id);
};

const onCloseIconClick = (
  event: { stopPropagation: () => void },
  props: { data: any; id: any },
) => {
  event.stopPropagation();
  const { data, id } = props;
  data.onDeleteNodeCallback(id);
};

export const Condition = (props: { data: any; id: any }) => {
  const { selectedNode } = useAppSelector(projectSelector);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(selectedNode?.id === props?.data?.id);
    if (!selectedNode) {
      setActive(false);
    }
  }, [selectedNode, props.data]);
  return (
    <div
      className={classNames("NodeWrapper", {
        "is-active-node": active,
      })}
    >
      <Handle type="target" position={Position.Top} className="NodePort" />
      <ActionsCard
        selected={false}
        {...props}
        onNodeClick={() => handleNodeClick(props)}
        onCloseIconClick={(event: { stopPropagation: () => void }) =>
          onCloseIconClick(event, props)
        }
      />
      <Handle
        id="condition_0"
        type="source"
        position={Position.Bottom}
        className="NodePort"
      />
      <Handle
        id="condition_1"
        type="source"
        position={Position.Bottom}
        className="NodePort"
      />
    </div>
  );
};

export const ActionCard = (props: { data: any; id: any }) => {
  const { selectedNode } = useAppSelector(projectSelector);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(selectedNode?.id === props?.data?.id);
    if (!selectedNode) {
      setActive(false);
    }
  }, [selectedNode, props.data]);
  return (
    <div
      className={classNames("NodeWrapper", {
        "is-active-node": active,
      })}
    >
      <ActionsCard
        selected={false}
        {...props}
        onNodeClick={() => handleNodeClick(props)}
        onCloseIconClick={(event: { stopPropagation: () => void }) =>
          onCloseIconClick(event, props)
        }
      />
      <Handle type="source" position={Position.Bottom} className="NodePort" />
    </div>
  );
};

export const EmptyNode = () => (
  <div className="empty-node">
    <Handle type="source" position={Position.Top} className="NodePort" />
  </div>
);
