import { useState } from "react";

import { CustomModal } from "../../../../../../components/common";
import { HeaderContanier } from "./elements";

import { deleteWorkflowStep } from "../../../../../../redux/slices/projectSlice";
import { useAppDispatch } from "../../../../../../redux/hooks";

import DeleteIcon from "../../../../../../assets/icons/delete.svg";
import DownIcon from "../../../../../../assets/icons/arrow-down.svg";
import UpArrowIcon from "../../../../../../assets/icons/arrow-up.svg";
import MessageIcon from "../../../../../../assets/icons/message-white.svg";
import RequestIcon from "../../../../../../assets/icons/api-request-white.svg";
import LinkIcon from "../../../../../../assets/icons/go-to-url-white.svg";
import WarningIcon from "../../../../../../assets/icons/warning.svg";

interface HeaderProps {
  workflowId: number | undefined;
  workflowStepId: number;
  onClose: () => void;
  stepTitle: string;
  stepType: number;
  isActionOpen: boolean;
}

export const Header = ({
  workflowId,
  workflowStepId,
  onClose,
  stepTitle,
  stepType,
  isActionOpen,
}: HeaderProps) => {
  const dispatch = useAppDispatch();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  // const [message, setMessage] = useState("");
  const renderIcon = (type: number) => {
    switch (type) {
      case 1:
        return RequestIcon;

      case 2:
        return MessageIcon;

      case 3:
        return LinkIcon;

      default:
        return;
    }
  };

  return (
    <>
      <HeaderContanier
        className="d-flex justify-content-between align-items-center header"
        onClick={onClose}
      >
        <div className="header-left">
          <div className="icon-container">
            <img src={renderIcon(stepType)} alt="" />
          </div>
          <div>{stepTitle}</div>
        </div>
        <div className="expand d-flex align-items-center">
          <button
            type="button"
            onClick={(e) => {
              // stop parent event handlers being executed
              e.stopPropagation();
              setDeleteId(workflowStepId);
            }}
            className="delete-btn"
          >
            <img src={DeleteIcon} alt="" />
          </button>
          {isActionOpen ? (
            <img src={UpArrowIcon} alt="" />
          ) : (
            <img src={DownIcon} alt="" />
          )}
        </div>
      </HeaderContanier>
      <CustomModal
        show={!!deleteId}
        onHide={() => {
          setDeleteId(null);
        }}
        onClick={() => {
          setIsDeleting(true);
          // setMessage("Workflow Action is deleted successfully");
          dispatch(
            deleteWorkflowStep(workflowId, workflowStepId, () => {
              setIsDeleting(false);
              setDeleteId(null);
            }),
          );
        }}
        label="Confirm"
        isLoading={isDeleting}
        title="Confirm Delete"
        subTitle="Are you sure you want to delete this action from workflow ?"
        isDelete={true}
        icon={WarningIcon}
      />
    </>
  );
};
