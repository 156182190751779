import styled from "styled-components";

export const SideNavBarContainer = styled.div`
  position: fixed;
  min-height: 100vh;
  max-width: 6rem;
  border-right: 0.0625rem solid #eaecf0;
  background: #fff;
  box-shadow: 0rem 0rem 0.375rem 0rem rgba(0, 0, 0, 0.06);
  padding-top: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .nav-container {
    width: 100%;
    margin-top: 0.5rem;
    padding: 0rem 0.875rem;
  }
  a {
    text-decoration: none;
    .text {
      padding-top: 0.125rem;
      text-align: center;
      color: #000;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
  .nav-item {
    margin-top: 1.5rem;
    padding: 0.5625rem;

    :hover {
      border-radius: 0.5rem;
      background: rgba(101, 90, 236, 0.08);
    }
  }
  .active-link {
    border-radius: 0.5rem;
    background: rgba(101, 90, 236, 0.15);
    a {
      .text {
        color: #655aec;
      }
    }
    :hover {
      a {
        .text {
          color: #655aec;
        }
      }
    }
  }
  .img-container {
    display: flex;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .img-container {
    display: flex;
    align-items: center;
  }
  &.is-collapsed {
    max-width: 3.5rem;
    .nav-container {
      padding: 0rem 0.4375rem;
    }
  }
`;
