import styled from "styled-components";

export const VerificationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92.123vh;
  flex-direction: column;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 4.3125rem;
    height: 6.0625rem;
  }
  .verify {
    font-weight: 500;
    font-size: 1rem;
    color: #000000;
    margin-top: 1.375rem;
    font-size: 1rem;
    line-height: 120%;
  }
`;
