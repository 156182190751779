import axios from "../instance";

export const updateBasicDetail = (data: any, organisationId: number) =>
  axios.put(`/organisation/${organisationId}`, data);

export const fetchUserOrganisations = () =>
  axios.get(`/organisation/all-organisation`);

export const deleteOrgLogo = (organisationId: number) =>
  axios.put(`/organisation/${organisationId}/delete`);
