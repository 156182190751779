import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .title {
    font-size: 1.6rem;
    font-weight: bold;
  }
`;
