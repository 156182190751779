/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import ArrowDownIcon from "../../../../../assets/icons/pagination-arrow-down.svg";
import ArrowLeftIcon from "../../../../../assets/icons/pagination-arrow-left.svg";
import SelectBox from "../SelectBox";
import { PaginationContainer } from "./elements";

export const Pagination = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
}: any) => {
  const pageSelectorElement = useRef();
  const rowCountSelectorElement = useRef();
  const [isPageSelectorOpen, setIsPageSelectorOpen] = useState(false);
  const [isRowCountSelectorOpen, setIsRowCountSelectorOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside() {
      if (pageSelectorElement.current) {
        setIsPageSelectorOpen(false);
      }

      if (rowCountSelectorElement.current) {
        setIsRowCountSelectorOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [pageSelectorElement, rowCountSelectorElement]);

  return (
    <PaginationContainer>
      <div className="align-items-center d-flex pagination-details-left">
        <div className="pagination-details">
          <span className="text">
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <span className="mx-4 text">|</span>
          <span className="mr-3 text">Rows per page</span>
        </div>
        <SelectBox
          className="select-count"
          icon={ArrowDownIcon}
          isOpen={isRowCountSelectorOpen}
          isOptionsOnTop
          items={[10, 20, 30, 40, 50].map((item) => ({
            name: item,
            onClick: () => {
              setPageSize(item);
            },
          }))}
          label={pageSize}
          onClick={() => {
            setIsRowCountSelectorOpen((previousValue) => !previousValue);
          }}
          reference={rowCountSelectorElement}
          width={"4.25rem"}
        />
      </div>
      <div className="pagination-button d-flex">
        <SelectBox
          className="select-page"
          icon={ArrowDownIcon}
          isOpen={isPageSelectorOpen}
          isOptionsOnTop
          items={pageOptions.map((page: number) => ({
            name: page + 1,
            onClick: () => {
              gotoPage(page);
            },
          }))}
          label={pageIndex + 1}
          onClick={() => {
            setIsPageSelectorOpen((previousValue) => !previousValue);
          }}
          reference={pageSelectorElement}
          width="4.25rem"
        />
        {/* Go to the previous page. */}
        <div className="prev-next-btn d-flex">
          <button
            className={classNames("previous-button", {
              "is-disabled": !canPreviousPage,
            })}
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            <img alt="Previous" src={ArrowLeftIcon} />
            <span>Prev.</span>
          </button>
          <button
            className={classNames("next-button", {
              "is-disabled": !canNextPage,
            })}
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            <span>Next</span>
            <img alt="Next" src={ArrowLeftIcon} />
          </button>
        </div>
      </div>
    </PaginationContainer>
  );
};
