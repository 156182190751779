import styled from "styled-components";

export const TriggerContainer = styled.div`
  cursor: pointer;
  border-color: rgba(54, 176, 136, 0.1) !important;
  .header {
    border-radius: 0.5rem;
    border: none;
    width: 100%;
    outline: none;
    background: transparent;
    display: flex;
    justify-content: space-between;
    .header-left {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 1rem;
      color: #000000;
    }
    .icon-container {
      margin-right: 1rem;
    }
    .expand {
      border: none;
      outline: none;
      background: transparent;
    }
  }
  .trigger-container {
    display: none;
    padding: 0rem 1.5rem 1rem 1.5rem;
    &.is-open {
      display: block;
    }
  }
  .trigger-type {
    height: 1.25rem;
    line-height: 1.25rem;
    button {
      display: flex;
      .label {
        font-size: 1.125rem;
      }
    }
  }
  .trigger-divider {
    border-bottom: 0.0625rem solid #f1effc;
    margin-bottom: 1.25rem;
  }
`;
interface ScheduleContainerProps {
  isSidebar: boolean;
}

export const ScheculeContainer = styled.form<ScheduleContainerProps>`
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05);

  padding-bottom: 2rem;

  ${(props) =>
    props.isSidebar
      ? `
      margin: 24px;
    `
      : `
      margin: 24px 0;
      padding: 1.5rem;
    `}
  .tabs-button {
    height: 39px;
    border-radius: 8px;
    padding: 10px 14px 10px 10px;
    width: 73px;
    outline: none;
    border: none;
    background: transparent;
    padding: unset;
    font-weight: 600;
    &.is-active {
      color: #655aec;
      background: rgba(101, 90, 236, 0.1);
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .simple-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    .at-text {
      color: #000;
      font-size: 1rem;
      font-weight: 500;
      margin-left: 1.5rem;
      margin-bottom: 0.7rem;
    }
    .input-field {
      padding: 0.5rem 0;
    }
    .interval-input,
    .start-from {
      min-width: 15rem;
      margin-right: 1.5rem;
    }
    .hrs-input,
    .mins-input {
      min-width: 5.5rem;
      margin-right: 1.5rem;
    }
    .interval-sidebar,
    .start-from-sidebar {
      min-width: 100%;
    }
    .hrs-sidebar {
      min-width: 50%;
      margin-right: 8px;
    }
    .mins-sidebar {
      min-width: 48%;
    }
  }

  .react-select__menu-list {
    overflow-y: auto !important;
    max-height: 14rem !important;
  }
  .button-container {
    margin-top: 1rem;
  }
`;
