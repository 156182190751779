import { fieldsDataGenerator } from "./FieldsDataGenerator";

export const findLabelAndValue = ({
  label,
  type,
}: {
  label: string;
  type: string;
}) => {
  const { intervals, startFrom, Hours, Minutes } = fieldsDataGenerator();
  let output;
  switch (type) {
    case "interval":
      output = intervals.find((item) => item.label === label);
      break;
    case "days":
      output = startFrom.find((item) => item.label === label);
      break;
    case "hrs":
      output = Hours.find((item) => item.label === label);
      break;
    case "mins":
      output = Minutes.find((item) => item.label === label);
      break;
    default:
      break;
  }
  return output;
};
