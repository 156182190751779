import { useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import { useAppDispatch } from "../../../../redux/hooks";
import {
  fetchAllProjectWorkFlows,
  fetchWorkflowStepTypes,
} from "../../../../redux/slices/projectSlice";

import { Workflow, WorkflowsList } from "./pages";

export const Workflows = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchAllProjectWorkFlows());
    dispatch(fetchWorkflowStepTypes());
  }, [id]);

  return (
    <>
      <div className="main-container">
        <Routes>
          <Route path="/" element={<WorkflowsList />} />
          <Route path="/:workflowId/*" element={<Workflow />} />
        </Routes>
      </div>
    </>
  );
};
