import styled from "styled-components";

export const ChangePasswordContainer = styled.div`
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  flex-direction: column;
  width: 100%;
  .title-container {
    display: flex;
    align-items: center;
    position: relative;
    .icon-container {
      background: #655aec;
      border-radius: 50%;
      opacity: 0.20000000298023224;
      width: 80px;
      height: 80px;
      position: relative;
      margin: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .img-container {
      position: absolute;
      top: 2.5rem;
    }
  }
  .change-password-container {
    width: 25rem;
    height: 27.25rem;
  }
  .form-container {
    padding-top: 1rem;
    .input-field {
      width: 100%;
      padding-top: 1.5rem;
      div {
        height: 4.375rem;
      }

      .label {
        margin-bottom: 0.375rem !important;
      }
      .input-container {
        height: 2.75rem;
      }
    }
  }
  .title-container {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #333333;
    line-height: 2rem;
  }
  .sub-title {
    font-weight: 500;
    font-size: 0.75rem;
    color: #000000;
    padding-top: 0.75rem;
    line-height: 1.125rem;
  }
  .reset-password {
    margin-top: 1.5rem;
    padding: 0.625rem;
    height: 48px;
    .label {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;
