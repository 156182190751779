import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { MakeAnAPIRequestContainer } from "./elements";

import { useAppDispatch } from "../../../../../../../../redux/hooks";
import { saveWorkflowAction } from "../../../../../../../../redux/slices/projectSlice";

import { FormValue, StepsTypes } from "../../../../../../../../utils/types";
import { MakeAPIRequestSChema } from "../../../../../../../../utils/validation";
import { Header } from "../../../../common";
import { useLocation } from "react-router-dom";
import ApiRequestForm from "./components/APIRequestForm";

const httpMethod = [
  { value: 1, label: "GET" },
  { value: 2, label: "POST" },
  { value: 3, label: "Delete" },
  { value: 4, label: "PUT" },
  { value: 5, label: "PATCH" },
];

const initialValues: FormValue = {
  httpMethod: { value: 1, label: "GET" },
  httpUrl: "",
  authUserName: "",
  authPassword: "",
  requestBody: "",
  requestHeaders: [
    { header: "User-Agent", value: "Lessy" },
    { header: "Content-Type", value: "application/json" },
  ],
};
export const MakeAnAPIRequest = ({
  workflowId,
  workflowStep,
  isActionOpen,
}: StepsTypes) => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [defaultFormValues, setDefaultFormValues] = useState(initialValues);
  const [open, setOpen] = useState(
    isActionOpen || state.actionId === workflowStep.pwst_id ? true : false,
  );

  const { handleSubmit } = useForm<FormValue>({
    resolver: yupResolver(MakeAPIRequestSChema),
    defaultValues: {
      httpMethod: null,
      httpUrl: "",
      authUserName: "",
      authPassword: "",
      requestBody: "",
      requestHeaders: [],
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const isSidebar = false;

  useEffect(() => {
    if (!workflowStep.api) {
      setDefaultFormValues({
        httpMethod: {
          value: httpMethod[0].value,
          label: httpMethod[0].label,
        },
        httpUrl: "",
        authUserName: "",
        authPassword: "",
        requestBody: "",
        requestHeaders: [
          { header: "User-Agent", value: "Lessy" },
          { header: "Content-Type", value: "application/json" },
        ],
      });
    } else {
      const makeRequest = workflowStep.api;
      const method = httpMethod.find(
        (item) => item.label === makeRequest.piae_method,
      );
      if (method) {
        // Set default form values based on fetched data
        setDefaultFormValues({
          httpMethod: {
            value: method.value,
            label: method.label,
          },
          httpUrl: makeRequest.http_url,
          authUserName: makeRequest?.username,
          authPassword: makeRequest?.password,
          requestBody: JSON.parse(makeRequest?.body),
          requestHeaders: makeRequest?.headers || [],
        });
      }
    }
  }, [workflowStep]);

  //save the data
  const onSave = (data: FormValue) => {
    setIsLoading(true);

    const apiRequestData = {
      ...data,
      workflow_step_id: workflowStep.pwst_id,
    };
    if (
      apiRequestData?.httpMethod?.value == 1 ||
      apiRequestData?.httpMethod?.value == 3
    ) {
      apiRequestData.requestBody = "";
    }
    dispatch(
      saveWorkflowAction(apiRequestData, workflowId, () => {
        setIsLoading(false);
      }),
    );
  };

  const onClose = () => {
    setOpen(!open);
  };

  return (
    <MakeAnAPIRequestContainer
      onSubmit={handleSubmit(onSave)}
      method="POST"
      noValidate
      className={classNames({ "is-open": open })}
    >
      <div className="header-container">
        <Header
          stepTitle="Make An API Request"
          workflowId={workflowId}
          workflowStepId={workflowStep.pwst_id}
          onClose={onClose}
          stepType={1}
          isActionOpen={open}
        />
      </div>
      {open && (
        <div className="form">
          <ApiRequestForm
            isLoading={isLoading}
            isSidebar={isSidebar}
            defaultValues={defaultFormValues}
            onSubmit={onSave}
          />
        </div>
      )}
    </MakeAnAPIRequestContainer>
  );
};
