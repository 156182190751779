import { StatusContainer } from "./elements";

export const Status = ({ value }: any) => {
  return (
    <StatusContainer>
      <div className={`status-${value}`}>
        {value === 1 ? "Active" : "Pending"}
      </div>
    </StatusContainer>
  );
};
