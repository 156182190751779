import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import classNames from "classnames";
// import moment from "moment";
import { Helmet } from "react-helmet-async";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import { createWorkFlowSchema } from "../../../../../../utils/validation";

import {
  Button,
  FormInput,
  ToastMessage,
} from "../../../../../../components/common";
import { Table } from "../../../../../../components/common/Table";
import Loader from "../../../../../../components/common/Loader";

import { Status, Title } from "../../common";
import { WorkflowsContainer } from "../../elements";

import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  createNewWorkFlow,
  setError,
  projectSelector,
} from "../../../../../../redux/slices/projectSlice";

import PlusIcon from "../../../../../../assets/icons/add.svg";
import TickIcon from "../../../../../../assets/icons/add-icon.svg";
import SearchIcon from "../../../../../../assets/icons/search.svg";
import APIIcon from "../../../../../../assets/icons/api.svg";
import CloseIcon from "../../../../../../assets/icons/cancel-icon.svg";

import { WorkFlow } from "../../../../../../utils/types";

export const WorkflowsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { workflows, error, isLoading } = useAppSelector(projectSelector);

  const [newWorkflow, setNewWorkflow] = useState(false);
  const [focus, setIsFocus] = useState(false);
  const [data, setData] = useState<WorkFlow[]>();
  const [searchInput, setSearchInput] = useState("");

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createWorkFlowSchema),
    defaultValues: {
      workflowName: "",
    },
  });

  const workflowColumns = useMemo(
    () => [
      {
        Header: "",
        Cell: ({ value }: any) => (
          <div className="d-flex justify-content-end align-items-center">
            {Status(value)}
          </div>
        ),
        accessor: "workflowRun.pwr_status",
        className: "status",
      },
      {
        Header: "Name",
        Cell: ({ value }: any) => <div className="workflow-name">{value}</div>,
        accessor: "pw_name",
        className: "workflow_name",
      },
      // {
      //   Header: "WorkFlow Type",
      //   accessor: "pw_type",
      //   className: "workflow_type",
      // },
      {
        Header: "Integrations",
        Cell: () => (
          <div className="d-flex justify-content-between align-items-center integration-div">
            <div className="integration-img d-flex align-items-center">
              <img src={APIIcon} alt="" />
            </div>
            <div className="integration-text">Rest</div>
          </div>
        ),
        accessor: "integrations",
        className: "integrations",
      },
      {
        Header: "Schedule",
        Cell: () => <div className="text-uppercase schedule">Manual</div>,
        accessor: "schedule",
        className: "schedule",
      },
      // {
      //   Header: "Published Date",
      //   Cell: ({ value }: any) => (
      //     <div>{moment(value).format("D MMM YYYY h:mm A")}</div>
      //   ),
      //   accessor: "pw_created_at",
      //   className: "published_date",
      // },
      // {
      //   Header: "Updated Date",
      //   Cell: ({ value }: any) => (
      //     <div>{moment(value).format("D MMM YYYY h:mm A")}</div>
      //   ),
      //   accessor: "pw_updated_at",
      //   className: "updated_date",
      // },

      // {
      //   Header: "Status",
      //   Cell: ({ value }: any) => (
      //     <div className="status-conatiner">
      //       {value == 1 ? "Draft" : "Published"}
      //     </div>
      //   ),
      //   accessor: "pw_status",
      //   className: "status",
      // },
      {
        Header: "   ",
        Cell: ({ row: { original } }: any) => {
          return (
            <div className="d-flex justify-content-end me-5">
              <button
                className="edit-button"
                onClick={() => {
                  navigate(`${original?.pw_id}/actions`, {
                    state: {
                      pw_id: original?.pw_id,
                      pw_name: original?.pw_name,
                    },
                  });
                }}
              >
                View
              </button>
              {/* <button
                className="ms-4 edit-button"
                onClick={() => {
                  navigate(`${original?.pw_id}/steps`, {
                    state: {
                      pw_id: original?.pw_id,
                      pw_name: original?.pw_name,
                    },
                  });
                }}
              >
                Edit
              </button> */}
            </div>
          );
        },
        accessor: "ou_id",
        className: "status",
      },
    ],
    [data],
  );

  const onSubmit = (data: { workflowName: string }) => {
    dispatch(
      createNewWorkFlow(data, () => {
        reset();
        setNewWorkflow(false);
      }),
    );
  };

  useEffect(() => {
    setData(workflows);
    if (workflows?.length) {
      setData(
        workflows?.filter((workflow) =>
          workflow?.pw_name.toLowerCase().includes(searchInput),
        ),
      );
    }
  }, [searchInput, workflows]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Workflows | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <WorkflowsContainer>
        <>
          <div className="d-flex justify-content-between align-items-center">
            <Title titles={[{ id: 1, title: "Worklfows" }]} />
            <div className="search-input">
              <input
                type="search"
                placeholder="Search by name"
                onChange={(event) => setSearchInput(event.target.value)}
                value={searchInput}
              />
              <div className="search-img">
                <img src={SearchIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="table">
            <Table
              columns={workflowColumns}
              data={data ? data : []}
              itemName="Workflows"
              isButton
              type={1}
              buttonComponent={
                !newWorkflow ? (
                  <Button
                    label="New Workflow"
                    onClick={() => setNewWorkflow(true)}
                    icon={PlusIcon}
                    iconPosition="left"
                    buttonStyles={{
                      borderRadius: "0.3125rem",
                    }}
                    className="new-workflow-btn"
                    isCancel
                  />
                ) : (
                  <form
                    className="d-flex form-container"
                    onSubmit={handleSubmit(onSubmit)}
                    method="POST"
                    noValidate
                  >
                    <div className="d-flex flex-column">
                      <div
                        className={classNames("new-workflow-container", {
                          focused: focus,
                          "is-error": !!errors.workflowName,
                        })}
                        onFocus={() => {
                          setIsFocus(true);
                        }}
                        onBlur={() => {
                          setIsFocus(false);
                        }}
                      >
                        <div className="input-field">
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <FormInput
                                {...field}
                                id="workflow-name-id"
                                type="text"
                                width="100%"
                                placeholder="Type New Workflow"
                                autoComplete="off"
                                errorMessage={errors.workflowName?.message}
                                hasError={!!errors.workflowName}
                                className="new-workflow"
                              />
                            )}
                            name="workflowName"
                          />
                        </div>
                        <div className="button-container">
                          {/* {isLoading ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                              style={{ width: "1rem", height: "1rem" }}
                            />
                          ) : ( */}
                          <button className="add-button" type="submit">
                            <img src={TickIcon} alt="add" />
                          </button>
                          {/* )} */}

                          <button
                            className="close-button"
                            onClick={() => {
                              setNewWorkflow(false);
                              reset();
                            }}
                          >
                            <img
                              src={CloseIcon}
                              alt="close"
                              className="close-icon"
                            />
                          </button>
                        </div>
                      </div>
                      {errors.workflowName ? (
                        <div className="error">
                          {errors.workflowName.message}
                        </div>
                      ) : null}
                    </div>
                  </form>
                )
              }
            />
          </div>
        </>
        <Outlet />
      </WorkflowsContainer>

      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
