import classNames from "classnames";
import LessyLogo from "../../../../assets/app-logo/lessy-auth.svg";
import { AuthNavbarContainer } from "./elements";

interface AuthNavbarData {
  to?: string;
  className?: string;
}
export const AuthNavbar = ({ to, className }: AuthNavbarData) => {
  return (
    <AuthNavbarContainer>
      <AuthNavbarContainer.Brand
        href={to ? to : "/"}
        className={classNames("d-flex align-items-center", className)}
      >
        <img alt="" src={LessyLogo} />
        {/* <span className="mx-2">{process.env.REACT_APP_NAME}</span> */}
      </AuthNavbarContainer.Brand>
    </AuthNavbarContainer>
  );
};
