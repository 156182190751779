import styled from "styled-components";

export const ButtonEdgedContainer = styled.div`
  cursor: "pointer";
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0s ease;
  .add-actions-button {
    outline: none;
    border: none;
    background: transparent;
    padding: unset;
    margin: 0 5px 0 auto;
    height: 1.125rem;
    width: 1.25rem;
  }
  .select-container {
    position: absolute;
    top: 100%; /* Center-align vertically */
    left: 50%; /* Center-align horizontally */
    transform: translate(-50%, -50%); /* Center it precisely */
    max-width: 18rem;
    min-width: 14rem;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    /* border: 0.0625rem solid #d0d5dd; */
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.03),
      0 1.25rem 0.5rem -0.25rem rgba(16, 24, 40, 0.08);
    /* padding: 0 1rem; */
    .add-action-field {
      border: none;
      outline: none;
      background: transparent;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      :not(:last-child) {
        border-bottom: 0.0625rem solid #d0d5dd;
      }
      .action-text {
        color: #000;
        font-size: 0.875rem;
        font-weight: 500;
        margin-left: 1rem;
        font-weight: 500;
        line-height: 1.3125rem;
        text-align: center;
      }
      .icon-container {
        display: flex;
        height: 18px;
        width: 18px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;
