import { useEffect, useState } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import { Outlet, Route, Routes, useParams } from "react-router-dom";

import { SideBar, ViewLogs, WorkflowRun } from "./components";
import { ViewWorkflowContainer } from "./elements";
import { AddWorkflowActions } from "../AddWorkflowActions";

import { useAppDispatch } from "../../../../../../redux/hooks";
import { fetchWorkflow } from "../../../../../../redux/slices/projectSlice";
import { Actions } from "../Actions";

export const Workflow = () => {
  const { workflowId } = useParams();
  const dispatch = useAppDispatch();
  const [collapse, setCollapse] = useState<any | null>("");

  useEffect(() => {
    if (workflowId) {
      dispatch(fetchWorkflow(workflowId));
    }
  }, [workflowId]);

  return (
    <>
      <Helmet>
        <title>Workflows | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <ViewWorkflowContainer>
        <div className="d-flex workflow-container">
          <SideBar setCollapse={setCollapse} collapse={collapse} />
          <div
            className={classNames("side-container", {
              "is-collapsed": collapse,
            })}
          >
            <Routes>
              <Route path={`actions`} element={<AddWorkflowActions />} />
              <Route path={`runs/*`} element={<WorkflowRun />} />
              <Route path={`runs/:workflowRunId`} element={<ViewLogs />} />
              <Route path={`flow/*`} element={<Actions />} />
            </Routes>
          </div>
        </div>
      </ViewWorkflowContainer>

      <Outlet />
    </>
  );
};
