import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, FormInput } from "../../../../components/common";
// import { Checkbox } from "../../../../components/common/Checkbox";
import { AuthNavbar, Container } from "../../components";
import { MainContainer, UpdatePasswordContainer } from "./elements";
import { updatePasswordSchema } from "../../../../utils/validation";
import {
  updatePassword,
  setError,
  logout,
  verifyResetPasswordToken,
  authSelector,
} from "../../../../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ToastMessage } from "../../../../components/common/ToastMessage";

export const UpdatePassword = () => {
  const { isLoading, error } = useAppSelector(authSelector);
  const [user, setUser] = useState(null || {});

  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
    dispatch(
      verifyResetPasswordToken(params.get("code"), (data: any) => {
        if (data) {
          setUser(data);
        }
      }),
    );
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
      // accept: false,
    },
  });

  const onSubmit = (data: { password: string; confirmPassword: string }) => {
    const token = params.get("code");
    dispatch(
      updatePassword(data, token, () => {
        navigate("/auth/login");
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>Update Password | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <MainContainer>
        {isLoading && !user ? (
          <div className="loader">
            <div
              className="spinner-border text-primary"
              role="status"
              style={{ width: "2.5rem", height: "2.5rem" }}
            />
            <span className="sr-only mt-4">Verifying...</span>
          </div>
        ) : (
          <>
            <div className="w-50">
              <AuthNavbar />
              <UpdatePasswordContainer>
                <div className="update-password-container">
                  <div className="title-container">
                    <div className="title">Set Your Password</div>
                    <div className="sub-title">
                      Must be at least 8 characters.
                    </div>
                  </div>
                  <div className="form-container">
                    <form
                      className="align-items-center d-flex flex-column w-100"
                      onSubmit={handleSubmit(onSubmit)}
                      method="POST"
                      noValidate
                    >
                      <div className="input-field">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              id="user-password"
                              type="password"
                              width="100%"
                              placeholder="Enter your Password"
                              errorMessage={errors.password?.message}
                              hasError={!!errors.password}
                              label="Password"
                            />
                          )}
                          name="password"
                        />
                      </div>
                      <div className="input-field">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              id="user-password"
                              type="password"
                              width="100%"
                              placeholder="Enter your Confirm Password"
                              // autoComplete="disabled"
                              errorMessage={errors.confirmPassword?.message}
                              hasError={!!errors.confirmPassword}
                              label="Confirm Password"
                            />
                          )}
                          name="confirmPassword"
                        />
                      </div>

                      {/* <div className="align-items-start d-flex  mt-4 w-100">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              hasError={!!errors.accept}
                              errorMessage={errors.accept?.message}
                              label="I agree to the Terms & Privacy"
                            />
                          )}
                          name="accept"
                        />
                      </div> */}
                      <Button
                        label="Update Password"
                        type="submit"
                        isLoading={isLoading}
                        className="w-100 update-password"
                      />
                    </form>
                  </div>
                  {/* <AuthSwitchLink
                    linkHref="/auth/login"
                    linkText="Login"
                    text="Already have an account?"
                  /> */}
                </div>
              </UpdatePasswordContainer>
            </div>
          </>
        )}
        <div className="left-container">
          <Container />
        </div>
      </MainContainer>

      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
