import styled from "styled-components";

export const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
  .previous-button,
  .next-button {
    align-items: center;
    background-color: transparent;
    border: 0.0625rem solid #c9c9c9;
    display: flex;
    background: rgb(255, 255, 255);
    padding: 0.4375rem 0.875rem;

    &.is-disabled {
      opacity: 0.5;

      :active,
      :focus {
        border-color: #c9c9c9;
        box-shadow: none;
      }
    }

    :active,
    :focus {
      border-color: #40a1ff;
      box-shadow: 0 0 0 0.125rem #cfe7ff;
      outline: none;
      z-index: 10;
    }

    img {
      vertical-align: unset;
    }

    span {
      color: #3d4457;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1;
    }
  }

  .previous-button {
    border-radius: 0.5rem 0 0 0.5rem;
    margin-right: -0.0625rem;

    img {
      margin-right: 0.75rem;
    }
  }

  .next-button {
    border-radius: 0 0.5rem 0.5rem 0;

    img {
      margin-left: 0.75rem;
      transform: rotate(180deg);
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  .text {
    color: #8a8c94;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .select-page {
    margin-right: 0.75rem;
    background-color: transparent;
    border: 0.0625rem solid #c9c9c9;
    border-radius: 0.5rem;
    margin-left: 0.75rem;
  }
  .select-count {
    margin-left: 0.75rem;
    background-color: transparent;
    border: 0.0625rem solid #c9c9c9;
    border-radius: 0.5rem;
    margin-left: 0.75rem;
  }
  .select-page,
  .select-count {
    padding: 0.25rem 0.5rem !important;
    background: rgb(255, 255, 255);
    h5 {
      color: #3d4457;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1;
      margin-right: 0.12rem;
    }

    img {
      height: 1.2rem;
      width: 1.2rem;
    }

    .dropdown-items {
      max-height: 15rem;
      overflow: auto;
    }
  }

  @media (max-width: 46rem) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .pagination-button {
      margin-top: 1.5rem;
      width: 100%;
      justify-content: space-between;
      .previous-button,
      .select,
      .next-button {
        padding: 0.75rem 0.8rem;
      }
      .select-page {
        margin-left: unset;
      }
    }
    .pagination-details-left {
      width: 100%;
      justify-content: space-between;
    }
  }
  .prev-next-btn {
    padding: unset !important;
  }
`;
