import { Navbar } from "react-bootstrap";
import styled from "styled-components";

export const AuthNavbarContainer = styled(Navbar)`
  padding: 2.53125rem 0 0 2.5rem !important;
  height: 7.877vh;
  a {
    padding: 0 !important;
  }
  span {
    font-weight: 600;
    font-size: 1.125rem;
    color: #333333;
  }
`;
