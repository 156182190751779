import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ThunkAction } from "redux-thunk";

import * as organisationApi from "../../api/organisation";
import { Organisation, UserOrganisation } from "../../utils/types";
import { RootState } from "../store";

interface organisationState {
  error: string | null;
  isLoading: boolean;
  organisation: Organisation | null;
  usersOrganisation: UserOrganisation[] | null;
  activeOrganisationId: number | null;
  userType: number | null;
  isDeleting: boolean;
}
const initialState: organisationState = {
  error: null,
  isLoading: false,
  organisation: null,
  usersOrganisation: null,
  activeOrganisationId: null,
  userType: null,
  isDeleting: false,
};

const organisationSlice = createSlice({
  initialState,
  name: "organisation",
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUsersOrganisation(state, action) {
      state.usersOrganisation = action.payload;
    },
    setActiveOrganisationId(state, action) {
      localStorage.setItem("ai-org-id", action.payload);
      state.activeOrganisationId = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    editUsersOrganisation(state, action) {
      const org = state?.usersOrganisation?.find(
        (item: { organisation: { or_id: number } }) =>
          item.organisation.or_id === action.payload.or_id,
      );

      if (org?.organisation) {
        state.usersOrganisation?.map((item) => {
          if (item.organisation.or_id === org.organisation.or_id) {
            item.organisation = action.payload;
          }
        });
      }
    },
    setIsDeleting(state, action) {
      state.isDeleting = action.payload;
    },
  },
});

export const {
  setError,
  setIsLoading,
  setUsersOrganisation,
  setActiveOrganisationId,
  setUserType,
  editUsersOrganisation,
  setIsDeleting,
} = organisationSlice.actions;

export const updateBasicDetail =
  (
    data: any,
    organisationId: number,
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await organisationApi.updateBasicDetail(
        data,
        organisationId,
      );

      const { organisation, status } = response.data;
      if (status) {
        dispatch(
          editUsersOrganisation({
            or_id: organisationId,
            or_name: organisation?.or_name,
          }),
        );
      }
      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const fetchUserOrganisations =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await organisationApi.fetchUserOrganisations();

      const { organisationData } = response.data;

      if (organisationData?.length) {
        dispatch(setActiveOrganisationId(organisationData[0].or_id));
        dispatch(setUserType(organisationData[0].ou_user_type));
        dispatch(setUsersOrganisation(organisationData));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const deleteOrgLogo =
  (
    organisationId: number,
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsDeleting(true));
    try {
      await organisationApi.deleteOrgLogo(organisationId);

      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsDeleting(false));
    }
  };
export const organisationSelector = (state: {
  organisation: organisationState;
}) => state.organisation;

export const organisationReducer = organisationSlice.reducer;
