import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";

import { useAppSelector } from "../../../../../../../../redux/hooks";
import { projectSelector } from "../../../../../../../../redux/slices/projectSlice";

import { RadioButton } from "../../../../../../../../components/common/Radio";

import { TriggerContainer } from "./elements";

import TriggerIcon from "../../../../../../../../assets/icons/trigger.svg";
import DownArrowIcon from "../../../../../../../../assets/icons/arrow-down.svg";
import UpArrowIcon from "../../../../../../../../assets/icons/arrow-up.svg";

import { triggerSchema } from "../../../../../../../../utils/validation";
import { TriggerData } from "../../../../../../../../utils/types";

import { fieldsDataGenerator } from "./common/FieldsDataGenerator";
import { findLabelAndValue } from "./common/FindLabelAndValue";
import TimeScheduleForm from "./common/TimeScheduleForm";

export const Trigger = () => {
  const [open, setOpen] = useState(false);
  const isSidebar = false;
  const [triggerType, setTriggerType] = useState(1);
  const [scheduleType, setScheduleType] = useState(1);
  const { workflow, isTriggerLoading } = useAppSelector(projectSelector);
  const { intervals, startFrom, Hours, Minutes } = fieldsDataGenerator();
  const trigger = workflow?.workflowTrigger;

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<TriggerData>({
    resolver: yupResolver(triggerSchema),
    defaultValues: {
      triggerType: 1,
      interval: null,
      startFrom: null,
      hrs: null,
      mins: null,
      cronExpression: "",
      scheduleType: 1,
    },
  });

  useEffect(() => {
    if (trigger) {
      const { pwt_trigger_type, pwt_trigger_value } = trigger;
      const interval = findLabelAndValue({
        label: pwt_trigger_value?.simple.interval,
        type: "interval",
      });
      const startFrom = findLabelAndValue({
        label: pwt_trigger_value?.simple.start_at_day,
        type: "days",
      });
      const hrs = findLabelAndValue({
        label: pwt_trigger_value?.simple.start_at_time_hour,
        type: "hrs",
      });
      const mins = findLabelAndValue({
        label: pwt_trigger_value?.simple.start_at_time_min,
        type: "mins",
      });
      reset((formValues) => ({
        ...formValues,
        triggerType: pwt_trigger_type,
        interval: interval ? interval : null,
        startFrom: startFrom ? startFrom : null,
        hrs: hrs ? hrs : null,
        mins: mins ? mins : null,
        scheduleType: 1,
        cronExpression: pwt_trigger_value?.cron ? pwt_trigger_value?.cron : "",
      }));
    }
  }, [workflow]);
  const onClose = () => {
    setOpen(!open);
  };

  return (
    <TriggerContainer>
      <div className="header-container">
        <div className="header" onClick={onClose}>
          <div className="header-left">
            <div className="icon-container">
              <img src={TriggerIcon} alt="" />
            </div>
            <div>Trigger</div>
          </div>
          <div className="expand d-flex align-items-center">
            {open ? (
              <img src={UpArrowIcon} alt="" />
            ) : (
              <img src={DownArrowIcon} alt="" />
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames("trigger-container", {
          "is-open": open,
        })}
      >
        <div className="trigger-divider" />
        <div className="trigger-type align-items-center d-flex flex-grow-1">
          <div className="manual-button">
            <Controller
              control={control}
              name="triggerType"
              render={({ field }) => (
                <RadioButton
                  {...field}
                  hasError={!!errors.triggerType}
                  label="Manual"
                  onClick={() => {
                    setValue("triggerType", 1);
                    setTriggerType(1);
                    clearErrors();
                  }}
                  id="manual"
                  value=""
                  checked={triggerType === 1}
                />
              )}
            />
          </div>
          <div className="schedule-button ms-4">
            <Controller
              control={control}
              name="triggerType"
              render={({ field }) => (
                <RadioButton
                  {...field}
                  className="mr-4"
                  hasError={!!errors.triggerType}
                  label="On a Schedule"
                  onClick={() => {
                    setValue("triggerType", 2);
                    setTriggerType(2);
                    clearErrors();
                  }}
                  value=""
                  id="schedule"
                  checked={triggerType === 2}
                />
              )}
            />
          </div>
        </div>
        {triggerType === 2 ? (
          <TimeScheduleForm
            control={control}
            scheduleType={scheduleType}
            setScheduleType={setScheduleType}
            setValue={setValue}
            errors={errors}
            formType={scheduleType === 1 ? "simple" : "cron"}
            intervals={intervals}
            startFrom={startFrom}
            Hours={Hours}
            Minutes={Minutes}
            isSidebar={isSidebar}
            isTriggerLoading={isTriggerLoading}
            handleSubmit={handleSubmit}
            workflow={workflow}
          />
        ) : null}
      </div>
    </TriggerContainer>
  );
};
