import styled from "styled-components";

export const StatusTagContainer = styled.div`
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-size: 0.625rem;
    padding: 0.3125rem;
    font-weight: 500;
    max-width: 5rem;
    line-height: normal;

    &.s0 {
      border: 0.0625rem solid #ecb22e;
      background: #fdf7ea;
      color: #ecb22e;
    }
    &.s2 {
      border: 0.0625rem solid #f84550;
      background: rgba(248, 69, 80, 0.1);
      color: #f84550;
    }
    &.s3 {
      border: 0.0625rem solid #10a14b;
      background: rgba(46, 173, 98, 0.1);
      color: #10a14b;
    }
  }
`;
