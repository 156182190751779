import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, FormInput } from "../../../../components/common";
// import { Checkbox } from "../../../../components/common/Checkbox";
import { ActivateAccountContainer, MainContainer } from "./elements";
import { activateAccountSchema } from "../../../../utils/validation";
import {
  invitedUserVerification,
  setError,
  usersSelector,
  verifyInvitation,
} from "../../../../redux/slices//usersSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ToastMessage } from "../../../../components/common/ToastMessage";
import { useEffect, useState } from "react";
import { User } from "../../../../utils/types";
import { AuthNavbar } from "../../components";

export const ActivateAccount = () => {
  const { isLoading, error, organisationName } = useAppSelector(usersSelector);
  const [isUserActive, setIsUserActive] = useState<number | undefined>();
  const [params] = useSearchParams();
  const code = params.get("code");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(activateAccountSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
      // accept: false,
    },
  });

  useEffect(() => {
    if (code) {
      dispatch(
        verifyInvitation(code, (user: User) => {
          localStorage.removeItem("jwtToken");
          localStorage.removeItem("userId");
          setIsUserActive(user?.us_active);
        }),
      );
    }
  }, [code]);

  const onSubmit = (data: { password: string; confirmPassword: string }) => {
    if (code) {
      dispatch(
        invitedUserVerification(data, code, () => {
          navigate("/auth/login");
        }),
      );
    }
  };

  if (isUserActive) {
    navigate("/auth/login");
  }

  return (
    <>
      <Helmet>
        <title>Activate Account</title>
      </Helmet>
      <MainContainer>
        <div className="w-100">
          <AuthNavbar />
          <ActivateAccountContainer>
            <div className="update-password-container">
              <div className="title-container">
                <div className="title">Activate Your Account</div>
                <div className="sub-title">Must be at least 8 characters.</div>
              </div>
              <div className="form-container">
                <form
                  className="align-items-center d-flex flex-column w-100"
                  onSubmit={handleSubmit(onSubmit)}
                  method="POST"
                  noValidate
                >
                  <div className="input-field">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="user-password"
                          type="password"
                          width="100%"
                          placeholder="Enter Your Password"
                          errorMessage={errors.password?.message}
                          hasError={!!errors.password}
                          label="Password"
                        />
                      )}
                      name="password"
                    />
                  </div>
                  <div className="input-field">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="user-password"
                          type="password"
                          width="100%"
                          placeholder="Enter Your Confirm Password"
                          // autoComplete="disabled"
                          errorMessage={errors.confirmPassword?.message}
                          hasError={!!errors.confirmPassword}
                          label="Confirm Password"
                        />
                      )}
                      name="confirmPassword"
                    />
                  </div>
                  {/* <div className="align-items-start d-flex  mt-4 w-100">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          hasError={!!errors.accept}
                          errorMessage={errors.accept?.message}
                          label="I agree to the Terms & Privacy"
                        />
                      )}
                      name="accept"
                    />
                  </div> */}
                  <Button
                    label={`Join ${organisationName}`}
                    type="submit"
                    isLoading={isLoading}
                    className="w-100 update-password"
                  />
                </form>
              </div>
            </div>
          </ActivateAccountContainer>
        </div>
        {/* <div className="left-container">
          <Container />
        </div> */}
      </MainContainer>
      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
