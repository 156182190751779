import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export const InviteUserModalContainer = styled(Modal)`
  .modal-content {
    border: unset !important;
  }
  .modal-title {
    font-weight: 500;
    font-size: 1.5rem;
    color: #000000;
  }
  .modal-content {
    border-radius: 0.5rem;
    max-width: 29rem;
  }

  .title-container {
    margin-top: 0.5rem;
    .title {
      color: #000;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.75rem;
    }
    .sub-title {
      color: #000;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
    }
  }
  .close-btn {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
  }
  .modal-header {
    align-items: unset;
    border-bottom: unset !important;
    padding: 1.5rem;
  }
  .modal-body {
    padding: 0 1.5rem;
  }
  .modal-footer {
    border-top: unset !important;
    padding: 1.5rem;

    button {
      border-radius: 0.5rem;
      margin: unset !important;
      padding: 0.5625rem 1.0625rem;

      span {
        font-size: 1rem;
        line-height: 1.5rem; /* 150% */
      }
    }
    .cancel-btn {
      margin-right: 0.75rem !important;
      border: 0.0625rem solid #d0d5dd;
      box-shadow: 0rem 0.0625rem 0.0125rem 0rem rgba(16, 24, 40, 0.05);
      span {
        color: #344054 !important;
      }
    }
  }
  .btn-close {
    margin: unset;
  }
  .modal-header {
    display: flex;
    flex-direction: column;
  }
  .input-field {
    .label {
      line-height: 1.25rem;
      margin-bottom: 0.375rem !important;
    }
    .input-container,
    .react-select__control {
      height: 2.875rem;
    }
  }
`;
