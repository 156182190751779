import classNames from "classnames";

import { StatusTagContainer } from "./elements";

export const StatusTag = (value: any) => {
  let output;
  switch (value) {
    case 1:
      output = (
        <div
          className={classNames("status", {
            s0: value == 1,
          })}
        >
          <div className="status-text">Progress</div>
        </div>
      );
      break;
    case 2:
      output = (
        <div
          className={classNames("status", {
            s2: value == 2,
          })}
        >
          <div className="status-text">Failed</div>
        </div>
      );
      break;
    case 3:
      output = (
        <div
          className={classNames("status", {
            s3: value == 3,
          })}
        >
          <div className="status-text">Success</div>
        </div>
      );
      break;
    default:
      output = (
        <div
          className={classNames("status", {
            s0: value == 0,
          })}
        >
          <div className="status-text">Not Started</div>
        </div>
      );
      break;
  }

  return <StatusTagContainer>{output}</StatusTagContainer>;
};
