import styled from "styled-components";

export const APISContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 3.25rem 2.5rem 2rem 3.25rem;
  .title-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .title {
    font-weight: 500;
    font-size: 1.25rem;
    color: #000000;
  }
`;
