import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92.123vh;
  flex-direction: column;
  width: 100%;
  .login-container {
    width: 22.5rem;
  }
  .form-container {
    padding-top: 1rem;
    .input-field {
      width: 100%;
      padding-top: 1.5rem;
      div {
        height: 4.5rem !important;
      }

      .label {
        margin-bottom: 0.375rem !important;
        line-height: 1.25rem;
        color: #344054;
      }
    }
  }
  .forgot-password-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
  .forgot-password {
    font-weight: 600;
    font-size: 0.75rem;
    color: #655aec;
    line-height: normal;
  }

  .title-container {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: 600;
    font-size: 2rem;
    color: #333333;
    line-height: normal;
  }
  .sub-title {
    font-weight: 500;
    font-size: 0.75rem;
    color: #000000;
    line-height: normal;
    margin-top: 0.75rem;
  }
  .form-check {
    input {
      width: 1.125rem;
      height: 1.125rem;
      border: 0.0625rem solid #000000;
      border-radius: 0.3125rem;
    }
    label {
      font-weight: 500;
      font-size: 0.75rem;
      color: #000000;
    }
  }
  .login-btn {
    height: 3.25rem;
    margin-top: 1.5rem;
    .label {
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  .left-container {
    width: 50%;
    margin: 1.125rem;
  }
  /* @media (max-width: 62rem) {
    .left-container {
      display: none;
    }
  } */
`;
