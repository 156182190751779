import { useState } from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

import { TabsContainer } from "./elements";

import IntegrationIcon from "../../../../../assets/navbar-icon/integration.svg";
import IntegrationActiveIcon from "../../../../../assets/navbar-icon/integration-active.svg";
import WorkflowActiveIcon from "../../../../../assets/navbar-icon/workflow-active-icon.svg";
import WorkflowIcon from "../../../../../assets/navbar-icon/worklfow-icon.svg";
import SettingsIcon from "../../../../../assets/navbar-icon/settings-icon.svg";
import SettingsActiveIcon from "../../../../../assets/navbar-icon/settings-active-icon.svg";

interface TabProps {
  orgId: number | null;
  projectId: number | null;
}
export const Tabs = ({ orgId, projectId }: TabProps) => {
  const [active, setActive] = useState<any | null>("");
  const { pathname } = useLocation();

  return (
    <TabsContainer>
      <Nav
        activeKey={active}
        onSelect={(selectedKey) => {
          setActive(selectedKey);
        }}
      >
        {pathname.includes("/api") ? (
          <Nav.Item
            className={classNames({
              "active-link": pathname.includes("/api"),
            })}
          >
            <NavLink
              to={`/org/${orgId}/project/${projectId}/api`}
              id="api"
              className="d-flex align-items-center"
            >
              <div className="img-container">
                {pathname.includes("/api") ? (
                  <img src={IntegrationActiveIcon} alt="" />
                ) : (
                  <img src={IntegrationIcon} alt="" />
                )}
              </div>
              <div>APIs</div>
            </NavLink>
          </Nav.Item>
        ) : null}

        <Nav.Item
          className={classNames({
            "active-link": pathname.includes("/workflows"),
          })}
        >
          <NavLink
            to={`/org/${orgId}/project/${projectId}/workflows`}
            id="workflows"
            className="d-flex align-items-center"
          >
            <div className="img-container">
              {pathname.includes("/workflows") ? (
                <img src={WorkflowActiveIcon} alt="" />
              ) : (
                <img src={WorkflowIcon} alt="" />
              )}
            </div>
            <div>Workflows</div>
          </NavLink>
        </Nav.Item>
        {/* <Nav.Item
          className={classNames("mt-1", {
            "active-link": pathname.includes("/integrations"),
          })}
        >
          <NavLink to={`integrations`} id="integrations">
            Integrations
          </NavLink>
        </Nav.Item> */}

        <Nav.Item
          className={classNames({
            "active-link": pathname.includes("/settings"),
          })}
        >
          <NavLink
            to={`/org/${orgId}/project/${projectId}/settings`}
            id="settings"
            className="d-flex align-items-center"
          >
            <div className="img-container">
              {pathname.includes("/settings") ? (
                <img src={SettingsActiveIcon} alt="" />
              ) : (
                <img src={SettingsIcon} alt="" />
              )}
            </div>
            <div>Settings</div>
          </NavLink>
        </Nav.Item>
        {/* <Nav.Item
          className={classNames("mt-1", {
            "active-link": pathname.includes("/settings"),
          })}
        >
          <NavLink to={`settings`} id="settings">
            Settings
          </NavLink>
        </Nav.Item> */}
      </Nav>
    </TabsContainer>
  );
};
