/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";

import {
  Button,
  CustomModal,
  ToastMessage,
} from "../../../../../../components/common";
import { Table } from "../../../../../../components/common/Table";

import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { authSelector } from "../../../../../../redux/slices/authSlice";
import {
  fetchAllOrgUser,
  removeInvitedUser,
  usersSelector,
} from "../../../../../../redux/slices/usersSlice";

import { Role, Status } from "./components";
import { UsersListContainer } from "./elements";

import WarningIcon from "../../../../../../assets/icons/warning.svg";
import FailedIcon from "../../../../../../assets/icons/close-sm.svg";
import ActiveIcon from "../../../../../../assets/icons/success.svg";
import PendingIcon from "../../../../../../assets/icons/not-started.svg";

interface UserData {
  userId: number;
  userName: string | undefined;
}
interface List {
  onClick: () => void;
  userType: number | null;
}
export const UserList = ({ onClick, userType }: List) => {
  const { user } = useAppSelector(authSelector);
  const { users, isLoading } = useAppSelector(usersSelector);
  const [message, setMessage] = useState("");
  const [userDetails, setUserDetails] = useState<UserData | null>(null);
  const dispatch = useAppDispatch();
  const { orgId } = useParams();

  // check user type is owner or admin
  const isValidUser = userType === 1 || userType === 2;

  useEffect(() => {
    if (!users && orgId) {
      dispatch(fetchAllOrgUser(Number(orgId)));
    }
  }, [orgId]);

  const removeUser = (userdata: UserData | null) => {
    if (userdata)
      dispatch(
        removeInvitedUser(userdata?.userId, Number(orgId), () => {
          setUserDetails(null);
          setMessage(`${userdata?.userName} removed sucessfully`);
        }),
      );
  };

  const renderStatus = (value: number) => {
    switch (value) {
      case 1:
        return ActiveIcon;
        break;
      case 0:
        return PendingIcon;
        break;
      default:
        return FailedIcon;
    }
  };
  const userColumns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   id: "index",
      //   accessor: (_row: any, i: number) => i + 1,
      // },
      {
        Header: "Name",
        Cell: ({ row: { original } }: any) => (
          <div className="d-flex align-items-center">
            <img src={renderStatus(original.ou_active)} alt="" />
            <div className="ms-3">{original.user.us_full_name}</div>
          </div>
        ),
        accessor: "user",
        className: "user_name",
      },
      {
        Header: "Email",
        accessor: "user.us_email",
        className: "user_email",
      },
      {
        Header: "Role",
        Cell: ({ value }: any) => <Role role={value} />,
        accessor: "ou_user_type",
        className: "user_email",
      },
      {
        Header: "Status",
        Cell: ({ row: { original } }) => <Status value={original?.ou_active} />,
        accessor: "status",
        className: "status",
      },
      {
        Header: "",
        Cell: ({ row: { original } }) => {
          if (isValidUser) {
            return (
              <div
                className={classNames("remove-button", {
                  "is-visible":
                    user?.us_id !== original?.ou_user_id &&
                    original.ou_user_type !== 1,
                })}
              >
                <Button
                  onClick={() =>
                    setUserDetails({
                      userId: original.ou_id,
                      userName: original.user.us_full_name,
                    })
                  }
                  label="Remove"
                  isCancel={true}
                  width="150px"
                  // buttonStyles={{
                  //   border: "0.0625rem solid #605F5F",
                  //   borderRadius: "0.5rem",
                  // }}
                />
              </div>
            );
          }
        },
        accessor: "ou_id",
        className: "status",
      },
    ],
    [],
  );

  return (
    <>
      <UsersListContainer>
        <div className="user-list-container">
          <div className="user-header">
            <div className="count">{users.length} Users</div>
            <Button
              label={isValidUser ? "Invite Users" : undefined}
              onClick={onClick}
              className="invite-user-btn"
            />
          </div>
          <div className="divider" />
          <div className="table-container">
            <Table
              columns={userColumns}
              data={users}
              itemName="Users"
              type={1}
            />
          </div>
        </div>
      </UsersListContainer>

      <CustomModal
        show={userDetails?.userId ? !!userDetails.userId : false}
        onHide={() => {
          setUserDetails(null);
        }}
        onClick={() => {
          removeUser(userDetails);
        }}
        label="Remove"
        isLoading={isLoading}
        title="Confirm Remove"
        subTitle={`Are you sure you want to remove  ${userDetails?.userName}?`}
        isDelete={true}
        icon={WarningIcon}
      />
      <ToastMessage
        isVisible={!!message}
        message={message}
        type="danger"
        position="top-center"
        onClose={() => {
          setMessage("");
        }}
      />
    </>
  );
};
