import { AnimatePresence, motion } from "framer-motion";

import arrowIcon from "../../../../../assets/icons/arrow_down.svg";
import blueTick from "../../../../../assets/icons/tick.png";
import { SelectBoxContainer } from "./elements";

const SelectBox = ({
  className,
  icon,
  isOpen,
  items,
  label,
  onClick,
  reference,
}: any) => (
  <SelectBoxContainer
    className={`${isOpen ? "is-open" : ""} ${className}`}
    onClick={onClick}
    ref={reference}
  >
    <h5>{label}</h5>
    <img alt="Focus" src={icon || arrowIcon} />
    <AnimatePresence>
      {isOpen ? (
        <motion.div
          animate={{
            opacity: 1,
            translateY: 0,
          }}
          className="dropdown-items"
          exit={{
            opacity: 0,
            translateY: "0.5rem",
          }}
          initial={{
            opacity: 0,
            translateY: "0.5rem",
          }}
          transition={{
            duration: 0.2,
            type: "keyframes",
          }}
        >
          {items.map(
            (item: { name: string; onClick: () => void; label: string }) => (
              <button
                className="dropdown-item"
                key={item.name}
                onClick={item.onClick}
                type="button"
              >
                <span>{item.name}</span>
                {(item.name === "Show All" && label === "Sort By") ||
                item.name === label ? (
                  <img alt="Checked" src={blueTick} />
                ) : null}
              </button>
            ),
          )}
        </motion.div>
      ) : null}
    </AnimatePresence>
  </SelectBoxContainer>
);

export default SelectBox;
