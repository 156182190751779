import styled from "styled-components";

export const CheckboxContainer = styled.button`
  background-color: transparent;
  border: none;
  padding: 0.5rem 0;

  :active,
  :focus {
    outline: none;

    .icon-container {
      border-color: #dbe1e6 !important;
    }
  }

  .icon-container {
    border: 0.0625rem solid #000000;
    border-radius: 0.25rem;
    cursor: pointer;
    height: 1.25rem;
    overflow: hidden;
    width: 1.25rem;

    &.has-error {
      border-color: red;
    }

    &.is-checked {
      background-color: #dbe1e6;
      border-color: #dbe1e6;

      .icon {
        opacity: 1;
      }
    }

    .icon {
      height: 100%;
      opacity: 0;
      transition: opacity 0.1s;
      width: 100%;
      vertical-align: unset;
    }
  }

  .label {
    cursor: pointer;
    line-height: 1.125rem;
    color: #000000;
    text-align: left;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
  .error-message {
    bottom: -1.1575rem;
    color: red;
    font-size: 0.75rem;
    left: 0;
    line-height: 0.9075rem;
  }
`;
