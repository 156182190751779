import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";

import { Button, FormInput } from "../../../../../../../../components/common";

import { useAppDispatch } from "../../../../../../../../redux/hooks";
import { saveWorkflowAction } from "../../../../../../../../redux/slices/projectSlice";

import { StepsTypes } from "../../../../../../../../utils/types";
import { GoToUrlSchema } from "../../../../../../../../utils/validation";

import { GoToUrlContainer } from "./elements";
import { Header } from "../../../../common";

export const GoToUrl = ({
  workflowId,
  workflowStep,
  isActionOpen,
}: StepsTypes) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(isActionOpen ? true : false);

  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(GoToUrlSchema),
    defaultValues: {
      url: "",
    },
  });

  useEffect(() => {
    if (workflowStep) {
      reset((formValues) => ({
        ...formValues,
        url: workflowStep?.pwst_configuration.url,
      }));
    }
  }, [workflowStep]);

  const onSave = (data: { url: string }) => {
    setIsLoading(true);
    const goToUrlData = { ...data, workflow_step_id: workflowStep.pwst_id };
    dispatch(
      saveWorkflowAction(goToUrlData, workflowId, () => {
        setIsLoading(false);
      }),
    );
  };

  const onClose = () => {
    setOpen(!open);
    if (isDirty) {
      reset((formValues) => ({
        ...formValues,
        url: workflowStep?.pwst_configuration.url,
      }));
    }
  };

  return (
    <GoToUrlContainer
      onSubmit={handleSubmit(onSave)}
      method="POST"
      noValidate
      className={classNames({ "is-open": open })}
    >
      <div className="header-container">
        <Header
          stepTitle="Go To URL"
          workflowId={workflowId}
          workflowStepId={workflowStep.pwst_id}
          onClose={onClose}
          stepType={3}
          isActionOpen={open}
        />
      </div>
      <div
        className={classNames("form-container", {
          "is-open": open,
        })}
      >
        <div>
          <div className="mt-3">
            <Controller
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  id="url-id"
                  type="text"
                  label="URL"
                  width="100%"
                  placeholder="Enter your URL"
                  errorMessage={errors.url?.message}
                  hasError={!!errors.url}
                />
              )}
              name="url"
            />
          </div>
        </div>
        {isDirty ? (
          <>
            <div className="button-divider" />
            <div className="button-container">
              <Button
                label="Save"
                type="submit"
                isLoading={isLoading}
                isDisabled={!isDirty}
              />
            </div>
          </>
        ) : null}
      </div>
    </GoToUrlContainer>
  );
};
