import { Helmet } from "react-helmet-async";
import { AuthSwitchLink } from "..";
import { SuccessContainer } from "./elements";
import EmailIcon from "../../../../assets/icons/email.svg";

interface SuccessProps {
  title?: string;
  content?: string;
}

export const Success = ({ title, content }: SuccessProps) => {
  return (
    <>
      <Helmet>
        <title>Success | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <SuccessContainer>
        <div className="verify-container">
          <div className="success-container">
            <div className="icon-container">
              <img src={EmailIcon} alt="email" />
            </div>
            <div className="d-flex flex-column align-items-center title-content">
              <div className="title">{title}</div>
              <div className="content">{content}</div>
            </div>
            <AuthSwitchLink
              linkHref="/"
              linkText="Contact Support"
              text="Having Trouble?"
            />
          </div>
        </div>
      </SuccessContainer>
    </>
  );
};
