import { Route, Routes } from "react-router-dom";

import { NotFound } from "../pages/404";
import { Project } from "../pages/Projects";
import {
  Integrations,
  API,
  Settings,
  Workflows,
} from "../pages/Projects/components";
import { View, New } from "../pages/Projects/components/API/pages";

export const ProjectRoute = () => {
  return (
    <Routes>
      <Route element={<Project />}>
        <Route path={`/settings`} element={<Settings />} />
        <Route path={`/workflows/*`} element={<Workflows />} />

        <Route path={`/integrations`} element={<Integrations />} />
        <Route path={`/api/:apiId`} element={<View />} />
        <Route path={`/api/*`} element={<API />}>
          <Route path={`new`} element={<New />} />
        </Route>
        {/* <Route path={`/settings`} element={<Settings />} /> */}
        <Route path="*" element={<NotFound to={"/"} />} />
      </Route>
    </Routes>
  );
};
