import styled, { css } from "styled-components";
import { InputProps } from "../index";

export const InputElementContainer = styled.div`
  border: 0.0625rem solid #d0d5dd;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: border-color 0.1s, box-shadow 0.1s;
  height: 2.625rem;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  &.has-error {
    border-color: red !important;
    box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
      0 0 0 0.25rem rgba(253, 70, 70, 0.1);

    &.is-focused {
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem rgba(253, 70, 70, 0.1);
    }
  }

  &.is-focused {
    border-color: #686868;
    box-shadow: 0 0 0 0.02rem #686868;
  }

  /* 
  &.is-focused {
    border-color: #655aec;
    transition: border-color 0.1s, box-shadow 0.1s;
    box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
      0 0 0 0.25rem rgba(101, 90, 236, 0.1);
  } */

  .text-input {
    border: none;
    color: black;
    font-size: 0.875rem;
    padding: 0.75rem 1rem;
    height: 100%;
    &.is-large {
      padding: 1.1875rem 1.5rem;
    }

    :active,
    :focus {
      outline: none;
    }

    ::placeholder {
      color: #c3cfd9;
    }
  }

  .toggle-visibility-button {
    background-color: transparent;
    border: none;
    right: 0.5rem;

    :active,
    :focus {
      outline: none;
    }

    .visibility-icon {
      height: 100%;
      width: 100%;
    }
  }

  .status-icon {
    height: 1.25rem;
    right: 1.5rem;
    width: 1.25rem;

    &.spinner-icon {
      animation: spin 1s linear infinite;
    }
  }
`;

export const TextInputContainer = styled.div<InputProps>`
  ${({ maxWidth }) =>
    maxWidth
      ? css`
          max-width: ${maxWidth};
        `
      : ""};
  ${({ minWidth }) =>
    minWidth
      ? css`
          min-width: ${minWidth};
        `
      : ""};
  ${({ width }) =>
    width
      ? css`
          width: ${width} !important;
        `
      : ""};
  ${({ flexBasis }) =>
    flexBasis
      ? css`
          flex-basis: ${flexBasis} !important;
        `
      : ""};
  :disabled {
    color: #667085;
  }
  .label {
    align-items: center;
    color: #344054;
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0.5rem 0;
    img {
      height: 1rem;
      margin-left: 0.5rem;
      object-fit: contain;
      width: 1rem;
    }
  }
  .isLarge {
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
  }
  .sub-label {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 0.75rem;
    color: #475467;
    font-weight: 500;
  }

  .error-message {
    bottom: -1.1575rem;
    color: red;
    font-size: 0.75rem;
    left: 0;
  }
  .adp-tooltip--component {
    text-transform: none;
    max-width: 18.625rem;
  }
`;
