import axios from "../instance";

export const logIn = (data: { email: string; password: string }) =>
  axios.post("/auth/login", data);
export const signUp = (data: {
  name: string;
  password: string;
  email: string;
}) => axios.post("/auth/signup", data);
export const fetchProfile = () => axios.get("/auth/me");
export const verify = (token: string | null) =>
  axios.get(`/auth/verify?token=${token}`);
export const resetPassword = (data: { email: string }) =>
  axios.post("/auth/reset-password", data);
export const updatePassword = (
  data: { password: string; confirmPassword: string },
  token: string | null,
) => axios.post(`/auth/update-password?code=${token}`, data);

export const verifyResetPasswordToken = (token: string | null) =>
  axios.get(`/auth/validate-password-token?token=${token}`);
