import styled from "styled-components";

export const HeaderContanier = styled.div`
  border-radius: 0.5rem;
  border: none;
  width: 100%;
  outline: none;
  background: transparent;
  .header-left {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
  }
  .icon-container {
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    background: #655aec;
  }

  .expand {
    border: none;
    outline: none;
    background: transparent;
  }
  .delete-btn {
    outline: none;
    border: none;
    background: transparent;
    padding: unset;
    margin-right: 1rem;
  }
`;
