import { Route, Routes, useSearchParams } from "react-router-dom";
import { Login, ResetPassword, SignUp, Success } from "../pages/Auth/pages";

export const AuthRoute = () => {
  const [searchParams] = useSearchParams();
  const email = decodeURI(searchParams.get("email") || "");

  return (
    <Routes>
      <Route index path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route
        path="/signup/success"
        element={
          <Success
            title="Verify your email"
            content={`To get started, check your ${email} inbox.`}
          />
        }
      />

      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/reset-password/success"
        element={
          <Success
            title="An Email is sent to you"
            content={`An Email with password reset instructions has been sent to ${email}`}
          />
        }
      />
    </Routes>
  );
};
