import styled from "styled-components";

export const HeaderContainer = styled.div`
  flex-direction: column;
  .divider {
    border-bottom: 0.0625rem solid #eaecf0;
    margin-top: 1.625rem;
  }
  .back-btn {
    outline: none;
    border: none;
    background: transparent;
    padding: unset;
  }
  .text {
    color: #000;
    font-size: 1.25rem;
    font-weight: 600;
    margin-left: 1rem;
  }
`;
