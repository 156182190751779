import { RoleContainer } from "./elements";

export const Role = ({ role }: any) => {
  let label;
  const renderRole = (value: number) => {
    if (value === 1) {
      label = "Owner";
      return label;
    }
    if (value === 2) {
      label = "Admin";
      return label;
    }
    if (value === 3) {
      label = "Member";
      return label;
    }
    if (value === 4) {
      label = "Watcher";
      return label;
    }
  };
  return (
    <RoleContainer>
      <div className="role">{renderRole(role)}</div>
    </RoleContainer>
  );
};
