import styled from "styled-components";

export const SideBarMain = styled.div`
  background: #ffffff;
  border-style: solid;
  border-color: transparent;
  border-width: 0px 1px 0px 0px;
  width: 553px;
  height: 944px;
  padding: 0px;
  margin: 0px;
  position: relative;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);

  .header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px #f3f2f2 solid;

    .icon-container {
      display: flex;
      align-items: center;
      text-align: left;
      font: 600 16px/20px "Inter", sans-serif;
      position: relative;

      .icon-text {
        padding-left: 16px;
      }
    }
    .close-img {
      height: 32px;
      width: 32px;

      &:hover {
        cursor: pointer;
      }
    }
    .icon-container-api {
      width: 2.75rem;
      height: 2.75rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #655aec;
    }
  }
  .input {
    position: relative;
    display: flex;
    align-items: center;
    .input-field {
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      padding: 11px 10px 11px 30px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      justify-content: center;
      width: 505px;
      position: relative;
      margin: 16px 24px;
      font: 500 16px/20px;

      &:focus-visible {
        border: 2px solid #d9d9d9;
      }
      :focus {
        outline: none;
      }
    }
    .search-icon {
      position: absolute;
      left: 2rem;
      opacity: 0.5;
      height: 16px;
      width: 16px;
    }
  }
  .schedule {
    .time-schedule {
      height: 60px;
      width: 504px;
      display: flex;
      align-items: center;
      margin: 0px 24px;
      background: #ffffff;
      border-radius: 5px;
      border: 2px solid #eaecf0;
      font-weight: 500;
      &:hover {
        cursor: pointer;
      }
    }
    .icon-container {
      border-radius: 5px;
      border-style: solid;
      border-color: #d0d5dd;
      border-width: 1px;
      height: 40px;
      width: 40px;
      padding: 5px;
      margin: 10px 16px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
    }
    .schedule-text {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
    }
  }
`;
