import styled from "styled-components";

export const APIContainer = styled.div`
  height: 100%;
  .main-container {
    display: flex;
  }
  .data-container {
    width: 100%;
  }
  .project-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #000000;
    text-transform: capitalize;
    padding: 2.5rem 2.5rem 0 2.5rem;
  }
  .content {
    height: 100%;
    background: #f8f7fb;
  }
  .title-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .endpoint-container {
    padding: 2.5rem;
  }

  .title {
    font-weight: 500;
    font-size: 1.25rem;
    color: #000000;
  }
  .swagger-btn {
    padding: 0.5rem 0.875rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid rgb(101, 90, 236);
    /* cursor: pointer; */
    :hover {
      border: 0.0625rem solid rgb(222, 222, 222);
    }
  }
  .swagger-link {
    text-decoration: none !important;
    display: flex;
    .label {
      font-weight: 500;
      color: rgb(101, 90, 236) !important;
      margin-left: 0.25rem;
    }
  }
`;
