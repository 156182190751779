import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const InstructionsContainer = styled(Modal)`
  .instruction-modal-body {
    padding: 2.5rem;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 1.5rem;
    color: #000000;
    button {
      outline: none;
      border: none;
      background: transparent;
      padding: unset;
    }
  }
  .sub-title {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
    margin-top: 2.5rem;
  }
  .list {
    margin-top: 1.25rem;
  }
  .copy-code {
    position: relative;
    width: 100%;
    margin-top: 1.25rem;
    button {
      border: none;
      background: transparent;
      outline: none;
      padding: unset;
    }

    pre {
      width: 100%;
    }
  }
  code {
    min-height: 18rem;
  }
  .copy-icon {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }
  .close-button-container {
    margin-top: 1.25rem;
  }
  .points {
    font-weight: 600;
  }
`;
