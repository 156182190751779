export const fieldsDataGenerator = () => {
  const dummyIntervals = [
    "15 min",
    "30 min",
    "1 hour",
    "3 hours",
    "6 hours",
    "9 hours",
    "12 hours",
    "1 day",
    "7 days",
  ];
  const intervals = [];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const startFrom = [];
  const Hours = [];
  const Minutes = [];

  for (let i = 0; i < dummyIntervals.length; i++) {
    intervals.push({ label: dummyIntervals[i], value: i + 1 });
  }
  for (let i = 0; i < days.length; i++) {
    startFrom.push({ label: days[i], value: i + 1 });
  }
  for (let i = 1; i < 24; i++) {
    Hours.push({ label: i.toString(), value: i });
  }
  for (let i = 1; i < 60; i++) {
    Minutes.push({ label: i.toString(), value: i });
  }
  return { intervals, startFrom, Hours, Minutes };
};
