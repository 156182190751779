import { ReactElement } from "react";
import Modal from "react-bootstrap/Modal";

import { InviteUserModalContainer } from "./elements";

import CloseIcon from "../../../../../../assets/icons/close.svg";
import UserIcon from "../../../../../../assets/icons/invite-user.svg";
import { Button } from "../../../../../../components/common";

interface ModalProps {
  show: boolean;
  onHide: () => void;
  children?: ReactElement | null | undefined;
  onClick: () => void;
  isLoading: boolean;
  isDisabled?: boolean;
  label?: string;
  dialogClassName?: string;
  contentClassName?: string;
}

export const InviteUserModal = ({
  show,
  onHide,
  children,
  onClick,
  isLoading,
  isDisabled,
  label,
  dialogClassName,
  contentClassName,
}: ModalProps) => {
  return (
    <InviteUserModalContainer
      dialogClassName={dialogClassName}
      contentClassName={contentClassName}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton={false} className="modal-header">
        <div className="d-flex justify-content-between">
          <div>
            <img src={UserIcon} alt="" />
          </div>
          <button type="button" className="close-btn" onClick={onHide}>
            <img src={CloseIcon} alt="x" />
          </button>
        </div>
        <div className="title-container">
          <div id="contained-modal-title-vcenter" className="title">
            Invite User
          </div>
          <div className="sub-title">
            Invite a new user to your organization
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          label="Cancel"
          onClick={onHide}
          isFullWidth={false}
          isLarge={false}
          isCancel
          className="cancel-btn"
        />
        <Button
          label={label}
          type="submit"
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={onClick}
          isFullWidth={false}
          isLarge={false}
        />
      </Modal.Footer>
    </InviteUserModalContainer>
  );
};
