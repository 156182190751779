import { useState } from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

import { SideNavBarContainer } from "./elements";

import PasswordActiveIcon from "../../../../../assets/icons/password-active.svg";

import { CollapseAndExpandProps } from "../../../../../utils/types";
import { CollapseAndExpand } from "../../../../../components/common";

export const SideNavBar = ({
  setCollapse,
  collapse,
}: CollapseAndExpandProps) => {
  const [active, setActive] = useState<any | null>("");
  const { pathname } = useLocation();

  return (
    <SideNavBarContainer>
      <Nav
        activeKey={active}
        className="flex-column"
        onSelect={(selectedKey) => {
          setActive(selectedKey);
        }}
      >
        <Nav.Item
          className={classNames({
            "active-link": pathname.includes("/change-password"),
          })}
        >
          <NavLink
            to="change-password"
            id="change-password"
            className="d-flex align-items-center flex-column justify-content-center"
          >
            <div className="img-container">
              {pathname.includes("/change-password") ? (
                <img src={PasswordActiveIcon} alt="" />
              ) : (
                <img src={PasswordActiveIcon} alt="" />
              )}
            </div>
            {!collapse ? <div className="text">Password</div> : null}
          </NavLink>
        </Nav.Item>
      </Nav>
      <CollapseAndExpand collapse={collapse} setCollapse={setCollapse} />
    </SideNavBarContainer>
  );
};
