import styled from "styled-components";

export const ViewLogContainer = styled.div`
  padding: 2.5rem;
  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header {
    padding: 1rem 1.5rem;
  }
  .header-left {
    display: flex;
    align-items: center;
  }
  .log-container {
    border: 0.0625rem solid rgba(101, 90, 236, 0.1);
    border-radius: 0.5rem;
    background: white;
    stroke: rgba(101, 90, 236, 0.1);
    margin-top: 1rem;
    &.is-failed {
      border: 0.0625rem solid rgba(224, 30, 90, 0.06);
      background: rgba(224, 30, 90, 0.06);
    }
  }
  .drop-button {
    outline: none;
    background: transparent;
    border: none;
    padding-left: 2rem;
    display: flex;
  }
  .data-container {
    display: none;

    &.is-open {
      display: flex;
      flex-direction: column;
    }
  }
  .request,
  .status-div,
  .response-div {
    display: flex;
    border-radius: 0.5rem;
    background: #f8f7fb;
    padding: 0.5rem 0.625rem;
    margin-top: 1rem;
    width: fit-content;
  }

  .status {
    height: 1.125rem;
    width: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .step-count {
    color: #808080;
    font-size: 1rem;
    font-weight: 500;
  }

  .vertical-divider {
    margin: 0rem 1.5rem;
    min-height: 100%;
    background: #eaecf0;
    width: 0.0625rem;
    &.is-failed {
      background: #d6d6d6;
    }
  }
  .splitter {
    margin-bottom: 0.5rem;
    min-height: 100%;
    background: #eaecf0;
    width: 100%;
    height: 0.0625rem;
    &.is-failed {
      background: #d6d6d6;
    }
  }
  .run-step {
    display: flex;
    flex-direction: column;
    .step-title {
      color: #000;
      font-size: 1rem;
      font-weight: 600;
    }
    .time {
      padding-top: 0.375rem;
      color: #808080;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  .output-data {
    color: #344054;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
  .run-again {
    border-radius: 0.5rem;
    img {
      margin-right: 0.5rem !important;
    }
  }
  .edit-button {
    border-radius: 0.3rem;
    background: #f84550;
    outline: none;
    border: none;
    padding: 0.1875rem 0.75rem;
    width: 3.1875rem;
    height: 1.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 2rem;
    div {
      color: #fff;
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
  .time-taken {
    /* display: none; */
    color: #605f5f;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    padding-right: 1.5rem;
    &.is-failed {
      color: #e01e5a;
    }
  }
`;
