import classNames from "classnames";

import ErrorIcon from "../../../../../../assets/icons/close-sm.svg";
import SuccessIcon from "../../../../../../assets/icons/success.svg";
import NotStartedIcon from "../../../../../../assets/icons/not-started.svg";
import { StatusContainer } from "../../elements";

export const Status = (value: any) => {
  let output;
  switch (value) {
    case 2:
      output = (
        <div
          className={classNames("status", {
            s2: value == 2,
          })}
        >
          <img src={ErrorIcon} alt="" />
        </div>
      );
      break;
    case 3:
      output = (
        <div
          className={classNames("status", {
            s3: value == 3,
          })}
        >
          <img src={SuccessIcon} alt="" />
        </div>
      );
      break;

    default:
      output = (
        <div
          className={classNames("status", {
            s0: value == 0,
          })}
        >
          <img src={NotStartedIcon} alt="" />
        </div>
      );
      break;
  }

  return <StatusContainer>{output}</StatusContainer>;
};
