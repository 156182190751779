import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Button, FormInput } from "../../../../../../components/common";
import { yupResolver } from "@hookform/resolvers/yup";
import { createApiSchema } from "../../../../../../utils/validation";
import { APIType } from "../../../../../../utils/types";
import {
  onCreateAPI,
  projectSelector,
  showModal,
} from "../../../../../../redux/slices/projectSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { Import } from "../components/Import";
import CloseIcon from "../../../../../../assets/icons/close.svg";
import { APIAddManualContainer, APIImportContainer } from "./elements";
import { FormTextArea } from "../../../../../../components/common/FormTextArea";

export const New = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { modalOpen, apiCreateError } = useAppSelector(projectSelector);
  const [showImportAPIModal, setShowImportAPIModal] = useState<boolean>(true);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(createApiSchema),
    defaultValues: {
      name: "",
      baseUrl: "",
      description: "",
    },
  });

  const onSubmit = (data: any) => {
    const response: APIType = data;
    const { name, baseUrl, description } = response;

    const api = {
      api_title: name,
      api_description: description,
      api_server_url: baseUrl,
    };

    dispatch(
      onCreateAPI(api, () => {
        dispatch(showModal(false));
        navigate(-1);
      }),
    );
  };

  const onError = (errors: any, e: any) => {
    console.log("onError");
    console.log(errors, e);
  };

  const onHide = () => {
    navigate(-1);
  };

  return (
    <>
      <APIImportContainer
        show={showImportAPIModal}
        onHide={onHide}
        centered
        dialogClassName="api-add-dialog-modal"
        contentClassName="api-add-content-modal"
      >
        <Modal.Header>
          <div className="header">
            <div className="title">
              <div>Add API</div>
              <button type="submit" onClick={onHide}>
                <img src={CloseIcon} alt="x" />
              </button>
            </div>
            <div className="sub-title">
              Import using OpenAPI Spec ( Swagger )
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Import
            onAddManualy={() => {
              dispatch(showModal(true));
              setShowImportAPIModal(false);
            }}
            onHide={onHide}
          />
        </Modal.Body>
      </APIImportContainer>
      <APIAddManualContainer
        centered
        show={modalOpen}
        onHide={onHide}
        dialogClassName="api-add-dialog-modal"
        contentClassName="api-add-content-modal"
      >
        <Modal.Body className="api-add-manually-body">
          <div className="add-manually-container">
            <div className="header">
              <div className="title">
                <div>Add API Manually</div>
                <button
                  type="submit"
                  onClick={() => {
                    dispatch(showModal(false));
                    navigate(-1);
                  }}
                >
                  <img src={CloseIcon} alt="x" />
                </button>
              </div>
            </div>
            {/* <p>
              Provide the details of your REST API. You would need to add the
              endpoint in the next step
            </p> */}
            <form
              className="d-flex flex-column w-100 form"
              onSubmit={handleSubmit(onSubmit, onError)}
              method="POST"
              noValidate
            >
              <div className="input-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      id="user-name-id"
                      type="input"
                      label="What should be call this API?"
                      placeholder="V3"
                      errorMessage={errors?.name?.message}
                      hasError={!!errors.name}
                    />
                  )}
                  name="name"
                />
              </div>
              <div className="input-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormTextArea
                      {...field}
                      id="description"
                      label="Description"
                      sublabel="Write about what your API is used to do"
                      errorMessage={errors.description?.message}
                      hasError={!!errors.description}
                      rows={6}
                    />
                  )}
                  name="description"
                />
              </div>
              <div className="input-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      id="baseurl"
                      type="input"
                      label="Enter the API base URL"
                      sublabel="If you want to test locally, provide your local address (https://localhost:9000). You may change this later."
                      placeholder="https://api.example.com/v3"
                      errorMessage={errors.baseUrl?.message}
                      hasError={!!errors.baseUrl}
                    />
                  )}
                  name="baseUrl"
                />
              </div>

              <div className="d-flex flex-row d-grid justify-content-end add-button-container">
                <div>
                  <Button
                    label="Cancel"
                    type="reset"
                    isCancel
                    onClick={() => {
                      dispatch(showModal(false));
                      navigate(-1);
                    }}
                  />
                </div>
                <div className="add-api-btn">
                  <Button label="Add API" type="submit" />
                </div>
              </div>
            </form>
            <span className="text-danger">{apiCreateError}</span>
          </div>
        </Modal.Body>
      </APIAddManualContainer>
    </>
  );
};
