import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export const ModalContainer = styled(Modal)`
  .modal-title {
    font-weight: 500;
    font-size: 1.5rem;
    color: #000000;
  }
  .modal-content {
    border-radius: 1rem;
    min-width: 34rem;
  }

  .title-container {
    &.is-delete {
      padding: 0 1.5rem;
      .title {
        line-height: 1.75rem;
      }
      .sub-title {
        line-height: 1.25rem;
      }
    }

    .title {
      color: #101828;
      font-size: 1.125rem;
      font-weight: 600;
    }
    .sub-title {
      margin-top: 0.5rem;
      color: #667085;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  .close-btn {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
  }
  .modal-header {
    align-items: unset;
    border-bottom: unset !important;
    padding: 2rem;
  }
  .modal-body {
    padding: 0 2rem;
  }
  .modal-footer {
    border-top: unset !important;
    padding: 2rem;
    &.is-delete {
      padding-top: 3rem !important;
      button {
        margin: unset !important;
        padding: 0.5rem 1rem;
        .label {
          line-height: 1.5rem;
          font-size: 1rem;
          font-weight: 600;
        }
        &.is-cancel {
          margin-right: 0.75rem !important;
        }
      }
    }
    button {
      border-radius: 0.5rem;
    }
  }
  .btn-close {
    margin: unset;
  }
  .modal-header {
    &.is-delete {
      padding-bottom: unset;
    }
  }
  .create-project-modal {
    min-width: 48.3125rem !important;
  }

  .create-project-modal {
    .modal-header {
      padding: 2.5rem !important;

      .title-container {
        .title {
          font-size: 1.5rem !important;
          font-weight: 500 !important;
          line-height: 110%;
          letter-spacing: -0.045rem;
        }
      }
      .close-btn {
        display: none !important;
      }
    }

    .modal-footer {
      padding: 1.5rem 2.5rem 2.5rem 2.5rem !important;
      margin: unset !important;
    }
    .modal-footer {
      button {
        height: 2.25rem !important;
        padding: 0.625rem 1rem !important;
        .label {
          font-weight: 600;
          line-height: 1.25rem;
        }
        &.is-cancel {
          margin-right: 0.5rem;
        }
      }
    }
  }
`;
