import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from "reactflow";
import MakeAnAPIRequestIcon from "../../../../../../../../../assets/icons/api-request.svg";
import MakeUrlIcon from "../../../../../../../../../assets/icons/go-to-url.svg";
import MakeMessageIcon from "../../../../../../../../../assets/icons/message.svg";
import { ButtonEdgedContainer } from "./elements";
import { ModalContainer } from "../ButtonEdge/elements";
import Modal from "react-bootstrap/Modal";
import AddIcon from "../../../../../../../../../assets/icons/Plus-icon.svg";
import { useCallback, useMemo, useState } from "react";
import CloseIcon from "../../../../../../../../../assets/icons/close.svg";
import searchIcon from "../../../../../../../../../assets/icons/search.svg";
import {
  createWorkflowStep,
  projectSelector,
} from "../../../../../../../../../redux/slices/projectSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/hooks";

export const EmptyEdge = ({
  // id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  // setIsSelected,
  // addNode,
  // source,
  // target,
  curvature = 0,
}: EdgeProps | any) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    curvature,
  });
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { workflow_steptypes, workflow, workflowSteps } =
    useAppSelector(projectSelector);

  const renderIcon = (type: number) => {
    switch (type) {
      case 1:
        return MakeAnAPIRequestIcon;

      case 2:
        return MakeMessageIcon;

      case 3:
        return MakeUrlIcon;

      default:
        return;
    }
  };
  const addActions = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: any) => {
      setIsDropdownOpen(false);
      dispatch(
        createWorkflowStep(
          {
            order: workflowSteps?.length + 1,
            step: data.value,
          },
          workflow?.pw_id,
        ),
      );
    },
    [workflowSteps],
  );
  const addActionOptions = useMemo(
    () =>
      workflow_steptypes?.map(
        (item: {
          pwstt_id: number;
          pwstt_description: string;
          pwstt_sample_configuration: string;
        }) =>
          ({
            value: item.pwstt_id,
            label: item.pwstt_description,
            configuration: item.pwstt_sample_configuration,
          } || []),
      ),
    [workflow_steptypes],
  );

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          strokeWidth: 1,
          stroke: "#605F5F",
        }}
      />
      <EdgeLabelRenderer>
        <ButtonEdgedContainer
          style={{
            position: "absolute",
            transform: `translate(-50%, 70%) translate(${sourceX}px,${sourceY}px)`,
            transition: " transform 0.4s cubic-bezier(0.35, 0, 0.25, 1)",
            pointerEvents: "all",
            background: "transparent",
          }}
          className="nodrag nopan"
        >
          <button
            className="add-actions-button"
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen);
            }}
          >
            <img src={AddIcon} alt="+" />
          </button>
          {/* modal container which shows the dropdowns of new actions to select */}
          <ModalContainer
            centered
            show={isDropdownOpen}
            onHide={() => {
              setIsDropdownOpen(false);
            }}
            size="md"
          >
            <Modal.Body className="modal-body">
              <div className="add-action">
                <div className="action-text">Add Action</div>
                <div className="icon">
                  <img
                    onClick={() => {
                      setIsDropdownOpen(false);
                    }}
                    src={CloseIcon}
                    alt="close-icon"
                  />
                </div>
              </div>
              <div className="input">
                <input
                  placeholder="Search"
                  className="input-field"
                  type="text"
                />
                <img className="search-icon" src={searchIcon} alt="" />
              </div>
              <div className="select-container">
                {addActionOptions?.map((item, index) => {
                  //currently only api request works thats why checking
                  if (item.value === 1)
                    return (
                      <button
                        key={index}
                        type="button"
                        onClick={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                        ) => addActions(e, item)}
                        className="add-action-field"
                      >
                        {/* different icons based on values */}
                        <div className="icon-container">
                          <img src={renderIcon(item.value)} alt="" />
                        </div>
                        <div className="action-text">{item.label}</div>
                      </button>
                    );
                })}
              </div>
            </Modal.Body>
          </ModalContainer>
        </ButtonEdgedContainer>
      </EdgeLabelRenderer>
    </>
  );
};
