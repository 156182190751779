import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinkContainer = styled(Link)`
  color: #6e63f6;
  font-size: 0.875rem;
  line-height: 1.3125rem;

  :hover {
    color: #6e63f6;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }
`;
