import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ThunkAction } from "redux-thunk";
import * as projectApi from "../../api/project";
import {
  APIResponse,
  APIEndpoints,
  Project,
  SelectedProject,
  WorkFlow,
  WorkflowStepTypes,
  WorkflowSteps,
  APIAuthMethod,
  WorkflowRuns,
  TriggerData,
} from "../../utils/types";
import { RootState } from "../store";

// import { setActiveOrganisationId } from "./organisationSlice";

interface projectState {
  error: null;
  isLoading: boolean;
  isAPILoading: boolean;
  projects: Project[];
  project: Project | null;
  selectedProject: SelectedProject | null;
  selectedProjectId: number | null;
  apis: APIResponse[];
  workflows: WorkFlow[];
  modalOpen: boolean;
  apiCreateError: string | null;
  apiEndpoints: APIEndpoints[];
  workflow_steptypes: WorkflowStepTypes[];
  workflowSteps: WorkflowSteps[];
  api: APIResponse | null;
  apiAuthMethods: APIAuthMethod[];
  workflow: WorkFlow | null;
  workflowRuns: WorkflowRuns[];
  isWorkflowRunLoading: boolean;
  workflowRun: WorkflowRuns | null;
  isTriggerLoading: boolean;
  isSelected: boolean;
  selectedNode: any;
}
const initialState: projectState = {
  error: null,
  isLoading: false,
  isAPILoading: false,
  projects: [],
  project: null,
  selectedProject: null,
  selectedProjectId: null,
  apis: [],
  workflows: [],
  modalOpen: false,
  apiCreateError: null,
  apiEndpoints: [],
  workflow_steptypes: [],
  workflowSteps: [],
  api: null,
  apiAuthMethods: [],
  workflow: null,
  workflowRuns: [],
  isWorkflowRunLoading: false,
  workflowRun: null,
  isTriggerLoading: false,
  isSelected: false,
  selectedNode: null,
};

const projectSlice = createSlice({
  initialState,
  name: "project",
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsSelected(state, action) {
      state.isSelected = action.payload;
    },
    setSelectedNode(state, action) {
      state.selectedNode = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setProjects(state, action) {
      state.projects = action.payload;
    },
    appendProject(state, action) {
      state.projects?.push(action.payload);
    },
    setProject(state, action) {
      state.project = action.payload;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    setSelectedProjectId(state, action) {
      state.selectedProjectId = action.payload;
    },
    saveAPI(state, action) {
      state.apis = [...action.payload];
    },
    setWorkflows(state, action) {
      state.workflows = action.payload;
    },
    appendWorkflow(state, action) {
      state.workflows = [...state.workflows, action.payload];
    },
    showModal(state, action) {
      state.modalOpen = action.payload;
    },
    setAPICreateError(state, action) {
      state.apiCreateError = action.payload;
    },
    saveAPIEndpoints(state, action) {
      state.apiEndpoints = [...action.payload];
    },
    setWorkflowStepTypes(state, action) {
      state.workflow_steptypes = action.payload;
    },
    setWorkflowSteps(state, action) {
      state.workflowSteps = action.payload;
    },
    updateWorkflows: (state, action) => {
      if (state.workflow) {
        state.workflow.workflowstep = action.payload;
      }
    },
    sortWorkflowSteps(state) {
      state.workflowSteps.sort((a, b) => a.pwst_order - b.pwst_order);
    },
    appendWorkflowStep(state, action) {
      state.workflowSteps = [...state.workflowSteps, action.payload];
      const workflowIndex = state.workflows.findIndex(
        (item) => item.pw_id === action.payload.pwst_workflow_id,
      );

      state.workflows[workflowIndex].workflowstep = [...state.workflowSteps];
    },
    updateWorkflowStep(state, action) {
      const workflowStepIndex = state.workflowSteps.findIndex(
        (item) => item.pwst_id === action.payload.pwst_id,
      );
      state.workflowSteps[workflowStepIndex] = action.payload;
      const workflowIndex = state.workflows.findIndex(
        (item) => item.pw_id === action.payload.pwst_workflow_id,
      );
      state.workflows[workflowIndex].workflowstep = [...state.workflowSteps];
    },
    updateWorkflowStepOrder(state, action) {
      const { stepId, newOrder } = action.payload;

      // Update the order of the specified workflow step
      const stepToUpdate = state.workflowSteps.find(
        (step) => step.pwst_id === stepId,
      );

      if (stepToUpdate) {
        stepToUpdate.pwst_order = newOrder;
      }
    },
    setAPI(state, action) {
      state.api = action.payload;
    },
    setAPIAuthMethods(state, action) {
      state.apiAuthMethods = action.payload;
    },
    setIsAPILoading(state, action) {
      state.isAPILoading = action.payload;
    },
    setWorkflow(state, action) {
      state.workflow = action.payload;
    },
    setWorkflowRuns(state, action) {
      state.workflowRuns = action.payload;
    },
    appendWorkflowRun(state, action) {
      state.workflowRuns = [action.payload, ...state.workflowRuns];
    },
    setIsWorkflowRunLoading(state, action) {
      state.isWorkflowRunLoading = action.payload;
    },
    setWorkflowRun(state, action) {
      state.workflowRun = action.payload;
    },
    filterWorkflowStep(state, action) {
      state.workflowSteps = state.workflowSteps?.filter(
        (step: { pwst_id: any }) => step.pwst_id != action.payload,
      );
    },
    setIsTriggerLoading(state, action) {
      state.isTriggerLoading = action.payload;
    },
    updateTrigger(state, action) {
      if (state.workflow) state.workflow.workflowTrigger = action.payload;
    },
  },
});

export const {
  setError,
  setIsLoading,
  setIsAPILoading,
  setProjects,
  appendProject,
  setProject,
  setSelectedProject,
  setSelectedProjectId,
  saveAPI,
  setWorkflows,
  appendWorkflow,
  showModal,
  setAPICreateError,
  saveAPIEndpoints,
  setWorkflowStepTypes,
  setWorkflowSteps,
  appendWorkflowStep,
  updateWorkflowStep,
  setAPI,
  setAPIAuthMethods,
  setWorkflow,
  setWorkflowRuns,
  appendWorkflowRun,
  setIsWorkflowRunLoading,
  setWorkflowRun,
  filterWorkflowStep,
  setIsTriggerLoading,
  updateTrigger,
  updateWorkflowStepOrder,
  sortWorkflowSteps,
  updateWorkflows,
  setIsSelected,
  setSelectedNode,
} = projectSlice.actions;

export const fetchAllOrgProjects =
  (orgId: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await projectApi.fetchAllOrgProjects(orgId);

      const { projects } = response.data;

      if (projects?.length) {
        dispatch(setProjects(projects));
      } else {
        dispatch(setProjects([]));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const createProject =
  (
    data: { projectName: string },
    callback: (id: string) => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { activeOrganisationId } = getState().organisation;

    dispatch(setIsLoading(true));

    try {
      const response = await projectApi.createProject(
        data,
        Number(activeOrganisationId),
      );

      const { createdProject } = response.data;

      if (createdProject) {
        dispatch(appendProject(createdProject));
        dispatch(setSelectedProjectId(createdProject?.p_id));
      }

      // dispatch(setActiveOrganisationId(orgId));

      if (callback) {
        callback(createdProject?.p_id);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const fetchProject =
  (
    callback: (project: Project) => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    const { activeOrganisationId } = getState().organisation;
    if (!selectedProjectId || !activeOrganisationId) {
      return false;
    }
    dispatch(setIsLoading(true));
    try {
      const response = await projectApi.fetchProject(
        Number(selectedProjectId),
        Number(activeOrganisationId),
      );

      const { project } = response.data;

      if (project) {
        dispatch(setProject(project));
      }
      if (callback) {
        callback(project);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const createNewWorkFlow =
  (
    data: { workflowName: string },
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    const { activeOrganisationId } = getState().organisation;
    if (!selectedProjectId || !activeOrganisationId) {
      return false;
    }
    dispatch(setIsLoading(true));

    try {
      const response = await projectApi.createNewWorkFlow(
        data,
        Number(selectedProjectId),
        Number(activeOrganisationId),
      );

      const { newWorkFlow, status } = response.data;

      if (status) {
        dispatch(appendWorkflow(newWorkFlow));
      }

      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const fetchAllProjectWorkFlows =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    const { activeOrganisationId } = getState().organisation;

    if (!selectedProjectId || !activeOrganisationId) {
      return false;
    }

    dispatch(setIsLoading(true));
    try {
      const response = await projectApi.fetchAllProjectWorkFlows(
        Number(activeOrganisationId),
        Number(selectedProjectId),
      );

      const { projectWorkflows, status } = response.data;

      if (status) {
        dispatch(setWorkflows(projectWorkflows));
      } else {
        dispatch(setWorkflows([]));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const onCreateAPI =
  (
    param: any,
    callback: () => any,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    if (!selectedProjectId) {
      return false;
    }
    dispatch(setAPICreateError(null));
    dispatch(setIsLoading(true));

    projectApi
      .createAPI(Number(selectedProjectId), param)
      .then((response) => {
        dispatch(setIsLoading(false));
        if (response.status === 200) {
          callback();
          dispatch(fetchAPIList(selectedProjectId));
        }
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        const response = error.response;
        if (response.status == 400) {
          dispatch(setAPICreateError(response.data.error));
        }
      });
  };

export const fetchAPIList =
  (id: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    // const { selectedProjectId } = getState().projects;
    if (!id) {
      return false;
    }
    dispatch(setIsAPILoading(true));

    projectApi
      .fetchAPI(id)
      .then((response) => {
        dispatch(setIsAPILoading(false));
        const data = response.data;
        if (data.status) {
          dispatch(saveAPI(data.apis));
        }
      })
      .catch((error) => {
        dispatch(setIsAPILoading(false));
        console.log(error);
      });
  };

export const importAPI =
  (
    type: string,
    file: any,
    callback: () => any,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    if (!selectedProjectId) {
      return false;
    }
    dispatch(setIsLoading(true));
    const param = { file_url: file };
    const formData = new FormData();
    if (type === "file") {
      console.log(file[0]);
      formData.append("file", file[0]);
    }
    projectApi
      .importAPI(Number(selectedProjectId), type === "url" ? param : formData)
      .then((response) => {
        dispatch(setIsLoading(false));
        console.log(response);
        dispatch(fetchAPIList(Number(selectedProjectId)));
        callback();
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        const response = error.response;
        console.log(response);
        if (response.status == 400) {
          dispatch(setAPICreateError(response.data.error));
        }
      });
  };

export const listAPIEndpoints =
  (apiId: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    if (!selectedProjectId) {
      return false;
    }
    dispatch(setIsLoading(true));

    projectApi
      .listEndpoints(String(selectedProjectId), apiId)
      .then((response) => {
        dispatch(setIsLoading(false));
        const data = response.data;
        // console.log(data);
        if (data.status) {
          dispatch(saveAPIEndpoints(data.endpoints));
        }
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        console.log(error);
      });
  };

export const fetchWorkflowStepTypes =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    if (!selectedProjectId) {
      return false;
    }
    dispatch(setIsLoading(true));

    projectApi
      .fetchWorkflowStepTypes(Number(selectedProjectId))
      .then((response) => {
        dispatch(setIsLoading(false));
        const data = response.data;
        if (data.status) {
          if (data.WorkflowStepTypes?.length) {
            dispatch(setWorkflowStepTypes(data.WorkflowStepTypes));
          }
        }
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
      });
  };

export const saveWorkflowAction =
  (
    data:
      | {
          httpMethod: { value: number; label: string } | null;
          httpUrl: string;
          authUserName: string;
          authPassword: string;
          requestBody: string;
          requestHeaders: { header: string; value: string }[];
          workflow_step_id: number;
        }
      | { title: string; message: string; workflow_step_id: number }
      | { url: string; workflow_step_id: number },
    workflowId: number | undefined,
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    const { activeOrganisationId } = getState().organisation;

    try {
      const response = await projectApi.saveWorkflowAction(
        data,
        Number(selectedProjectId),
        Number(activeOrganisationId),
        workflowId,
      );

      const {
        data: { updatedWorkflowStep },
        status,
      } = response;
      if (status) {
        dispatch(updateWorkflowStep(updatedWorkflowStep));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      if (callback) {
        callback();
      }
    }
  };
export const updateWorkflowSteps = (
  updatedWorkflowSteps: WorkflowSteps[],
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    dispatch(setWorkflowSteps(updatedWorkflowSteps));

    // Dispatch the action to update workflow steps separately
    dispatch(updateWorkflows(updatedWorkflowSteps));
  };
};

export const createWorkflowStep = (
  data: {
    order: number;
    step: number;
  },
  workflowId: number | undefined,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    const { activeOrganisationId } = getState().organisation;
    // Check if the order already exists in workflow steps
    const { workflowSteps } = getState().projects;
    const { order } = data;
    const orderExists = workflowSteps.some((step) => step.pwst_order === order);
    if (orderExists) {
      // Adjust the pwst_order for all subsequent steps
      const updatedWorkflowSteps = workflowSteps.map((step) => {
        if (step.pwst_order >= order) {
          return { ...step, pwst_order: step.pwst_order + 1 };
        }
        return step;
      });

      // Dispatch the action to update the workflow step order
      dispatch(setWorkflowSteps(updatedWorkflowSteps));
    }

    try {
      const response = await projectApi.createWorkflowStep(
        data, // Pass the order as it is to the API
        Number(selectedProjectId),
        Number(activeOrganisationId),
        workflowId,
      );

      const {
        data: { newWorkFlowStep },
        status,
      } = response;

      if (status) {
        const appendWorkflowStepPromise = new Promise<void>((resolve) => {
          // Dispatch the action to add the new workflow step
          dispatch(appendWorkflowStep(newWorkFlowStep));
          resolve();
        });

        await appendWorkflowStepPromise;
        // Sort the workflow steps
        dispatch(sortWorkflowSteps());
        // Fetch the latest state after sorting
        setTimeout(async () => {
          const updatedState = getState().projects.workflowSteps;
          try {
            await projectApi.updateWorkflowSteps(
              updatedState,
              Number(selectedProjectId),
              Number(activeOrganisationId),
              workflowId,
            );
          } catch (error: unknown) {
            if (error instanceof AxiosError) {
              dispatch(
                setError(
                  error?.response?.data?.message ||
                    "Something went wrong with updating steps",
                ),
              );
            }
          }
          dispatch(updateWorkflowSteps(updatedState));
        }, 0);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(
            error?.response?.data?.message ||
              "Something went wrong with adding a new step",
          ),
        );
      }
    }
  };
};
export const viewAPI =
  (apiId: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    if (!selectedProjectId) {
      return false;
    }
    dispatch(setIsLoading(true));

    projectApi
      .viewAPI(Number(selectedProjectId), Number(apiId))
      .then((response) => {
        dispatch(setIsLoading(false));
        const data = response.data;
        if (data.status) {
          dispatch(setAPI(data.api));
        }
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        // dispatch(setError(error));
        console.log(error);
      });
  };

export const fetchAPIAuthMethods =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    if (!selectedProjectId) {
      return false;
    }
    dispatch(setIsLoading(true));
    projectApi
      .fetchAPIAuthMethods(Number(selectedProjectId))
      .then((response) => {
        dispatch(setIsLoading(false));
        const data = response.data;
        if (data.status) {
          if (data.apiAuthMethods.length) {
            dispatch(setAPIAuthMethods(data.apiAuthMethods));
          }
        }
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        // dispatch(setError(error));
        console.log(error);
      });
  };

export const configureAPI =
  (
    data: any,
    apiId: number | undefined,
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    if (!selectedProjectId) {
      return false;
    }
    dispatch(setAPICreateError(null));
    dispatch(setIsAPILoading(true));

    projectApi
      .configureAPI(Number(selectedProjectId), apiId, data)
      .then((response) => {
        if (response.status === 200) {
          const { api } = response.data;
          dispatch(setAPI(api));
          dispatch(setIsAPILoading(false));
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        dispatch(setIsAPILoading(false));
        const response = error.response;
        if (response.status == 400) {
          dispatch(setAPICreateError(response.data.error));
        }
      });
  };

export const fetchWorkflow =
  (
    workflowId: string | number | undefined,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;

    if (!selectedProjectId) {
      return false;
    }
    dispatch(setIsLoading(true));

    projectApi
      .fetchWorkflow(Number(selectedProjectId), Number(workflowId))
      .then((response) => {
        dispatch(setIsLoading(false));
        const data = response.data;
        if (data.status) {
          const sortedWorkflowSteps = data?.workflow?.workflowstep.sort(
            (a: WorkflowSteps, b: WorkflowSteps) => a.pwst_order - b.pwst_order,
          );
          dispatch(setWorkflow(data?.workflow));
          dispatch(setWorkflowSteps(sortedWorkflowSteps));
        }
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        // dispatch(setError(error));
        console.log(error);
      });
  };

export const createWorkflowRun =
  (
    data: {
      notes: string;
    },
    workflowId: number,
    callback: (run: WorkflowRuns) => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    dispatch(setIsWorkflowRunLoading(true));
    try {
      const response = await projectApi.createWorkflowRun(
        data,
        Number(selectedProjectId),
        workflowId,
      );

      const {
        data: { workflowRun },
        status,
      } = response;
      if (status) {
        dispatch(appendWorkflowRun(workflowRun));
        if (callback) {
          callback(workflowRun);
        }
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsWorkflowRunLoading(false));
    }
  };

export const fetchAllWorkflowRuns =
  (workflowId: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    if (!selectedProjectId) {
      return false;
    }
    dispatch(setIsWorkflowRunLoading(true));
    try {
      const response = await projectApi.fetchAllWorkflowRuns(
        Number(selectedProjectId),
        workflowId,
      );

      const { workflowRuns, status } = response.data;
      if (status) {
        if (workflowRuns) {
          dispatch(setWorkflowRuns(workflowRuns));
        }
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsWorkflowRunLoading(false));
    }
  };

export const fetchWorkflowRun =
  (
    workflowId: number,
    workflowRunId: number,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    if (!selectedProjectId) {
      return false;
    }
    dispatch(setIsWorkflowRunLoading(true));
    try {
      const response = await projectApi.fetchWorkflowRun(
        Number(selectedProjectId),
        workflowId,
        workflowRunId,
      );

      const { workflowRun, status } = response.data;
      if (status) {
        if (workflowRun) {
          dispatch(setWorkflowRun(workflowRun));
        }
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsWorkflowRunLoading(false));
    }
  };

export const deleteWorkflowStep =
  (
    workflowId: number | undefined,
    workdlowStepId: number,
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    dispatch(setIsLoading(true));
    try {
      const response = await projectApi.deleteWorkflowStep(
        Number(selectedProjectId),
        workflowId,
        workdlowStepId,
      );
      const { pwst_id, status } = response.data;
      if (status) {
        if (pwst_id) {
          dispatch(filterWorkflowStep(pwst_id));
        }
      }
      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const createWorkflowTrigger =
  (
    data: TriggerData,
    workflowId: number,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { selectedProjectId } = getState().projects;
    dispatch(setIsTriggerLoading(true));
    try {
      console.log("data", data);
      const response = await projectApi.createWorkflowTrigger(
        data,
        Number(selectedProjectId),
        workflowId,
      );
      console.log("response", response);
      const {
        data: { newTrigger },
        status,
      } = response;
      if (status) {
        dispatch(updateTrigger(newTrigger));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsTriggerLoading(false));
    }
  };

export const projectSelector = (state: { projects: projectState }) =>
  state.projects;

export const projectReducer = projectSlice.reducer;
