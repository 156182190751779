import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useState, forwardRef, InputHTMLAttributes } from "react";

import EyeCloseIcon from "../../../assets/icons/eye-off.svg";
import EyeOpenIcon from "../../../assets/icons/eye-on.svg";
import { InputElementContainer, TextInputContainer } from "./elements";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  autoFocus?: boolean;
  className?: string;
  errorMessage?: string | undefined | null;
  flexBasis?: string | undefined | null;
  hasError?: boolean;
  isLarge?: boolean;
  label?: string;
  id?: string;
  isDisabled?: boolean;
  maxWidth?: string | undefined | null;
  minWidth?: string | undefined | null;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  width?: string;
  sublabel?: string;
}
export type InputRef = HTMLInputElement;

export const FormInput = forwardRef<InputRef, InputProps>(
  (
    {
      autoFocus,
      className,
      errorMessage,
      flexBasis,
      hasError,
      isLarge,
      label,
      id,
      isDisabled,
      maxWidth,
      minWidth,
      placeholder,
      type,
      width,
      autoComplete,
      sublabel,
      ...rest
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    return (
      <TextInputContainer
        className={classNames(
          "d-flex",
          "flex-column",
          "position-relative",
          "w-100",
          className,
        )}
        flexBasis={flexBasis}
        maxWidth={maxWidth}
        minWidth={minWidth}
        width={width}
      >
        {label ? (
          <label
            className={classNames("label mb-2", {
              isLarge: sublabel?.length,
            })}
            htmlFor={label}
          >
            {label}
          </label>
        ) : null}
        {sublabel ? (
          <label className="sub-label" htmlFor={label}>
            {sublabel}
          </label>
        ) : null}
        <InputElementContainer
          className={classNames(
            "d-flex",
            "align-items-center",
            "position-relative",
            "input-container",
            {
              "has-error": hasError,
              "is-focused": isFocused,
            },
          )}
        >
          <input
            {...rest}
            autoFocus={autoFocus}
            className={classNames("text-input", "w-100", {
              "is-large": isLarge,
              "pr-5": type === "password",
            })}
            id={id}
            onFocus={() => {
              setIsFocused(true);
            }}
            disabled={isDisabled}
            placeholder={placeholder}
            ref={ref}
            type={isVisible ? "text" : type}
            onBlur={(e) => {
              rest?.onBlur?.(e);
              setIsFocused(false);
            }}
            autoComplete={autoComplete}
          />
          {type === "password" ? (
            <button
              className="align-items-center d-flex justify-content-center position-absolute toggle-visibility-button"
              onClick={() => {
                setIsVisible((previousValue) => !previousValue);
              }}
              type="button"
            >
              <img
                alt="Visibility"
                className="visibility-icon"
                src={isVisible ? EyeOpenIcon : EyeCloseIcon}
              />
            </button>
          ) : null}
          {/* {StatusIcon} */}
        </InputElementContainer>
        <AnimatePresence>
          {errorMessage ? (
            <motion.span
              animate={{
                opacity: 1,
                translateY: 0,
              }}
              className="error-message position-absolute"
              exit={{
                opacity: 0,
                translateY: "-0.25rem",
              }}
              initial={{
                opacity: 0,
                translateY: "-0.25rem",
              }}
              transition={{
                duration: 0.2,
                type: "keyframes",
              }}
            >
              {errorMessage}
            </motion.span>
          ) : null}
        </AnimatePresence>
      </TextInputContainer>
    );
  },
);

FormInput.displayName = "FormInput";
