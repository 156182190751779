import { useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastMessage } from "../../../../components/common/ToastMessage";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  accountVerification,
  authSelector,
  setError,
} from "../../../../redux/slices/authSlice";

import { VerificationContainer } from "./elements";
import LoaderIcon from "../../../../assets/icons/loader.png";
import { AuthNavbar } from "../../components";

export const Verification = () => {
  const { isLoading, error } = useAppSelector(authSelector);
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      accountVerification(params.get("token"), () => {
        navigate("/dashboard");
      }),
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Verification | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <AuthNavbar />
      <VerificationContainer>
        {isLoading ? (
          <div className="loader">
            <img src={LoaderIcon} alt="" className="spinner" />
            <span className="verify">Verifying</span>
          </div>
        ) : null}
      </VerificationContainer>
      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
