import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./authSlice";
import { projectReducer } from "./projectSlice";
import { organisationReducer } from "./organisationSlice";
import { usersReducer } from "./usersSlice";
import { billingReducer } from "./billingSlice";

export const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  organisation: organisationReducer,
  projects: projectReducer,
  billing: billingReducer,
});

export default rootReducer;
