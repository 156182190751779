import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const ConfigureModalContainer = styled(Modal)`
  .configure-modal-body {
    padding: 2.5rem;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 1.5rem;
    color: #000000;
    button {
      outline: none;
      border: none;
      background: transparent;
      padding: unset;
    }
  }
  .first-row {
    padding-top: 2.5rem;
  }
  .add-button-container {
    padding-top: 1.5rem;
  }
  .configure-api-btn {
    margin-left: 1rem;
  }
  .no-button {
    margin-left: 1.25rem;
  }
  form {
  }
  .input-fields,
  .col {
    padding-top: 1.25rem;
  }
  .authentication-title {
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
  }
  .authentication-field {
    padding-top: 1rem;
  }
`;
