import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { Button, FormInput } from "../../../../components/common";
import { Checkbox } from "../../../../components/common/Checkbox";
import { AuthNavbar, AuthSwitchLink, Container } from "../../components";
import { MainContainer, SignUpContainer } from "./elements";
import { signUpSchema } from "../../../../utils/validation";
import {
  signUp,
  setError,
  authSelector,
} from "../../../../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ToastMessage } from "../../../../components/common/ToastMessage";

export const SignUp = () => {
  const { isLoading, error } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signUpSchema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      accept: false,
    },
  });

  const onSubmit = (data: {
    name: string;
    password: string;
    email: string;
  }) => {
    dispatch(
      signUp(data, () => {
        navigate(`success?email=${encodeURIComponent(data?.email)}`);
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>SignUp | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <MainContainer>
        <div className="w-50">
          <AuthNavbar />
          <SignUpContainer>
            <div className="signup-container">
              <div className="title-container">
                <div className="title">
                  Welcome to {process.env.REACT_APP_NAME}
                </div>
                <div className="sub-title">
                  Enter your credentials to access your account
                </div>
              </div>
              <div className="form-container">
                <form
                  className="align-items-center d-flex flex-column w-100"
                  onSubmit={handleSubmit(onSubmit)}
                  method="POST"
                  noValidate
                >
                  <div className="input-field">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="user-full-name"
                          type="text"
                          width="100%"
                          placeholder="Enter your Name"
                          // autoComplete="disabled"
                          errorMessage={errors.name?.message}
                          hasError={!!errors.name}
                          label="Name"
                        />
                      )}
                      name="name"
                    />
                  </div>
                  <div className="input-field">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="user-email-id"
                          type="email"
                          width="100%"
                          placeholder="Enter your Email"
                          // autoComplete="disabled"
                          errorMessage={errors.email?.message}
                          hasError={!!errors.email}
                          label="Email"
                        />
                      )}
                      name="email"
                    />
                  </div>
                  <div className="input-field">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="user-password"
                          type="password"
                          width="100%"
                          placeholder="Enter your Password"
                          // autoComplete="disabled"
                          errorMessage={errors.password?.message}
                          hasError={!!errors.password}
                          label="Password"
                        />
                      )}
                      name="password"
                    />
                  </div>
                  <div className="input-field">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="user-password"
                          type="password"
                          width="100%"
                          placeholder="Enter your Confirm Password"
                          // autoComplete="disabled"
                          errorMessage={errors.confirmPassword?.message}
                          hasError={!!errors.confirmPassword}
                          label="Confirm Password"
                        />
                      )}
                      name="confirmPassword"
                    />
                  </div>
                  <div className="align-items-start d-flex w-100 agree-terms">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          hasError={!!errors.accept}
                          errorMessage={errors.accept?.message}
                          label="I agree to the"
                          subLabel="Terms & Privacy"
                        />
                      )}
                      name="accept"
                    />
                  </div>
                  <Button
                    label="Sign Up"
                    type="submit"
                    isLoading={isLoading}
                    className="w-100 sign-up-btn"
                  />
                </form>
              </div>
              <AuthSwitchLink
                linkHref="/auth/login"
                linkText="Log In"
                text="Already have an account?"
                className="justify-content-center"
              />
            </div>
          </SignUpContainer>
        </div>
        <div className="left-container">
          <Container />
        </div>
      </MainContainer>

      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
