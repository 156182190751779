import styled from "styled-components";

export const ActionsCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
  position: relative;
  &:hover {
    .button-del {
      visibility: visible;
    }
  }
  .left {
    display: flex;
    align-items: center;
  }

  .interval {
    position: absolute;
    top: 0;
    left: 110px;
    display: flex;
    align-items: center;
    color: #655aec;
    text-align: left;
    font-size: 10px;
    border: 1px solid #655aec;
    border-radius: 8px;
    padding: 1px 6px 1px 6px;
    margin: 5px 10px;
    background: rgba(101, 90, 236, 0.1);
    img {
      height: 12px;
      width: 12px;
      margin-right: 3px;
    }
  }
  .schedule-text {
    font-size: 12px;
    padding-top: 5px;
    color: #808080;
  }
  .icon-container {
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    border: 1px #d0d5dd solid;
    border-radius: 8px;
    background: white;
  }

  .delete-btn {
    outline: none;
    border: none;
    background: transparent;
    padding: unset;
    margin-right: 1rem;
    height: 18px;
    width: 18px;
  }
  .delete-btns {
    height: 18px;
    width: 18px;
    padding: 1px;
    &:hover {
      border: 1px solid #d0d5dd;
      border-radius: 3px;
      padding: 1px;
    }
  }
  .button-del {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
  }
`;
