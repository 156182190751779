import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  fetchAPIAuthMethods,
  listAPIEndpoints,
  projectSelector,
  viewAPI,
} from "../../../../../../redux/slices/projectSlice";
import { ConfigureModal } from "../components/Modal/Configure";
import { Header } from "../components/Header";
import { Table } from "../../../../../../components/common/Table";
import { APIContainer } from "./elements";
// import { Button } from "../../../../../../components/common";
// import PlusIcon from "../../../../../../assets/icons/add-white.svg";
import ViewIcon from "../../../../../../assets/icons/view-icon.svg";
import Loader from "../../../../../../components/common/Loader";

export const View = () => {
  const { apiId } = useParams();
  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const dispatch = useAppDispatch();
  const { apiEndpoints, isLoading, api } = useAppSelector(projectSelector);

  useEffect(() => {
    if (apiId) {
      dispatch(listAPIEndpoints(apiId));
      dispatch(viewAPI(apiId));
      dispatch(fetchAPIAuthMethods());
    }
  }, [apiId]);

  const apiColumns = useMemo(
    () => [
      {
        Header: "#",
        id: "index",
        accessor: (_row: any, i: number) => i + 1,
      },
      {
        Header: "Method",
        accessor: "piae_method",
      },
      {
        Header: "Endpoint",
        accessor: "piae_path",
      },
      {
        Header: "",
        Cell: ({ value }: any) => (
          <Link to={String(value)}>
            <img src={ViewIcon} alt="Edit" />
          </Link>
        ),
        accessor: "piae_id",
      },
    ],
    [],
  );
  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <APIContainer>
      <Header onConfig={() => setShowConfigureModal(true)} />
      <div className="endpoint-container">
        <div className="title-button">
          <div className="title">Endpoints</div>
          {/* <Button
            label="New Endpoints"
            // onClick={() => navigate("new")}
            icon={PlusIcon}
            iconPosition="left"
            buttonStyles={{
              borderRadius: "0.3125rem",
            }}
          /> */}
        </div>
        {apiEndpoints.length ? (
          <div className="table mt-3">
            <Table
              columns={apiColumns}
              data={apiEndpoints}
              itemName="API Endpoints"
              type={1}
            />
          </div>
        ) : (
          <div>No Endpoints Added</div>
        )}
      </div>
      <ConfigureModal
        show={showConfigureModal}
        onHide={() => {
          setShowConfigureModal(false);
        }}
        modalSize="lg"
        api={api}
      />
    </APIContainer>
  );
};
