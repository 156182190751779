import styled from "styled-components";

export const WorkflowsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 3.25rem 2.5rem 2rem 2.5rem;

  .status-conatiner {
    color: #ffffff;
    font-weight: 500;
    padding: 0.375rem 1rem;
    text-align: center;
    border-radius: 1rem;
    background: #655aec;
    width: fit-content;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-right: 0.75rem;
  }
  .close-button,
  .add-button {
    border: none;
    outline: none;
    background: transparent;
    display: flex;
    padding: 0;
  }
  .close-button {
    margin-left: 0.5rem;
  }

  .preview-button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
  }
  .edit-button {
    background-color: transparent;
    border: 0.0625rem solid rgb(101, 90, 236);
    padding: 0.157rem 1.053rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    font-weight: 500;
    color: rgb(101, 90, 236);
    font-size: 0.875rem;
    :hover {
      background-color: rgb(101, 90, 236);
      color: #ffffff;
      font-weight: 700;
    }
  }
  .add-workflow-row {
    background-color: #f2f5f7;
  }
  .add-workflow {
    max-width: 8rem;
  }
  .id-project-workflow {
    display: none;
  }
  .form-container {
    display: flex;
  }
  .new-workflow-container {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 2.375rem;
    background: #ffffff;
    border: 0.0625rem solid #655aec;

    &.focused {
      transition: border-color 0.1s, box-shadow 0.1s;
      box-shadow: 0rem 0rem 0rem 0.25rem rgba(101, 90, 236, 0.1),
        0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05);
    }
    &.is-error {
      border-color: red !important;
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem rgba(253, 70, 70, 0.1);
      box-shadow: 0 0.0625rem 0.125rem rgba(255, 0, 17, 0.05),
        0 0 0 0.25rem rgba(255, 0, 34, 0.1);
    }

    .new-workflow {
      .input-container {
        border: unset;
        border-radius: unset;
        height: 2.875rem;
        background: transparent;
        box-shadow: none;
      }
    }
  }
  .error {
    color: red;
    font-size: 0.75rem;
    margin-top: 0.4rem;
  }
  .search-input {
    max-height: 2.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--gray-300, #d0d5dd);
    box-shadow: 0px 0.0625rem 0.125rem 0 rgba(16, 24, 40, 0.05);

    input {
      border: none;
      background: transparent;
      color: black;
      font-size: 0.875rem;
      line-height: 1.125rem;
      padding: 0.75rem 0 0.75rem 1rem;
      height: 100%;

      :active,
      :focus {
        outline: none;
      }

      ::placeholder {
        color: #101828;

        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
      }
    }
    .search-img {
      padding-right: 1rem;
      display: flex;
    }
  }
  .divider {
    height: 0.0625rem;
    width: 100%;
    background: #eaecf0;
    margin: 1rem 0 1.5625rem 0;
  }
  .integration-div {
    background: #f2f4f7;
    width: fit-content;
    text-transform: uppercase;
    padding: 0.219rem 0.612rem;
    text-align: center;
    border-radius: 1.3rem;
    .integration-text {
      margin-left: 0.325rem;
      color: #344054;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
  table {
    tr:not(:last-child) td:first-child {
      width: 0.5%;
      padding-right: unset;
    }
    tr,
    td:nth-child(2) {
      color: #101828;
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: Capitalize;
      padding-left: 1rem;
    }
    th:nth-child(2) {
      padding-left: 1rem;
    }
    td {
      width: 20%;
    }
    th {
      text-transform: uppercase;
    }
    .schedule {
      color: #667085;
      font-size: 0.875rem;
      font-weight: 500;
    }
    .workflow-name {
      color: #101828;
      font-weight: 600;
      line-height: 1.25rem;
    }
  }

  .new-workflow-btn {
    border-radius: 0.25rem;
    border: 0.0625rem solid #655aec;
    background: rgba(101, 90, 236, 0.1);
    padding: 0.21rem 0.439rem 0.21rem 0.4rem;
    img {
      margin-right: 0.125rem !important;
    }
  }
`;

export const StatusContainer = styled.div`
  .status {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.s0 {
      background-color: #959698;
    }
    &.s2 {
      background-color: #f84550;
    }
    &.s3 {
      background-color: #10a14b;
    }
  }
`;
