import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ThunkAction } from "redux-thunk";

import * as usersApi from "../../api/users";
import { InvitedUser, User, UserTypes } from "../../utils/types";
import { RootState } from "../store";

interface usersState {
  error: null;
  isLoading: boolean;
  users: InvitedUser[];
  userTypes: UserTypes[];
  organisationName: null;
}
const initialState: usersState = {
  error: null,
  isLoading: false,
  users: [],
  userTypes: [],
  organisationName: null,
};

const usersSlice = createSlice({
  initialState,
  name: "users",
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUserTypes(state, action) {
      state.userTypes = action.payload;
    },
    setUser(state, action) {
      state.users = action.payload;
    },
    clearUser: (state, action) => {
      state.users = state.users.filter((data) => data.ou_id !== action.payload);
    },
    appendUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    setOrganisationName(state, action) {
      state.organisationName = action.payload;
    },
  },
});

export const {
  setError,
  setIsLoading,
  setUserTypes,
  setUser,
  clearUser,
  appendUser,
  setOrganisationName,
} = usersSlice.actions;

export const fetchAllOrgUser =
  (orgId: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await usersApi.fetchAllOrgUser(orgId);

      const { allOrgUser } = response.data;

      if (allOrgUser?.length) {
        dispatch(setUser(allOrgUser));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const fetchAllUserType =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await usersApi.fetchAllUserType();

      const { userTypes } = response.data;

      if (userTypes?.length) {
        dispatch(setUserTypes(userTypes));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

// invite users
export const inviteUser =
  (
    data: {
      userName: string;
      email: string;
      role: { label: string; value: number } | null;
    },
    orgId: number,
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      const response = await usersApi.inviteUser(data, orgId);

      const { invitedOrgUser } = response.data;

      if (invitedOrgUser) {
        dispatch(appendUser(invitedOrgUser));
      }

      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const verifyInvitation =
  (
    token: string,
    callback?: (user: User) => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await usersApi.verifyInvitation(token);

      const { user, organisation } = response.data;
      if (organisation) {
        dispatch(setOrganisationName(organisation?.or_name));
      }

      if (callback) {
        callback(user);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const invitedUserVerification =
  (
    data: { password: string; confirmPassword: string },
    token: string,
    callback?: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      await usersApi.invitedUserVerification(data, token);

      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const removeInvitedUser =
  (
    id: number,
    orgId: number,
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      await usersApi.removeInvitedUser(id, orgId);

      if (id) {
        dispatch(clearUser(id));
      }

      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const usersSelector = (state: { users: usersState }) => state.users;

export const usersReducer = usersSlice.reducer;
