import { Helmet } from "react-helmet-async";
import { AuthSwitchLink } from "../Auth/components";

export const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>

      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
          <div className="container">
            <a className="navbar-brand" href="#">
              {process.env.REACT_APP_NAME}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <AuthSwitchLink linkHref="/auth/login" linkText="Login" />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div
          style={{
            height: "100vh",
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Elevate Your Business with AI-Driven Innovation</h2>
          <h4>Optimize your processes and improve customer experience.</h4>
          <button type="button" className="btn btn-primary btn-lg mt-4">
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};
