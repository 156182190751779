/* eslint-disable react/no-children-prop */
import { Control, Controller, FieldErrors } from "react-hook-form";
import { CustomModal, FormInput } from "../../../../components/common";

import { CreateProjectContainer } from "./elements";
interface CreateProjectModalProps {
  show: boolean;
  onHide: () => void;
  onClick: () => void;
  isLoading: boolean;
  isDisabled?: boolean;
  control: Control<{ projectName: string }>;
  errors: FieldErrors<{ projectName?: string }>;
}

export const CreateProjectModal = ({
  show,
  onHide,
  onClick,
  isDisabled,
  isLoading,
  control,
  errors,
}: CreateProjectModalProps) => (
  <CustomModal
    show={show}
    onHide={onHide}
    onClick={onClick}
    isDisabled={isDisabled}
    label="Create"
    isLoading={isLoading}
    title="Create Project"
    dialogClassName="create-project-modal"
    children={
      <CreateProjectContainer
        className="d-flex flex-column w-100 form"
        method="POST"
        noValidate
      >
        <div className="input-field">
          <Controller
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                id="project-name-id"
                type="name"
                label="What’s the name of your Website ?"
                sublabel="This will be displayed on the prompt UIon your appication"
                width="100%"
                placeholder="Project Name"
                errorMessage={errors.projectName?.message}
                hasError={!!errors.projectName}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onClick();
                  }
                }}
              />
            )}
            name="projectName"
          />
        </div>
      </CreateProjectContainer>
    }
  />
);
