import styled from "styled-components";

export const SideNavBarContainer = styled.div`
  position: fixed;
  min-height: 100vh;
  max-width: 6rem;
  border-right: 0.0625rem solid #eaecf0;
  background: #fff;
  box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.06);
  padding-top: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .nav-container {
    width: 100%;
    margin-top: 0.5rem;
  }
  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: #605f5f;
  }
  .nav-item {
    margin-top: 1.25rem;
    padding: 0.375rem 0.625rem 0.375rem 0.514rem;
    width: 100%;
    border-left: 0.1875rem solid transparent;
  }
  .active-link {
    border-left: 0.1875rem solid #655aec;
    background: #f8f7fb;
    a {
      color: #000;
    }
  }
  .img-container {
    display: flex;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
`;
