import styled from "styled-components";

export const PlanAndBillingContainer = styled.div`
  padding: 0.75rem 1.5rem 0 0.25rem;
  line-height: 1.25rem;
  .header {
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .billing-details-container {
    margin-top: 2.5rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #d0d5dd;
    background: #fff;
    box-shadow: 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05);
    .plan-details-container {
      padding: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .plan-details,
      .actions {
        display: flex;
        align-items: center;
      }
      .plan-name,
      .payment-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          color: #655aec;
          font-size: 1rem;
          font-weight: 500;
        }
        .value {
          color: #000;
          font-size: 1.5rem;
          font-weight: 600;
          display: flex;
          padding-top: 0.75rem;
          .period {
            color: #655aec;
            font-size: 1rem;
            font-weight: 500;
            margin-left: 0.5rem;
          }
        }
      }
      .upgrade-container {
        padding: 0 2.5rem 0 1.5rem;
        .btn-upgrade {
          border-radius: 0.5rem;
          border: 0.0625rem solid #000;
          background: transparent;
          outline: none;
          padding: unset;
          .text-img {
            display: flex;
            align-items: center;
            padding: 0.375rem 0.375rem 0.375rem 0.4375rem;
            justify-content: space-between;
          }

          .text {
            color: #000;
            font-size: 0.875rem;
            font-weight: 500;
          }
        }
      }
      .actions {
        .cancel-subscription {
          .btn-cancel {
            border: none;
            background: transparent;
            outline: none;
            padding: unset;
            .text {
              color: #655aec;
              font-size: 1rem;
              font-weight: 600;
            }
          }
        }
        .view-payment-history {
          margin-left: 1.5rem;
          button {
            padding: 0.625rem 1rem;
          }
        }
      }
    }
  }
  .divider {
    border-bottom: 0.0625rem solid #655aec;
    opacity: 0.2;
  }
  .workflow-used-container {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    .header {
      color: #655aec;
      font-size: 1rem;
      font-weight: 500;
    }

    .progess-details {
      display: flex;
      align-items: center;
      .icon-container {
        display: flex;
        align-items: center;
        img {
          vertical-align: unset;
        }
      }
      .workflows-used {
        color: #655aec;
        font-size: 0.875rem;
        font-weight: 500;
        padding-left: 0.625rem;
      }
    }
  }
  .progess-bar-container {
    margin: 1rem 0rem 0.75rem 0rem;
  }
  .progress {
    height: 0.5rem;
    background: #d9d9d9;
    border-radius: 1.875rem;
  }
  .progress-done {
    background: #655aec;
    border-radius: 1.875rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
  }
`;
