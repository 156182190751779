import styled from "styled-components";

export const RadioButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")} !important;

  font-weight: 500;
  font-size: 1rem;
  padding: unset;

  cursor: ${({ disabled }) =>
    disabled ? "not-allowed" : "pointer"} !important;
  :active,
  :focus,
  .default {
    outline: none;

    .radio-button-container {
      border-color: #655aec !important;
    }
    .radio-button-active-indicator {
      background-color: #655aec !important;
      border-radius: 50%;
      height: 100%;
      transition: background-color 0.1s;
    }
  }

  .radio-button-container {
    border: 0.0625rem solid #655aec;
    border-radius: 0.625rem;
    cursor: ${({ disabled }) =>
      disabled ? "not-allowed" : "pointer"} !important;
    height: 1rem;
    overflow: hidden;
    transition: border-color 0.1s, box-shadow 0.1s;
    width: 1rem;

    &.has-error {
      border-color: red;
    }

    .radio-button-active-indicator {
      background-color: transparent;
      border-radius: 50%;
      height: 100%;
      transition: background-color 0.1s;
    }
  }

  .label {
    color: #000000;
    text-align: left;
    cursor: ${({ disabled }) =>
      disabled ? "not-allowed" : "pointer"} !important;
    font-size: 0.875rem;
    margin-left: 0.875rem;
  }
`;
