import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
  authSelector,
  fetchProfile,
  setIsFetchingProfile,
} from "./redux/slices/authSlice";

export const PrivateRoute = ({ children, ...rest }: any) => {
  const jwtToken = localStorage.getItem("jwtToken");

  const { isFetchingProfile } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchUser = () => {
    dispatch(setIsFetchingProfile(true));
    setTimeout(() => {
      if (isFetchingProfile) {
        return false;
      }
      dispatch(
        fetchProfile(() => {
          dispatch(setIsFetchingProfile(false));
          navigate("/");
        }),
      );
    }, 1000);
  };

  React.useEffect(() => {
    if (jwtToken) {
      fetchUser();
    }
  }, [jwtToken, history, dispatch]);

  if (isFetchingProfile) {
    return (
      <div
        style={{
          height: "100vh",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "2.5rem", height: "2.5rem" }}
          />
        </div>
      </div>
    );
  }

  if (rest.path == "LoginRoute") {
    if (jwtToken) {
      return <Navigate to="/dashboard" replace />;
    }
    return children;
  }

  if (!jwtToken) {
    return <Navigate to="/" replace />;
  }
  return children;
};
