import LoaderIcon from "../../../assets/icons/loader.png";

export const Loader = () => {
  return (
    <>
      <div
        style={{
          height: "80vh",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader">
          <img src={LoaderIcon} alt="" className="spinner" />
        </div>
      </div>
    </>
  );
};

export default Loader;
