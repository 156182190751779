import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("jwtToken");
    const organisationId = localStorage.getItem("ai-org-id");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    if (organisationId) {
      config.headers["ai-org-id"] = organisationId;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default instance;
