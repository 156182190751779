import React, { forwardRef } from "react";
import classNames from "classnames";

import { RadioButtonContainer } from "./elements";

interface RadioButtonProps {
  className?: string;
  id?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  label?: string;
  value?: string;
  checked: boolean;
  onClick: () => void;
}

type RadioButtonRef = HTMLInputElement;

export const RadioButton = forwardRef<RadioButtonRef, RadioButtonProps>(
  (
    { className, hasError, label, value, id, isDisabled, onClick, checked },
    ref,
  ) => (
    <RadioButtonContainer
      className={className}
      type="button"
      id={id}
      disabled={isDisabled}
      onClick={onClick}
    >
      <label className="align-items-center d-flex mb-0" htmlFor={id}>
        <div
          className={classNames("radio-button-container", "p-1", {
            "has-error": hasError,
            default: checked,
          })}
        >
          <div className="radio-button-active-indicator w-100" />
        </div>
        <span className="label">{label}</span>
        <input
          className="d-none"
          id={id}
          ref={ref}
          type="radio"
          value={value}
          disabled={isDisabled}
          name={value}
          checked={checked}
        />
      </label>
    </RadioButtonContainer>
  ),
);

RadioButton.displayName = "RadioButton";
