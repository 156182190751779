import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import {
  Button,
  ToastMessage,
} from "../../../../../../../../components/common";
import { Table } from "../../../../../../../../components/common/Table";
import Loader from "../../../../../../../../components/common/Loader";
import { Status, Title } from "../../../../common";

import { StatusTag } from "../StatusTag";
import { AddWorkflowRun } from "../AddWorkflowRun";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/hooks";
import {
  fetchAllWorkflowRuns,
  projectSelector,
  setError,
} from "../../../../../../../../redux/slices/projectSlice";

import ViewIcon from "../../../../../../../../assets/icons/overview-active.svg";

export const WorkflowRun = () => {
  const { workflowId, orgId, id } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, workflowRuns, error } = useAppSelector(projectSelector);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (workflowId) {
      dispatch(fetchAllWorkflowRuns(Number(workflowId)));
    }
  }, [workflowId]);

  const workflowRunColumns = useMemo(
    () => [
      // {
      //   Header: "",
      //   Cell: ({ value }: any) => (

      //   ),
      //   accessor: "pwr_status",
      //   className: "status",
      // },
      {
        Header: "Triggered By",
        Cell: ({ row: { original } }: any) => {
          return (
            <>
              {original?.pwr_run_type == "MANUAL" ? (
                <div className="triggered-by">
                  <div className="d-flex justify-content-center align-items-center">
                    {Status(original?.pwr_status)}
                  </div>
                  <div className="run-type"> {original?.pwr_run_type} </div>
                  <div className="user-name">
                    ( {original?.user?.us_full_name} )
                  </div>
                </div>
              ) : (
                <div> {original?.pwr_run_type}</div>
              )}
            </>
          );
        },
        accessor: "pwr_run_by",
        className: "triggered-by",
      },
      {
        Header: "Status",
        Cell: ({ row: { original } }: any) => (
          <div className="status-conatiner">
            {StatusTag(original.pwr_status)}
          </div>
        ),
        accessor: "status",
        className: "status",
      },
      {
        Header: "Notes",
        accessor: "pwr_notes",
      },

      {
        Header: "Start Time",
        Cell: ({ value }: any) => (
          <div>{moment(value).format("D MMM YYYY h:mm:ss A")}</div>
        ),
        accessor: "pwr_started_at",
      },
      {
        Header: "End Time",
        Cell: ({ value }: any) => (
          <>
            {value ? (
              <div>{moment(value).format("D MMM YYYY h:mm:ss A")}</div>
            ) : (
              <div>-</div>
            )}
          </>
        ),
        accessor: "pwr_finished_at",
      },

      // {
      //   Header: "Error",
      //   accessor: "error",
      // },

      {
        Header: "",
        Cell: ({ row: { original } }: any) => {
          return (
            <button
              className="view-logs-button"
              onClick={() => {
                navigate(`${original?.pwr_id}`, {
                  state: {
                    pw_id: original?.pw_id,
                    pw_name: original?.pw_name,
                  },
                });
              }}
            >
              <img src={ViewIcon} alt="" />
              <div className="text">View Logs</div>
            </button>
          );
        },
        accessor: "pwr_id",
        className: "logs",
      },
    ],

    [],
  );
  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <div className="workflow-run-container">
        <div className="title-button">
          <Title
            titles={[
              {
                id: 1,
                title: "Workflows",
                navigation: () =>
                  navigate(`/org/${orgId}/project/${id}/workflows`),
              },
              { id: 2, title: "Workflow Runs" },
            ]}
          />
          <Button
            label="Run Manually"
            onClick={() => setShowModal(true)}
            buttonStyles={{
              borderRadius: "0.3125rem",
            }}
            className="run-manual-button"
          />
        </div>
        <div className="divider" />
        {workflowRuns?.length ? (
          <div className="workflow-run-table">
            <Table
              columns={workflowRunColumns}
              data={workflowRuns}
              itemName="Workflow Runs"
              type={1}
            />
          </div>
        ) : (
          <div className="mt-5 w-100">No Workflow Runs are Added</div>
        )}
      </div>

      <AddWorkflowRun
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        modalSize="lg"
      />
      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
