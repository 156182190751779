import styled from "styled-components";

export const UsersListContainer = styled.div`
  width: 100%;
  .user-list-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .user-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .count {
      color: #000;
      font-size: 1rem;
      font-weight: 700;
    }
  }
  .divider {
    border-bottom: 0.0625rem solid #eaecf0;
    margin: 1rem 0rem 1.5625rem 0rem;
    height: 0.0625rem;
    width: 100%;
  }

  .table-container {
    width: 100%;
    th {
      text-transform: uppercase;
    }
    tr td {
      color: #101828;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.25rem;
    }
    tr td:not(:nth-child(2)) {
      text-transform: unset !important;
    }
  }
  .remove-button {
    visibility: hidden;
    width: 3.625rem;
    float: right;

    button {
      background-color: #ffffff;
      padding: 0.3125rem;
      span {
        font-size: 0.75rem;
        line-height: normal;
      }
    }
    &.is-visible {
      visibility: visible;
    }
    &.is-owner {
      visibility: hidden;
    }
  }
  tr:hover {
  }
  .invite-user-btn {
    padding: 0.532rem 0.775rem;
  }
`;
