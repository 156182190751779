import { ReactElement } from "react";
import classNames from "classnames";
import Modal from "react-bootstrap/Modal";

import { Button } from "../Button";
import { ModalContainer } from "./elements";

import CloseIcon from "../../../assets/icons/close.svg";

interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  children?: ReactElement | null | undefined;
  modalSize?: string;
  onClick: () => void;
  isLoading: boolean;
  isDisabled?: boolean;
  label?: string;
  title: string;
  isDelete?: boolean;
  dialogClassName?: string;
  contentClassName?: string;
  icon?: string;
  subTitle?: string;
}

export const CustomModal = ({
  show,
  onHide,
  children,
  modalSize,
  onClick,
  isLoading,
  isDisabled,
  label,
  title,
  isDelete,
  dialogClassName,
  contentClassName,
  icon,
  subTitle,
}: CustomModalProps) => {
  return (
    <ModalContainer
      dialogClassName={dialogClassName}
      contentClassName={contentClassName}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      size={modalSize}
    >
      <Modal.Header
        closeButton={false}
        className={classNames("modal-header", { "is-delete": isDelete })}
      >
        <div className="d-flex">
          {icon ? (
            <div>
              <img src={icon} alt="" />
            </div>
          ) : null}
          <div
            className={classNames("title-container", { "is-delete": isDelete })}
          >
            <div id="contained-modal-title-vcenter" className="title">
              {title}
            </div>
            {subTitle ? <div className="sub-title">{subTitle}</div> : null}
          </div>
        </div>
        <button type="button" className="close-btn" onClick={onHide}>
          <img src={CloseIcon} alt="x" />
        </button>
      </Modal.Header>
      {!isDelete ? <Modal.Body>{children}</Modal.Body> : null}
      <Modal.Footer
        className={classNames("modal-footer", { "is-delete": isDelete })}
      >
        <Button
          label="Close"
          onClick={onHide}
          isFullWidth={false}
          isLarge={false}
          isCancel
        />
        <Button
          label={label}
          type="submit"
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={onClick}
          isFullWidth={false}
          isLarge={false}
        />
      </Modal.Footer>
    </ModalContainer>
  );
};
