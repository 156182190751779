import axios from "../instance";

export const fetchAllOrgUser = (orgId: number) =>
  axios.get(`/users/${orgId}/all-user`);

export const fetchAllUserType = () => axios.get("/users/user-type");

export const inviteUser = (
  data: {
    userName: string;
    email: string;
    role: { label: string; value: number } | null;
  },
  orgId: number,
) => axios.post(`/users/${orgId}/invite-user`, data);

export const verifyInvitation = (token: string) =>
  axios.get(`/users/verify-invitation?token=${token}`);

export const invitedUserVerification = (
  data: { password: string; confirmPassword: string },
  token: string,
) => axios.post(`/users/accept-invitation?token=${token}`, data);

export const removeInvitedUser = (orgUserId: number, orgId: number) =>
  axios.post(`/users/${orgId}/remove-invited-user/${orgUserId}`);
