import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const APIImportContainer = styled(Modal)`
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-weight: 500;
      font-size: 1.5rem;
      color: #000000;
      button {
        outline: none;
        border: none;
        background: transparent;
      }
    }
    .sub-title {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
  .modal-header {
    width: 100%;
    padding: 2.5rem;
    border-bottom: unset !important;
  }
  .import-api-btn {
    margin-left: 0.625rem;
  }
  .modal-body {
    padding: 0 2.5rem 2.5rem 2.5rem;
  }
  .input-field {
    width: 100%;
  }
  .file-upload-container {
    background: rgba(101, 90, 236, 0.05);
    border: 0.0625rem dashed #655aec;
    border-radius: 0.75rem;
    width: 100%;
    min-height: 13.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1.5rem;
  }
  .drop-file {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 0.875rem;
    color: #605f5f;
  }
  .choose-file {
    padding: unset !important;
    margin: 0 0.35rem;
    color: #655aec;
    border: none !important;
    :first-child:active {
      color: #655aec;
    }
  }
  .add-manully-button {
    outline: none;
    background: transparent;
    border: none;
    color: #655aec !important;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1.5rem;
    border-bottom: 0.0625rem solid #655aec;
    color: #655aec;
  }
  .or-container {
    padding-top: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.75rem;
    color: #605f5f;
  }
  hr {
    width: 48%;
    color: #d0d5dd;
  }
  .import-button-container {
    margin-top: 1.5rem;
  }
  .upload-file {
    width: 100%;
  }
  .upload-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 13.375rem;
    padding: 1.5rem 1.5rem;
    background: rgba(101, 90, 236, 0.05);
    border: 0.0625rem dashed #655aec;
    border-radius: 0.75rem;
    margin-top: 1.5rem;

    &.logo-drag {
      background: #ffffff;
      border-color: #655aec;
    }
    .upload-text {
      padding: 0.75rem 0 0rem 0;
      text-align: center;
      position: relative;
      overflow: hidden;
      width: 100%;
      font-weight: 500;
      font-size: 0.875rem;
      color: #605f5f;
    }

    .upload-text input {
      position: absolute;
      color: black;
      top: -6rem;
      right: 0;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
      font-size: 18.75rem;
      height: 12.5rem;
      width: 100%;
    }
    span {
      color: #655aec;
    }
  }
  .drag-and-drop {
    margin: 0 0.4rem;
  }
  .uploaded-file-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 1.5rem 1.5rem;
    background: rgba(101, 90, 236, 0.05);
    border: 0.0625rem dashed #655aec;
    border-radius: 0.75rem;
    margin-top: 1.5rem;
    button {
      outline: none;
      border: none;
      background: transparent;
    }
  }
`;

export const APIAddManualContainer = styled(Modal)`
  .add-manually-container {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-weight: 500;
      font-size: 1.5rem;
      color: #000000;
      button {
        outline: none;
        border: none;
        background: transparent;
        padding: unset;
      }
    }
  }
  .input-field {
    padding-top: 1.25rem;
    .label {
      font-weight: 600;
      font-size: 0.875rem;
      color: #344054;
    }
    .sub-label {
      margin-top: unset;
      margin-bottom: 0.5rem;
    }
  }
  .api-add-manually-body {
    padding: 2.5rem;
    .form {
      padding-top: 1.25rem;
    }
    .add-button-container {
      padding-top: 1.25rem;
    }
    .add-api-btn {
      margin-left: 1rem;
    }
  }
`;
