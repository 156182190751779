import styled from "styled-components";

export const DashboardContainer = styled.div`
  display: flex;
  min-height: 100vh;
  padding-top: 5rem;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 1.5rem;
    color: #000000;
    line-height: 110%;
    letter-spacing: -0.045rem;
    button {
      line-height: 1.25rem;
      height: 2.25rem;
    }
  }
  .dashboard-container {
    padding: 3.25rem 2.5rem 2.5rem 2.5rem;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .row {
    padding-top: 2rem;
  }
  .title {
    font-weight: 500;
    font-size: 1.25rem;
    color: #000000;
    padding-bottom: 1rem;

    line-height: 110%; /* 22px */
    letter-spacing: -0.0375rem;
  }
  .project {
    width: 100%;
    height: 13.5rem;
    background: #ffffff;
    outline: none;
    background: #ffffff;
    border: 0.0625rem solid #eaecf0;
    box-shadow: 0 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1),
      0 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.5rem;
    :hover,
    :active {
      border-color: #f1effc;
      box-shadow: 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.06),
        0rem 0.125rem 0.5rem 0rem rgba(101, 90, 236, 0.25);
    }
    .project-title {
      font-weight: 500;
      font-size: 1rem;
      letter-spacing: -0.03em;
      margin-top: 1rem;
      color: #000000;
      line-height: 110%;
      letter-spacing: -0.03rem;
    }
    img {
      min-width: 3rem;
      min-height: 3rem;
    }
  }
  .divider {
    border-bottom: 0.0625rem solid #eaecf0;
    margin-top: 1rem;
  }
`;
