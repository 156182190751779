import styled from "styled-components";

export const SelectBoxContainer = styled.button`
  align-items: center;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  position: relative;
  transition: border-color 0.1s, box-shadow 0.1s;

  &.is-open {
    border-color: #6666ff !important;
    box-shadow: 0 0 0 2px #9c9cf8;
  }

  :focus,
  :active {
    outline: none;
  }

  h5 {
    color: #3d4457;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 36px 0 0;
  }

  .dropdown-items {
    background-color: #fff;
    border: 0.0625rem solid #dedede;
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    position: absolute;
    right: -0.0625rem;
    z-index: 1000;

    .dropdown-item {
      align-items: center;
      background-color: #fff;
      border: none;
      border-radius: 0.25rem;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      padding: 0.5rem;
      transition: background-color 0.1s;

      :not(:last-child) {
        margin-bottom: 0.5rem;
      }

      :hover {
        /* background-color: rgba(64, 161, 255, 0.1); */
        background-color: #f1f0ff;
      }

      :focus,
      :active {
        outline: none;
      }

      span {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.21;
      }

      img {
        height: 16px;
        width: 16px;
      }
    }
  }
`;
