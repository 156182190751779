import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import type {} from "redux-thunk/extend-redux";
import { RootState } from "./../store";
import * as billingApi from "../../api/billing";
import { AxiosError } from "axios";
import { SubscriptionPlan } from "../../utils/types";

export interface Product {
  id: string;
  object: string;
  active: boolean;
  name: string;
  metadata: MetaData;
  prices: Prices[];
}

interface MetaData {
  [key: string]: string;
}

interface Prices {
  currency: string;
  unit_amount: number;
  lookup_key: string;
}

interface billingState {
  products: Product[] | null;
  isLoading: boolean;
}
const initialState: billingState = {
  products: null,
  isLoading: false,
};

const billingSlice = createSlice({
  initialState,
  name: "billing",
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setProducts, setIsLoading } = billingSlice.actions;

export const getPrices =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await billingApi.listPrices();

      if (response.status === 200) {
        dispatch(setProducts(response.data.products));
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log(error?.response?.data?.message || "Something went wrong.");
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const subscribe =
  (
    lookup_key: string,
    callback: (redirect_url: string) => any,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async () => {
    try {
      const body = { lookup_key };
      const response = await billingApi.subscribePlane(body);
      console.log("response :", response);
      if (response.status === 200) {
        callback(response.data);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log(error?.response?.data?.message || "Something went wrong.");
      }
    }
  };

export const getSubscription =
  (
    callback: (subscription: SubscriptionPlan) => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await billingApi.planDetails();

      if (callback) {
        callback(response.data.subscriptions[0]);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log(error?.response?.data?.message || "Something went wrong.");
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const subscribedPlanAction =
  (
    callback: (redirect_url: string) => any,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async () => {
    try {
      const response = await billingApi.subscribedPlanAction();
      console.log("response :", response);
      if (response.status === 200) {
        callback(response.data?.url);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log(error?.response?.data?.message || "Something went wrong.");
      }
    }
  };
export const billingSelector = (state: { billing: billingState }) =>
  state.billing;

export const billingReducer = billingSlice.reducer;
