import styled from "styled-components";
import { Props } from "..";
export const SelectContainer = styled.div<Props>`
  .error-message {
    bottom: -0.2rem;
    color: red;
    font-size: 0.75rem;
    left: 0;
    line-height: 0.9075rem;
  }
  .is-truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .react-select {
    &.has-error__control {
      border-color: red !important;
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem rgba(253, 70, 70, 0.1);

      &.is-focused {
        box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
          0 0 0 0.25rem rgba(253, 70, 70, 0.1);
      }
    }
    &.has-error {
      &.is-configure__control {
        border-color: red !important;
        box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
          0 0 0 0.25rem rgba(253, 70, 70, 0.1);

        &.is-focused {
          box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
            0 0 0 0.25rem rgba(253, 70, 70, 0.1);
        }
      }
    }
    &.is_navbar {
      background: rgba(255, 255, 255, 0.1);
      border: 0.0625rem solid #8d85f3;
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
    }
    &.is-navbar__menu {
      border-radius: 0.25rem;
    }
    &.is-configure__menu {
      background: #ffffff;
      border: 0.0625rem solid #d0d5dd;
      box-shadow: 0 1.25rem 1.5rem -0.25rem rgba(16, 24, 40, 0.08),
        0 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.03);
      border-radius: 0.5rem;
    }
  }
  .react-select__control {
    border: 0.0625rem solid rgb(208, 213, 221);
    border-radius: 0.5rem;
    height: 2.625rem;
  }
  .react-select__control--is-focused {
    border-color: #686868 !important;
    box-shadow: 0 0 0 0.02rem #686868;
  }
  .react-select {
    &.is-navbar__option--is-focused {
      background-color: #f1f0ff;
    }
  }

  .label {
    align-items: center;
    color: #000000;
    display: flex;
    font-size: 0.875rem;
    margin: 0.5rem 0;

    img {
      height: 1rem;
      margin-left: 0.5rem;
      object-fit: contain;
      width: 1rem;
    }
  }

  .tick-icon {
    width: 1rem;
    height: 1rem;
  }
  .selected-value {
    color: #344054;

    font-weight: 400;
    font-size: 0.875rem;
  }

  .value {
    color: #000000;
  }
  .react-select__menu-list {
    overflow-y: unset !important;
    max-height: unset !important;
  }
  &.is-navbar {
    .selected-value,
    .label {
      color: #ffffff;
    }
  }
`;
