/* eslint-disable react/jsx-key */

import { ReactElement, useEffect } from "react";
import classNames from "classnames";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
  useSortBy,
} from "react-table";

import { useSticky } from "react-table-sticky";
import { Pagination } from "./components";
import { Button } from "../Button";

import {
  TableContainer,
  TableBody,
  TableHead,
  TableHeader,
  TableMainConatiner,
} from "./elements";

interface Props {
  isWithoutHeader?: boolean;
  isWithoutPagination?: boolean;
  itemName?: string;
  buttonLabel?: string | null;
  onButtonClick?: () => void;
  buttonIcon?: string;
  filterTabs?: [];
  showAllRows?: boolean;
  isButton?: boolean;
  columns: any;
  data: any;
  type?: number;
  buttonComponent?: ReactElement | undefined;
  // isDivider?: boolean;
}

export const Table = ({
  columns,
  data,
  isWithoutHeader,
  isWithoutPagination,
  itemName,
  buttonLabel,
  onButtonClick,
  buttonIcon,
  filterTabs = [],
  showAllRows,
  isButton,
  type,
  buttonComponent,
}: // isDivider,
Props) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
  );

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageOptions,
    preGlobalFilteredRows,
    prepareRow,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    const element = document.querySelector("body");
    element?.scrollIntoView({
      behavior: "smooth",
    });
  }, [pageIndex]);

  useEffect(() => {
    if (showAllRows && data && data.length) {
      setPageSize(data.length);
    }
  }, [data, setPageSize, showAllRows]);

  return (
    <>
      {isWithoutHeader ? null : (
        <>
          <TableHeader
            className={classNames({
              "type-1": type === 1,
              "type-2": type === 2,
            })}
          >
            <div className="item-container">
              {!filterTabs?.length && type === 2 ? (
                <div className="item-count mb-0">
                  {`${preGlobalFilteredRows.length} ${itemName}`}
                </div>
              ) : null}
            </div>

            {buttonLabel && onButtonClick ? (
              <div className="button-container">
                <Button
                  icon={buttonIcon || ""}
                  className="add-button"
                  label={buttonLabel}
                  isFullWidth={false}
                  onClick={onButtonClick}
                />
              </div>
            ) : null}
          </TableHeader>
        </>
      )}
      {/* {isDivider ? <div className="table-divider" /> : null} */}

      <TableMainConatiner
        className={classNames({
          "type-1": type === 1,
          "type-2": type === 2,
        })}
      >
        <TableContainer
          {...getTableProps()}
          className={classNames({
            "type-1": type === 1,
            "type-2": type === 2,
          })}
        >
          <TableHead
            className={classNames({
              "type-1": type === 1,
              "type-2": type === 2,
            })}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div className="align-items-center d-flex">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </TableHead>
          {type === 2 ? <div className="seperator"></div> : null}
          <TableBody
            {...getTableBodyProps()}
            className={classNames({
              "type-1": type === 1,
              "type-2": type === 2,
            })}
          >
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any, index: number) => (
                    <td
                      {...cell.getCellProps()}
                      data-label={headerGroups[0]?.headers[index]?.Header}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
            {isButton ? (
              <tr>
                <td colSpan={columns.length}>{buttonComponent}</td>
              </tr>
            ) : null}
          </TableBody>
        </TableContainer>
      </TableMainConatiner>
      {isWithoutPagination ? null : (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          previousPage={previousPage}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
};
