import styled from "styled-components";

export const MakeAnAPIRequestContainer = styled.form`
  .react-select {
    .react-select__menu-list {
      padding-top: unset !important;
      padding-bottom: unset !important;
    }
    .react-select__menu-notice {
      padding: unset !important;
    }
    .label {
      margin-bottom: unset;
    }
  }
  .react-select__dropdown-indicator {
    color: #323232 !important;
  }

  .http-details-container {
    padding: 10px 24px;
    .react-select__control {
      background-color: #ffffff;
    }
  }
  .request-headers {
    padding: 0 24px;
    .headers-values-div {
      border-radius: 0.5rem;
      border: 0.0625rem solid #d0d5dd;
      .rows:not(:first-child) {
        border-top: 0.0625rem solid #d0d5dd;
      }
      .rows {
        padding-left: 0.05rem;
      }
      .header-div {
        border-right: 0.0625rem solid #d0d5dd;
      }
    }
    .add-button-header {
      margin-bottom: 10px;
    }
    .headers-values {
      display: flex;
      width: 100%;
    }
    .cols {
      width: 50%;
    }
    .inputs {
      .input-container {
        border: unset !important;
        box-shadow: unset !important;
      }
    }
    .remove-col {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .close-button {
      background-color: transparent;
      border: none;
      outline: none;
      margin-right: 0.5rem;
    }
  }
  .action-divider {
    border-bottom: 0.0625rem solid #f1effc;
    margin-bottom: 1.5rem;
  }

  .tabs-button {
    outline: none;
    border: none;
    background: transparent;
    margin: unset;
    padding: unset;
    margin-bottom: 1.5rem;
    font-weight: 600;
    &.is-active {
      color: #655aec;
      font-size: 1rem;
      font-weight: 600;
      border-bottom: 0.125rem solid #655aec;
    }
  }
  .auth-password {
    padding-top: 1rem;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    padding: 10px 24px 24px 24px;
  }
  .is-sidebar {
    width: 100%;
  }

  .request-body {
    padding: 0 24px;
  }
`;
