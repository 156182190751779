import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { CreateProjectModal } from "../../../DashBoard/components";

import { createProjectsSchema } from "../../../../utils/validation";
import {
  createProject,
  projectSelector,
  setError,
} from "../../../../redux/slices/projectSlice";
import { useNavigate, useParams } from "react-router-dom";
import { ToastMessage } from "../../../../components/common";
import { setActiveOrganisationId } from "../../../../redux/slices/organisationSlice";

export const Add = () => {
  const navigate = useNavigate();
  const { isLoading, error } = useAppSelector(projectSelector);
  const dispatch = useAppDispatch();
  const [modalShow, setModalShow] = useState(true);
  const { orgId } = useParams();

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(createProjectsSchema),
    defaultValues: {
      projectName: "",
    },
  });
  const clearFields = () => {
    setValue("projectName", "");
  };
  const onSubmit = (data: { projectName: string }) => {
    dispatch(setActiveOrganisationId(Number(orgId)));
    dispatch(
      createProject(data, (p_id: string) => {
        setModalShow(false);
        clearFields();
        navigate(`/org/${orgId}/project/${p_id}`);
      }),
    );
  };
  return (
    <>
      <Helmet>
        <title>Dashboard | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <CreateProjectModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          clearFields();
          navigate(-1);
        }}
        onClick={handleSubmit(onSubmit)}
        isLoading={isLoading}
        control={control}
        errors={errors}
      />
      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
