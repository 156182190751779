import styled from "styled-components";

export const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;

  .verify-container {
    background-color: transparent;
    width: 33.625rem;
    height: 20rem;
  }
  .success-container {
    height: 100%;
    background-color: #ffffff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.75rem;
    box-shadow: 0 1.25rem 1.5rem -0.25rem rgba(16, 24, 40, 0.08),
      0 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.03);
  }
  .title-content {
    margin-top: 1.5rem;
    height: 4rem;
    line-height: 1.5rem !important;
  }
  .title {
    font-weight: 600;
    font-size: 1.875rem;
    color: #000000;
  }
  .contact-support {
    padding-top: 2rem;
  }
  .content {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1rem;
    color: #475467;
  }
  .icon-container {
    padding-top: 0.5rem;
  }

  .text {
    color: #000;
    line-height: 1.5rem !important;
  }

  .href-text {
    text-decoration: none !important;
    color: #655aec;
    line-height: 1.5rem !important;
  }
`;
