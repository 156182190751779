import styled from "styled-components";

export const NavbarContainer = styled.nav`
  /* padding: 1.11125rem 2.5rem; */
  padding: unset;
  z-index: 2;
  position: fixed;
  width: 100%;
  background-color: #655aec;
  /* align-items: center; */

  .logo-link {
    text-decoration: none;
    font-weight: 600;
    font-size: 1.125rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    height: 1.75rem;
  }
  .app-name {
    margin-left: 0.75rem;
  }
  .user-details {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 2.625rem;
  }

  .user-name {
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
    line-height: 1.5rem;
  }
  .user-email {
    font-weight: 400;
    font-size: 0.75rem;
    color: #475467;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .menu {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500;
    color: #000000;
    line-height: 1.25rem;
  }
  .icon-container {
    margin-right: 1rem;
    width: 1.125rem;
    height: 1.125rem;
    img {
      width: 100%;
      height: 100%;
      vertical-align: unset;
    }
  }

  .dropdown-item {
    padding: 0.5rem 1.25rem !important;
    :hover,
    :active {
      background-color: rgb(244, 246, 252);
      color: #000000;
    }
  }
  .nav-container {
    display: flex;
    min-width: 30%;
    /* align-items: center; */
  }
  .logo-container {
    padding: 1.625rem 0 1.625rem 2.5rem;
  }
  .select-container {
    display: flex;
    min-width: 14rem;
    margin-left: 1.625rem;
    align-items: center;
    ::placeholder {
      color: #000000;
    }
  }

  .org-container {
    padding-right: 2.5rem;
  }

  .button-container {
    padding: 0 0.5rem 0.5rem 0.5rem;
    button {
      padding: 0.5rem;
      .label {
        color: #ffffff;
        font-size: 1rem;
        font-weight: 600;
        font-family: Inter;
      }
    }
    .label {
      margin-bottom: unset;
    }
  }
  .user-organisation-container {
    padding-bottom: 0.75rem;
    .user-organisation {
      font-weight: bold;
      font-size: 1rem;
      font-weight: 600;
      color: #000000;
      line-height: 1.5rem;
    }

    input:checked {
      background-color: #655aec !important;
      border-color: #655aec;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
  .dropdown,
  .dropdown-toggle {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgb(229, 228, 228);
    padding: unset !important;
  }

  .dropdown-profile-img {
    vertical-align: unset;
    width: 60%;
    height: 60%;
  }
  .dropdown-menu {
    min-width: 18.125rem;
    background: #ffffff;
    box-shadow: 0px 1.25rem 1.5rem -0.25rem rgba(16, 24, 40, 0.08),
      0px 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.03);
    border-radius: 0.75rem;
    inset: 0.5625rem 0 auto auto !important;
    border: none !important;
    padding: unset !important;
  }

  .dropdown-item-text-user {
    padding: 1.25rem 1.25rem 0 1.25rem !important;
  }
  .item-text-list {
    padding: 0 1.25rem !important;
  }
  .navbar-href {
    margin: unset !important;
  }
  .dropdown-item-container {
    padding: 1rem 0;
  }
  .logout-link {
    /* margin-top: 1rem;E  */
  }
  .org-radio-btn {
    display: flex;
    align-items: center;
    :first-child {
      margin-top: 0.5rem;
    }
    padding: 0.625rem 1.25rem;
    width: 100%;
    :hover,
    :active {
      background-color: rgb(244, 246, 252);
      color: #000000;
    }
  }
  .org-radio {
    label {
      font-weight: 500;
      font-size: 0.875rem;
      color: #000000;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 11.5rem;
    }
  }
  .form-check {
    margin-bottom: unset;
    input {
      margin-top: unset !important;
    }
  }
  .organisation-list {
    /* padding: 0 1.25rem 1.25rem 1.25rem; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .org-col {
    padding: unset !important;
  }
  .org-name {
    margin-right: 0.625rem;
    font-weight: 500;
    color: #ffffff;
  }
  .project-list {
    .selected-value,
    .label {
      color: #ffffff;
    }
  }
  .is-navbar__menu-list {
    max-height: 13rem;
  }
  .is-navbar__control {
    height: 2.625rem;
  }
  .radio-button {
    display: flex;
    align-items: center;
    label {
      display: flex;
      align-items: center;
    }
    .label {
      margin-left: 1rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.125rem;
      font-weight: 500;
    }
  }
  .change-icon {
    padding-right: unset !important;
    img {
      float: right;
    }
  }
`;
