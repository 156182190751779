import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "../../../../../../components/common";
import { AddWorkFlowContainer } from "./elements";
import { GoToUrl, MakeAnAPIRequest, ShowAMessage, Trigger } from "./components";
import { Title } from "../../common";

import { WorkflowSteps } from "../../../../../../utils/types";

import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  createWorkflowStep,
  projectSelector,
} from "../../../../../../redux/slices/projectSlice";

import AddIcon from "../../../../../../assets/icons/add-action.svg";
import MakeAnAPIRequestIcon from "../../../../../../assets/icons/api-request-action.svg";

// Workflow Steps
// value 1 = Make an API Request
// value 2 = Show Message
// value 3 = Go to URL

export const AddWorkflowActions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { orgId, id } = useParams();

  const { workflow_steptypes, workflow, workflowSteps } =
    useAppSelector(projectSelector);

  const workflowId = workflow?.pw_id;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);

  const addActions = useCallback(
    (data: any) => {
      setIsDropdownOpen(false);
      setIsActionOpen(true);
      dispatch(
        createWorkflowStep(
          {
            order: workflowSteps?.length + 1,
            step: data.value,
          },
          workflow?.pw_id,
        ),
      );
    },
    [workflowSteps],
  );

  const renderActionComponent = (workflowStep: WorkflowSteps) => {
    if (workflowStep.pwst_step_type === 1) {
      return (
        <MakeAnAPIRequest
          workflowId={workflowId}
          workflowStep={workflowStep}
          isActionOpen={isActionOpen}
        />
      );
    }
    if (workflowStep.pwst_step_type === 2) {
      return (
        <ShowAMessage
          workflowId={workflowId}
          workflowStep={workflowStep}
          isActionOpen={isActionOpen}
        />
      );
    }
    if (workflowStep.pwst_step_type === 3) {
      return (
        <GoToUrl
          workflowId={workflowId}
          workflowStep={workflowStep}
          isActionOpen={isActionOpen}
        />
      );
    }
    // if (value === 4) {
    //   return <SendNotificationToSlack title={label} />;
    // }
    // if (value === 5) {
    //   return <ReceiveAFileUpload title={label} />;
    // }
  };

  const addActionOptions = useMemo(
    () =>
      workflow_steptypes?.map(
        (item: {
          pwstt_id: number;
          pwstt_description: string;
          pwstt_sample_configuration: string;
        }) =>
          ({
            value: item.pwstt_id,
            label: item.pwstt_description,
            configuration: item.pwstt_sample_configuration,
          } || []),
      ),
    [workflow_steptypes],
  );

  return (
    <>
      <AddWorkFlowContainer>
        <div className="modal-container">
          <div className="workflow-modal-body">
            <div>
              <div className="action-container">
                <Title
                  titles={[
                    {
                      id: 1,
                      title: "Workflows",
                      navigation: () =>
                        navigate(`/org/${orgId}/project/${id}/workflows`),
                    },
                    {
                      id: 2,
                      title: "Workflow Actions",
                    },
                  ]}
                />
                <div className="divider" />
                <Trigger />
                <div className="action-title">Action</div>
                {workflowSteps?.map((item: WorkflowSteps) => (
                  <div key={item.pwst_id}>
                    <div className={`add-actions-${item.pwst_step_type}`}>
                      {renderActionComponent(item)}
                    </div>
                    <div className="border-div" />
                  </div>
                ))}
                <div className="add-button-container">
                  <button
                    className="add-actions-button"
                    type="button"
                    onClick={() => {
                      setIsDropdownOpen(!isDropdownOpen);
                    }}
                  >
                    <div className="add-icon-container">
                      <img src={AddIcon} alt="+" />
                    </div>
                  </button>
                </div>
                {isDropdownOpen ? (
                  <div className="select-container">
                    {addActionOptions?.map((item, index) => {
                      if (item.value === 1)
                        return (
                          <button
                            key={index}
                            type="button"
                            onClick={() => addActions(item)}
                            className="add-action-field"
                          >
                            <div className="icon-container">
                              <img src={MakeAnAPIRequestIcon} alt="" />
                            </div>
                            <div className="action-text">{item.label}</div>
                          </button>
                        );
                    })}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <div className="btn-divider" />
            <div className="button-container d-flex justify-content-end">
              <Button
                label="Cancel"
                // className=""
                onClick={() => {
                  // go back to before page
                  navigate(-1);
                }}
                isCancel
              />
              <Button label="Save" type="submit" className="save-btn ms-2" />
            </div>
          </div>
        </div>
      </AddWorkFlowContainer>
    </>
  );
};
