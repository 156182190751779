import axios from "../instance";

export const listPrices = () => axios.get(`/subscription/prices`);

export const subscribePlane = (body: any) =>
  axios.post(`/subscription/create-checkout-session`, body);

export const planDetails = () => axios.get("/subscription");

export const subscribedPlanAction = () =>
  axios.post(`/subscription/create-portal-session`);
