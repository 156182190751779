import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { DashboardContainer } from "./elements";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Button } from "../../components/common";
import PlusIcon from "../../assets/icons/add-white.svg";
import {
  projectSelector,
  setSelectedProject,
  setSelectedProjectId,
} from "../../redux/slices/projectSlice";
import { organisationSelector } from "../../redux/slices/organisationSlice";
import { useEffect, useState } from "react";
import { UserOrganisation } from "../../utils/types";
import LogoIcon from "../../assets/icons/aisuit.svg";

export const DashBoard = () => {
  const { projects, isLoading } = useAppSelector(projectSelector);

  const { usersOrganisation, activeOrganisationId } =
    useAppSelector(organisationSelector);
  const [organisationDetails, setOrganisationDetails] = useState<
    UserOrganisation | undefined
  >(undefined);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const orgDetails = usersOrganisation?.find(
      (item: { organisation: { or_id: number } }) =>
        item.organisation.or_id === Number(activeOrganisationId),
    );
    if (orgDetails) {
      setOrganisationDetails(orgDetails);
    }
  }, [usersOrganisation, activeOrganisationId]);

  const orgUserType =
    organisationDetails?.ou_user_type === 1 ||
    organisationDetails?.ou_user_type === 2;

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <DashboardContainer>
        <div className="dashboard-container">
          {orgUserType ? (
            <div className="header">
              <div>Projects</div>
              <Button
                label="Create Project"
                type="submit"
                isLoading={isLoading}
                onClick={() =>
                  navigate(`/org/${activeOrganisationId}/project/add`)
                }
                isFullWidth={false}
                icon={PlusIcon}
                iconPosition="left"
              />
            </div>
          ) : null}
          <div className="divider" />
          <div className="row">
            {projects.length ? (
              <div className="title">Current Projects</div>
            ) : null}
            {projects?.map((project) => (
              // <div key={p_id}>{p_name}</div>
              <div
                key={project.p_id}
                className="col-sm-4 col-lg-3 col-md-3 pb-5"
              >
                <button
                  className="project"
                  onClick={() => {
                    dispatch(
                      setSelectedProject({
                        value: project.p_id,
                        label: project.p_name,
                      }),
                    );
                    dispatch(setSelectedProjectId(project.p_id));
                    navigate(
                      `/org/${activeOrganisationId}/project/${project.p_id}/workflows`,
                    );
                  }}
                >
                  <div className="project-body">
                    <div>
                      <img src={LogoIcon} alt="" />
                    </div>
                    <h5 className="project-title">{project.p_name}</h5>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};
