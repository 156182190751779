import styled from "styled-components";

export const HeaderContainer = styled.div`
  padding: 1.5rem 2.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #f4f6fc;

  .logo-icon {
    width: 1.25rem;
    height: 1.25rem;
    img {
      width: 100%;
      height: 100%;
      vertical-align: unset;
    }
  }
  .web-icon {
    width: 0.75rem;
    height: 0.75rem;
    img {
      width: 100%;
      height: 100%;
      vertical-align: unset;
    }
  }
  .web-link {
    font-size: 1rem;
    color: #655aec;
    margin-left: 0.375rem;
    font-weight: 600;
  }
  .link-container {
    display: flex;
    margin-left: 2rem;
  }
  .project-left-container,
  .project-right-container {
    display: flex;
    align-items: center;
  }
  .project-name {
    font-size: 1rem;
    text-align: left;
    font-weight: 600;
    font-size: 1rem;
    margin-left: 11px;
  }
  .project-container {
    display: flex;
  }
  .date-container {
    display: flex;
    font-size: 0.875rem;
  }
  .last-updated {
    color: #626061;
  }
  .project-date {
    margin-left: 0.6rem;
    color: #605f5f;
    font-style: italic;
    font-weight: 600;
  }
  .button-container {
    margin-left: 1rem;
  }
`;
