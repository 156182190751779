import axios from "../instance";

import { TriggerData, WorkflowSteps } from "../../utils/types";

export const fetchAllOrgProjects = (orgId: number) =>
  axios.get(`/projects/${orgId}`);

export const createProject = (data: { projectName: string }, orgId: number) =>
  axios.post(`/projects/${orgId}/create`, data);

export const fetchProject = (projectId: number, orgId: number) =>
  axios.get(`/projects/${orgId}/${projectId}`);

// workflows

export const createNewWorkFlow = (
  data: { workflowName: string },
  projectId: number,
  orgId: number,
) => axios.post(`/projects/${orgId}/${projectId}/workflow/create`, data);

export const fetchAllProjectWorkFlows = (orgId: number, projectId: number) =>
  axios.get(`/projects/${orgId}/${projectId}/workflows`);

export const fetchWorkflowStepTypes = (projectId: number) =>
  axios.get(`/projects/${projectId}/workflow/steptype`);

export const saveWorkflowAction = (
  data:
    | {
        httpMethod: { value: number; label: string } | null;
        httpUrl: string;
        authUserName: string;
        authPassword: string;
        requestBody: string;
        requestHeaders: { header: string; value: string }[];
        workflow_step_id: number;
      }
    | { title: string; message: string; workflow_step_id: number }
    | { url: string; workflow_step_id: number },
  projectId: number,
  orgId: number,
  workflowId: number | undefined,
) =>
  axios.post(
    `/projects/${orgId}/${projectId}/workflow/${workflowId}/step`,
    data,
  );

export const createWorkflowStep = (
  data: {
    order: number;
    step: number;
  },
  projectId: number,
  orgId: number,
  workflowId: number | undefined,
) =>
  axios.post(
    `/projects/${orgId}/${projectId}/workflow/${workflowId}/create-step`,
    data,
  );

export const updateWorkflowSteps = (
  updatedWorkflowSteps: WorkflowSteps[],
  projectId: number,
  orgId: number,
  workflowId: number | undefined,
) =>
  axios.put(
    `/projects/${orgId}/${projectId}/workflow/${workflowId}/update-steps`,
    updatedWorkflowSteps,
  );

export const createAPI = (
  projectId: number,
  data: {
    api_title: string;
    api_description: string;
    api_server_url: string;
  },
) => axios.post(`/projects/${projectId}/api`, data);

export const fetchAPI = (projectId: number) =>
  axios.get(`/projects/${projectId}/api`);

export const importAPI = (projectId: number, data: any) =>
  axios.post(`/projects/${projectId}/api/import`, data);

export const listEndpoints = (projectId: string, apiId: string) =>
  axios.get(`/projects/${projectId}/api/${apiId}/endpoints`);

export const viewAPI = (projectId: number, apiId: number) =>
  axios.get(`/projects/${projectId}/api/${apiId}`);

export const fetchAPIAuthMethods = (projectId: number) =>
  axios.get(`/projects/${projectId}/auth-methods`);

export const configureAPI = (
  projectId: number,
  apiId: number | undefined,
  data: { projectName: string },
) => axios.post(`/projects/${projectId}/api/configure/${apiId}`, data);

export const fetchWorkflow = (projectId: number, workflowId: number) =>
  axios.get(`/projects/${projectId}/workflow/${workflowId}`);

export const createWorkflowRun = (
  data: {
    notes: string;
  },
  projectId: number,
  workflowId: number,
) =>
  axios.post(
    `/projects/${projectId}/workflow/${workflowId}/workflow-run/create`,
    data,
  );

export const fetchAllWorkflowRuns = (projectId: number, workflowId: number) =>
  axios.get(`/projects/${projectId}/workflow/${workflowId}/workflow-runs`);

export const fetchWorkflowRun = (
  projectId: number,
  workflowId: number,
  workflowRunId: number,
) =>
  axios.get(
    `/projects/${projectId}/workflow/${workflowId}/workflow-runs/${workflowRunId}/steps`,
  );

export const deleteWorkflowStep = (
  projectId: number,
  workflowId: number | undefined,
  workflowStepId: number,
) =>
  axios.put(
    `/projects/${projectId}/workflow/${workflowId}/step/${workflowStepId}`,
  );

export const createWorkflowTrigger = (
  data: TriggerData,
  projectId: number,
  workflowId: number,
) =>
  axios.post(
    `/projects/${projectId}/workflow/${workflowId}/trigger/create`,
    data,
  );
