import { AnyAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ThunkAction } from "redux-thunk";

import * as authApi from "../../api/auth";
import { RootState } from "./../store";
import { User, UserOrganisation } from "../../utils/types";

import {
  setActiveOrganisationId,
  setUserType,
  setUsersOrganisation,
} from "./organisationSlice";

interface authState {
  error: null;
  isLoading: boolean;
  isFetchingProfile: boolean;
  user: User | null;
}
const initialState: authState = {
  error: null,
  isLoading: false,
  isFetchingProfile: false,
  user: null,
};

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    clearUser(state) {
      state.user = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsFetchingProfile(state, action) {
      state.isFetchingProfile = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const {
  clearUser,
  setError,
  setIsLoading,
  setIsFetchingProfile,
  setUser,
} = authSlice.actions;

export const logIn =
  (
    data: { email: string; password: string },
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const response = await authApi.logIn(data);

      const { jwtToken, user } = response.data;

      if (user) {
        dispatch(setUser(user));
        localStorage.setItem("jwtToken", jwtToken);
        localStorage.setItem("userId", user.us_id);

        if (callback) {
          callback();
        }
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const signUp =
  (
    data: { name: string; password: string; email: string },
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      await authApi.signUp(data);

      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const logout =
  (callBack?: () => void): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(clearUser());
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userId");
    dispatch(setActiveOrganisationId(null));
    dispatch(setUserType(null));
    if (callBack) {
      callBack();
    }
  };
export const fetchProfile =
  (callback: () => void): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (
    dispatch,
    getState: () => {
      organisation: { activeOrganisationId: number | null };
    },
  ) => {
    const { activeOrganisationId } = getState().organisation;
    dispatch(setIsFetchingProfile(true));

    try {
      const response = await authApi.fetchProfile();

      const { user } = response.data;

      if (user) {
        dispatch(setUser(user));
        localStorage.setItem("userId", user.us_id);
        const userOrganisations = user.organisationUser;

        if (userOrganisations?.length) {
          if (activeOrganisationId) {
            dispatch(setActiveOrganisationId(activeOrganisationId));
            const currentUserType: UserOrganisation | undefined =
              userOrganisations?.find(
                (item: { organisation: { or_id: number } }) =>
                  item.organisation.or_id === Number(activeOrganisationId),
              );

            dispatch(setUserType(currentUserType?.ou_user_type));
          } else {
            dispatch(
              setActiveOrganisationId(userOrganisations[0].organisation.or_id),
            );
            dispatch(setUserType(userOrganisations[0].ou_user_type));
          }
          dispatch(setUsersOrganisation(userOrganisations));
        }
      }
    } catch (error: unknown) {
      dispatch(setIsFetchingProfile(false));
      if (callback) {
        dispatch(logout(callback));
      }
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsFetchingProfile(false));
    }
  };

export const accountVerification =
  (
    token: string | null,
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await authApi.verify(token);

      const { jwtToken, user } = response.data;

      if (user) {
        dispatch(setUser(user?.user));
        localStorage.setItem("jwtToken", jwtToken);
        localStorage.setItem("userId", user.user?.us_id);
      }
      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const resetPassword =
  (
    data: { email: string },
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      await authApi.resetPassword(data);

      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updatePassword =
  (
    data: { password: string; confirmPassword: string },
    token: string | null,
    callback: () => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      await authApi.updatePassword(data, token);

      if (callback) {
        callback();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const verifyResetPasswordToken =
  (
    token: string | null,
    callback: (data: any) => void,
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const response = await authApi.verifyResetPasswordToken(token);

      const { user } = response.data;

      if (user) {
        dispatch(setUser(user));
      }
      if (callback) {
        callback(user);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        dispatch(
          setError(error?.response?.data?.message || "Something went wrong."),
        );
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const authSelector = (state: { auth: authState }) => state.auth;

export const authReducer = authSlice.reducer;
