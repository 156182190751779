import { Helmet } from "react-helmet-async";
import { IntegrationsContainer } from "./elements";

export const Integrations = () => {
  return (
    <>
      <Helmet>
        <title>Integrations | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <IntegrationsContainer className="shadow sm">
        <h1>Integrations</h1>
      </IntegrationsContainer>
    </>
  );
};
