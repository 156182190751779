import styled from "styled-components";

export const RoleContainer = styled.div`
  display: flex;
  flex-direction: row;

  .role {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.625rem;
    padding: 0.3125rem;
    min-width: 4rem;
    background-color: #f9fafb;
    color: #101828;
    border: 0.0625rem solid #eaecf0;
    border-radius: 1.875rem;
    font-weight: 500;
    line-height: normal;
  }
`;
