import styled from "styled-components";

export const AuthSwitchLinkContainer = styled.div`
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  .text {
    color: #333333;
  }
  .href-container {
    margin-left: 0.375rem;
  }

  .href-text {
    text-decoration: none !important;
    color: #7f56d9;
  }
`;
