import styled from "styled-components";

export const ActivateAccountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92.123vh;
  flex-direction: column;
  width: 100%;
  .update-password-container {
    width: 22.5rem;
  }
  .form-container {
    padding-top: 1rem;
    .input-field {
      width: 100%;
      padding-top: 1.5rem;
      div {
        height: 4.5rem;
      }

      .label {
        margin-bottom: 0.375rem !important;
        line-height: 1.25rem;
        color: #344054;
      }
      .input-container {
        height: 2.875rem;
      }
    }
  }
  .accept-terms-label {
    color: #a3aeb8;
    text-align: left;
    margin-left: 0.5rem;
    font-size: 0.875rem;
  }
  .title-container {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-weight: 600;
    font-size: 2rem;
    color: #333333;
    line-height: normal;
  }
  .sub-title {
    font-weight: 500;
    font-size: 0.75rem;
    color: #000000;
    line-height: normal;
    margin-top: 0.75rem;
  }

  .update-password {
    height: 3.25rem;
    margin-top: 1.5rem;
    .label {
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  .left-container {
    width: 50%;
    margin: 1.125rem;
  }
`;
