import { useEffect } from "react";
// import moment from "moment";
import { Helmet } from "react-helmet-async";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch } from "../../redux/hooks";
import {
  fetchProject,
  setSelectedProjectId,
} from "../../redux/slices/projectSlice";
import { ProjectsContainer } from "./elements";
import { Project as ProjectTypes } from "../../utils/types";
import { setActiveOrganisationId } from "../../redux/slices/organisationSlice";

export const Project = () => {
  const dispatch = useAppDispatch();
  const { id, orgId } = useParams();
  const navigate = useNavigate();
  // const { pathname } = useLocation();

  // const [projectName, setProjectName] = useState("");
  // const [projectCreatedDated, setProjectCreatedDated] = useState("");

  useEffect(() => {
    if (id && orgId) {
      dispatch(setSelectedProjectId(Number(id)));
      dispatch(setActiveOrganisationId(Number(orgId)));
      dispatch(
        fetchProject((project: ProjectTypes) => {
          if (project) {
            dispatch(setActiveOrganisationId(Number(orgId)));
            // setProjectName(project?.p_name);
            // setProjectCreatedDated(project?.p_created_at);
          } else {
            navigate("/dashboard");
          }
        }),
      );
    }
  }, [id]);

  // const renderPageTitle = () => {
  //   let title;
  //   if (pathname.includes("api")) {
  //     title = "APIs";
  //     return title;
  //   }
  //   if (pathname.includes("workflows")) {
  //     title = "Workflows";
  //     return title;
  //   }
  //   if (pathname.includes("settings")) {
  //     title = "Overview";
  //     return title;
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>Projects | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <ProjectsContainer>
        {/* <Header
          projectName={projectName}
          projectDate={moment(projectCreatedDated).format("H:MM A D MMMM YYYY")}
        /> */}
        <div className="content">
          {/* {!apiId ? (
            <div className="project-title">
              {projectName} / {renderPageTitle()}
            </div>
          ) : null} */}
          <div className="main-container">
            <div className="data-container">
              <Outlet />
            </div>
          </div>
        </div>
      </ProjectsContainer>
    </>
  );
};
