import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";

import {
  Button,
  CustomModal,
  FormInput,
  ToastMessage,
} from "../../../../components/common";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  updateBasicDetail,
  setError,
  organisationSelector,
  setUserType,
  deleteOrgLogo,
} from "../../../../redux/slices/organisationSlice";

import DeleteIcon from "../../../../assets/icons/delete.svg";
import ImageIcon from "../../../../assets/icons/upload-img.svg";
import WarningIcon from "../../../../assets/icons/warning.svg";

import { basicDetailsSchema } from "../../../../utils/validation";

import { BasicDetailsContainer } from "./elements";

export const BasicDetails = () => {
  const { isLoading, error } = useAppSelector(organisationSelector);
  const { orgId } = useParams();
  const dispatch = useAppDispatch();

  const [previewImage, setPreviewImage] = useState<any>(ImageIcon);
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const { usersOrganisation, userType, isDeleting } =
    useAppSelector(organisationSelector);

  // check user type is owner or admin
  const isValidUser = userType === 1 || userType === 2;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(basicDetailsSchema),
    defaultValues: {
      organisationName: "",
    },
  });

  const handleSelectImage = (event: any) => {
    const objectUrl = URL.createObjectURL(event.files[0]);
    setPreviewImage(objectUrl);
    setUploadedImage(event.files[0]);
  };

  useEffect(() => {
    if (orgId) {
      // find current organisation
      const orgDetails = usersOrganisation?.find(
        (item: { organisation: { or_id: number } }) =>
          item.organisation.or_id === Number(orgId),
      );

      if (orgDetails) {
        // set organisation user type
        dispatch(setUserType(orgDetails?.ou_user_type));
      }
      if (orgDetails?.organisation) {
        reset({
          organisationName: orgDetails?.organisation?.or_name,
        });
        if (orgDetails?.organisation?.signed_in_url) {
          setPreviewImage(orgDetails?.organisation?.signed_in_url);
        }
      }
    }
  }, [orgId]);

  const onSubmit = (data: { organisationName: string }) => {
    const form = new FormData();
    form.append("organisationName", JSON.stringify(data.organisationName));
    form.append("organisationLogo", uploadedImage);

    dispatch(
      updateBasicDetail(form, Number(orgId), () => {
        reset({
          organisationName: data?.organisationName,
        });
        setUploadedImage(null);
      }),
    );
  };

  const onDelete = () => {
    dispatch(
      deleteOrgLogo(Number(orgId), () => {
        setUploadedImage(null);
        setPreviewImage(ImageIcon);
        setShowModal(false);
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>Basic Details | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <BasicDetailsContainer>
        <form
          className="d-flex flex-column w-100 form"
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          noValidate
        >
          <div>
            <div className="header">Organisation</div>
            <div className="divider" />
            <div className="org-logo-container">
              <div
                className={classNames("image-container", {
                  "is-change": previewImage !== ImageIcon,
                })}
              >
                <img
                  src={previewImage ? previewImage : uploadedImage}
                  alt="preview-image"
                />
              </div>{" "}
              {isValidUser ? (
                <div className="logo-button-container">
                  <div className="update-button">
                    <input
                      type="file"
                      id="file-input"
                      name="organisationLogo"
                      onChange={(event) => handleSelectImage(event.target)}
                      accept="image/*"
                    />
                    <div
                      className={classNames("update-button-div", {
                        "is-change": previewImage !== ImageIcon,
                      })}
                    >
                      <label id="file-input-button" htmlFor="file-input">
                        {previewImage !== ImageIcon
                          ? "Change Logo"
                          : "Update Logo"}
                      </label>
                    </div>
                  </div>
                  {previewImage !== ImageIcon && !uploadedImage ? (
                    <div className="delete-button">
                      <Button
                        label="Delete"
                        type="button"
                        isCancel
                        icon={DeleteIcon}
                        iconPosition="left"
                        onClick={() => setShowModal(true)}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>

            <div className="input-field">
              <Controller
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    id="user-email-id"
                    type="email"
                    label="Organisation Name"
                    width="100%"
                    placeholder="Email"
                    errorMessage={errors.organisationName?.message}
                    hasError={!!errors.organisationName}
                    isDisabled={!isValidUser}
                  />
                )}
                name="organisationName"
              />
            </div>
          </div>
          <div>
            <div className="btn-divider" />
            {isValidUser ? (
              <div className="button-container">
                <Button
                  label="Cancel"
                  type="button"
                  isLoading={isLoading}
                  isLarge={false}
                  isCancel
                  className="cancel-btn"
                />
                <Button
                  label="Update"
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={!(uploadedImage || isDirty)}
                  isLarge={false}
                />
              </div>
            ) : null}
          </div>
        </form>
      </BasicDetailsContainer>
      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
      <CustomModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        onClick={onDelete}
        label={isDeleting ? "Deleting..." : "Delete"}
        isLoading={isDeleting}
        title="Confirm Delete"
        subTitle={`Are you sure you want to delete Logo?`}
        isDelete={true}
        icon={WarningIcon}
      />
    </>
  );
};
