import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useState, forwardRef, TextareaHTMLAttributes } from "react";
import { TextAreaElementContainer, TextAreaContainer } from "./elements";

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  autoFocus?: boolean;
  className?: string;
  errorMessage?: string | undefined | null;
  flexBasis?: string | undefined | null;
  hasError?: boolean;
  isLarge?: boolean;
  label?: string;
  id?: string;
  isDisabled?: boolean;
  maxWidth?: string | undefined | null;
  minWidth?: string | undefined | null;
  placeholder?: string;
  width?: string;
  rows?: number;
  sublabel?: string;
}
export type TextAreaRef = HTMLTextAreaElement;

export const FormTextArea = forwardRef<TextAreaRef, TextAreaProps>(
  (
    {
      className,
      errorMessage,
      flexBasis,
      hasError,
      isLarge,
      label,
      isDisabled,
      maxWidth,
      minWidth,
      placeholder,
      width,
      rows,
      sublabel,
      ...rest
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <TextAreaContainer
        className={classNames(
          "d-flex",
          "flex-column",
          "position-relative",
          "w-100",
          className,
        )}
        flexBasis={flexBasis}
        maxWidth={maxWidth}
        minWidth={minWidth}
        width={width}
      >
        {label ? (
          <label className={classNames("label mb-2")} htmlFor={label}>
            {label}
          </label>
        ) : null}
        {sublabel ? (
          <label className="sub-label" htmlFor={label}>
            {sublabel}
          </label>
        ) : null}
        <TextAreaElementContainer
          className={classNames(
            "d-flex",
            "align-items-center",
            "position-relative",
            {
              "has-error": hasError,
              "is-focused": isFocused,
            },
          )}
        >
          <textarea
            {...rest}
            className={classNames("text-area", "w-100", {
              "is-large": isLarge,
            })}
            rows={rows}
            id={label}
            onFocus={() => {
              setIsFocused(true);
            }}
            placeholder={placeholder}
            ref={ref}
            onBlur={() => {
              setIsFocused(false);
            }}
            disabled={isDisabled}
          />
        </TextAreaElementContainer>
        <AnimatePresence>
          {errorMessage ? (
            <motion.span
              animate={{
                opacity: 1,
                translateY: 0,
              }}
              className="error-message position-absolute"
              exit={{
                opacity: 0,
                translateY: "-0.25rem",
              }}
              initial={{
                opacity: 0,
                translateY: "-0.25rem",
              }}
              transition={{
                duration: 0.2,
                type: "keyframes",
              }}
            >
              {errorMessage}
            </motion.span>
          ) : null}
        </AnimatePresence>
      </TextAreaContainer>
    );
  },
);

FormTextArea.displayName = "FormTextArea";
