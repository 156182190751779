import Toast from "react-bootstrap/Toast";
import ToastContainer, { ToastPosition } from "react-bootstrap/ToastContainer";

// bg = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
// positon = 'top-start' | 'top-center' | 'top-end' | 'middle-start' | 'middle-center' | 'middle-end' | 'bottom-start' | 'bottom-center' | 'bottom-end'

interface ToastMessageProps {
  isVisible?: boolean;
  message?: string | null;
  position?: undefined | ToastPosition;
  type?: string;
  onClose?: () => void;
}

export const ToastMessage = ({
  isVisible,
  message,
  position,
  type,
  onClose,
}: ToastMessageProps) => {
  return (
    <ToastContainer
      className="p-4"
      position={position ? position : "bottom-start"}
    >
      <Toast
        onClose={onClose}
        show={isVisible}
        delay={3000}
        autohide
        bg={type ? type : "success"}
      >
        <Toast.Body className="text-white">{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
