import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export const ButtonEdgedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
  cursor: "pointer";
  transition: transform 0.1s ease;
  background-color: transparent;
  .add-actions-button {
    outline: none;
    border: none;
    background: transparent;
    padding: unset;
    height: 1.125rem;
    width: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
`;

export const ModalContainer = styled(Modal)`
  .modal-body {
    padding: 24px;
  }
  .add-action {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon img {
      height: 32px;
      width: 32px;
      cursor: pointer;
    }
    .action-text {
      color: black;
      font-size: 24px;
      font-family: Inter;
      font-weight: 600;
      line-height: 28px;
      word-wrap: break-word;
    }
  }
  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    .input-field {
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      padding: 11px 10px 11px 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 42px;
      position: relative;
      margin: 24px 0;
      font: 500 16px/20px;

      &:focus-visible {
        border: 2px solid #d9d9d9;
      }
      :focus {
        outline: none;
      }
    }
    .search-icon {
      position: absolute;
      left: 0.5rem;
      opacity: 0.5;
      height: 16px;
      width: 16px;
    }
  }
  .select-container {
    display: flex;
    outline: none;
    flex-direction: column;
    background: #ffffff;
    .add-action-field {
      border: none;
      outline: none;
      background: transparent;
      text-align: left;
      padding: 0.75rem 1rem;
      display: flex;
      align-items: center;
      height: 60px;

      &:hover {
        border: 1px solid #d0d5dd;
        box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
      }
      .action-text {
        color: #000000;
        text-align: left;
        font: 500 16px/20px "Inter", sans-serif;
        padding-left: 10px;
      }
      .icon-container {
        display: flex;
        height: 40px;
        width: 40px;
        border: 1px solid #d0d5dd;
        border-radius: 5px;
        img {
          width: 100%;
          height: 100%;
          padding: 4px;
          color: #655aec;
        }
      }
    }
  }
`;
