import { useCallback, useEffect, useMemo, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { authSelector, logout } from "../../../redux/slices/authSlice";
import {
  organisationSelector,
  setActiveOrganisationId,
  setUserType,
} from "../../../redux/slices/organisationSlice";
import {
  fetchAllOrgProjects,
  projectSelector,
  setSelectedProject,
  setSelectedProjectId,
} from "../../../redux/slices/projectSlice";

import UserIcon from "../../../assets/icons/user.svg";
import OrgSettingsIcon from "../../../assets/icons/org-change.svg";
import AccountSettingsIcon from "../../../assets/icons/settings-dark.svg";
import PlusIcon from "../../../assets/icons/add-white.svg";
import LogoutIcon from "../../../assets/icons/logout.svg";
import LessyLogo from "../../../assets/app-logo/lessy.svg";

import { SelectInput } from "../SelectInput";
import { Button } from "../Button";
import { Tabs } from "./components";
import { NavbarContainer } from "./elements";

import { Project, UserOrganisation } from "../../../utils/types";
import { RadioButton } from "../Radio";

const Navbar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [currentOrgname, setCurrentOrgname] = useState<string | undefined>();

  const { user } = useAppSelector(authSelector);
  const { usersOrganisation, activeOrganisationId, userType } =
    useAppSelector(organisationSelector);
  const { projects, isLoading, selectedProjectId, selectedProject } =
    useAppSelector(projectSelector);

  // checking organisation user type to set the access of projects and organisation settings
  const orgUserType = userType === 1 || userType === 2;

  useEffect(() => {
    if (activeOrganisationId) {
      dispatch(fetchAllOrgProjects(activeOrganisationId));
    }
  }, [activeOrganisationId]);

  const projectData = useMemo(
    () =>
      projects?.map(
        (item: { p_id: number; p_name: string }) =>
          ({
            value: item.p_id,
            label: item.p_name,
          } || []),
      ),
    [projects],
  );

  useEffect(() => {
    if (selectedProjectId && projects?.length) {
      const currentProject: Project | undefined = projects.find(
        (item: { p_id: number }) => item.p_id === Number(selectedProjectId),
      );
      if (currentProject) {
        dispatch(
          setSelectedProject({
            value: currentProject?.p_id,
            label: currentProject?.p_name,
          }),
        );
        dispatch(setSelectedProjectId(currentProject?.p_id));
        const currentUserType: UserOrganisation | undefined =
          usersOrganisation?.find(
            (item: { organisation: { or_id: number } }) =>
              item.organisation.or_id === Number(activeOrganisationId),
          );
        dispatch(setUserType(currentUserType?.ou_user_type));
      } else {
        dispatch(setSelectedProject(null));
      }
    }
  }, [projects, selectedProjectId]);

  useEffect(() => {
    if (activeOrganisationId) {
      const selectedOrganisation: UserOrganisation | undefined =
        usersOrganisation?.find(
          (item: { organisation: { or_id: number } }) =>
            item.organisation.or_id === Number(activeOrganisationId),
        );
      if (selectedOrganisation) {
        setCurrentOrgname(selectedOrganisation?.organisation?.or_name);
      }
    }
  }, [usersOrganisation, activeOrganisationId]);

  const onCheck = useCallback(
    (orgId: number, orgUserType: number, newRoute?: string) => {
      dispatch(setActiveOrganisationId(orgId));
      dispatch(setUserType(orgUserType));
      dispatch(fetchAllOrgProjects(orgId));
      if (newRoute) {
        navigate(newRoute);
      } else {
        navigate("/dashboard");
      }
    },
    [],
  );

  const onSelect = (data: any) => {
    dispatch(setSelectedProject(data));
    dispatch(setSelectedProjectId(data.value));
    navigate(`/org/${activeOrganisationId}/project/${data.value}/workflows`);
  };

  const Organisation = useCallback(() => {
    if (usersOrganisation) {
      const organisationlist = usersOrganisation?.map(
        (item: UserOrganisation, index: number) => (
          <NavDropdown.Item key={index} className="row org-radio-btn">
            <div className="col-md-10 org-col">
              {/* <Form.Check
                className="d-flex org-radio align-items-center"
                label={item?.organisation.or_name}
                name="button"
                type="radio"
                id={`inline-radio-${item.organisation.or_id}`}
                checked={activeOrganisationId === item.organisation.or_id}
                onChange={() => {
                  onCheck(item.organisation.or_id, item.ou_user_type);
                }}
              /> */}
              <RadioButton
                label={item?.organisation.or_name}
                onClick={() => {
                  onCheck(item.organisation.or_id, item.ou_user_type);
                }}
                id={`${item.organisation.or_id}`}
                checked={activeOrganisationId === item.organisation.or_id}
                className="radio-button"
              />
            </div>

            <div className="col-md-2 change-icon">
              <img
                src={OrgSettingsIcon}
                alt=""
                className="icon"
                role="button"
                onClick={() => {
                  onCheck(
                    item.organisation.or_id,
                    item.ou_user_type,
                    `/org/${item.organisation.or_id}/settings/basic`,
                  );
                }}
              />
              {/* </Link> */}
            </div>
          </NavDropdown.Item>
        ),
      );
      return organisationlist;
    }
  }, [usersOrganisation, activeOrganisationId]);

  return (
    <>
      <NavbarContainer className="navbar">
        <div className="nav-container">
          {/* <AuthNavbar to="/dashboard" className="navbar-href" /> */}
          <div className="logo-container">
            <Link to="/dashboard" className="logo-link">
              <img src={LessyLogo} alt="" />
              {/* <div className="app-name">{process.env.REACT_APP_NAME}</div> */}
            </Link>
          </div>
          {!pathname.includes("/settings/") &&
          !pathname.includes("/dashboard") &&
          !pathname.includes("/project/add") &&
          projectData?.length ? (
            <div className="d-flex">
              <div className="select-container">
                <SelectInput
                  value={selectedProject}
                  options={projectData}
                  placeholderColor="#ffffff"
                  placeholder="Select Projects"
                  onChange={(project) => {
                    onSelect(project);
                  }}
                  borderColor="1px solid #8D85F3"
                  className="project-list"
                  customComponent={
                    <>
                      {orgUserType ? (
                        <div className="button-container w-100">
                          <Button
                            label="Create Project"
                            type="submit"
                            isLoading={isLoading}
                            className="w-100 align-items-center"
                            onClick={() =>
                              navigate(
                                `/org/${activeOrganisationId}/project/add`,
                              )
                            }
                            isFullWidth={false}
                            icon={PlusIcon}
                            iconPosition="left"
                          />
                        </div>
                      ) : null}
                    </>
                  }
                />
              </div>
              <Tabs
                projectId={selectedProjectId}
                orgId={activeOrganisationId}
              />
            </div>
          ) : null}
        </div>

        <Nav className="align-items-center org-container">
          <span className="org-name">{currentOrgname}</span>

          <NavDropdown
            id="nav-dropdown-dark-example"
            title={
              <img src={UserIcon} alt="" className="dropdown-profile-img" />
            }
          >
            <div className="main-nav-dropdown">
              <NavDropdown.ItemText className="dropdown-item-text-user">
                <div className="user-details">
                  <div className="user-name">{user?.us_full_name}</div>
                  <div className="user-email">{user?.us_email}</div>
                </div>
              </NavDropdown.ItemText>
              <div className="dropdown-item-container">
                <NavDropdown.Item
                  onClick={() => {
                    navigate(`/settings/change-password`);
                  }}
                  className="dropdown-item"
                >
                  <div className="menu">
                    <div className="icon-container">
                      <img src={AccountSettingsIcon} alt="" className="icon" />
                    </div>
                    <div>Account Settings</div>
                  </div>
                </NavDropdown.Item>
                {/* <NavDropdown.Item
              onClick={() => {
                navigate(`/org/${activeOrganisationId}/settings/basic`);
              }}
            >
              <div className="menu">
                <div className="icon-container">
                  <img src={OrganisationSettingsIcon} alt="" className="icon" />
                </div>
                <div>Organisation Settings</div>
              </div>
            </NavDropdown.Item> */}
                <NavDropdown.Item
                  onClick={() => {
                    dispatch(
                      logout(() => {
                        navigate("/auth/login");
                      }),
                    );
                  }}
                  className="logout-link"
                >
                  <div className="menu">
                    <div className="icon-container">
                      <img src={LogoutIcon} alt="" className="icon" />
                    </div>
                    <div>Logout</div>
                  </div>
                </NavDropdown.Item>
              </div>
              {usersOrganisation?.length ? (
                <div className="user-organisation-container">
                  <NavDropdown.ItemText className="item-text-list">
                    <div className="user-organisation">Your Organisations</div>
                  </NavDropdown.ItemText>
                  <div key="radio" className="organisation-list">
                    {Organisation()}
                  </div>
                </div>
              ) : null}
            </div>
          </NavDropdown>
        </Nav>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
