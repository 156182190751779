import styled from "styled-components";

export const TableContainer = styled.table`
  width: 100%;

  &.type-1 {
    border-radius: 0.5rem;
    overflow: hidden;
    thead {
      border: none !important;
      /* outline: thin solid #eaecf0; */
    }
  }

  &.type-2 {
    border-spacing: 0px !important;
    border-collapse: separate;
    thead,
    tbody {
      border: none !important;
      outline: thin solid #eaecf0;
    }
  }
`;

export const TableBody = styled.tbody`
  border-bottom-width: 0;

  &.type-1 {
    border-bottom-width: 0;
    tr {
      background-color: #ffffff;
      :hover {
        background-color: #f4f6fc;
      }
      td {
        padding: 1rem 1.5rem;
        font-weight: 500;
        font-size: 0.875rem;
        color: #101828;
      }
    }

    tr {
      :not(:last-child) {
        border-bottom: 0.0125rem solid #eaecf0;
      }
      td:last-child {
        padding: 1rem 1.5rem !important;
      }
    }
  }
  &.type-2 {
    border-radius: 0.5rem;
    background-color: #ffffff;
    tr {
      :hover {
        background-color: #f4f6fc;
      }
      td {
        border: 0;

        padding: 0.8125rem;
        font-weight: 500;
        font-size: 0.875rem;
        color: #101828;
        text-transform: capitalize;
      }
    }
    tr:first-child td:first-child {
      border-top-left-radius: 0.5rem;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 0.5rem;
    }

    tr:first-child td:last-child {
      border-top-right-radius: 0.5rem;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 0.5rem;
    }
  }
`;

export const TableHead = styled.thead`
  th {
    text-transform: Capitalize;
  }
  &.type-1 {
    background-color: #f9fafb;
    th {
      padding: 0.8125rem 1.5rem;
      font-weight: 500;
      font-size: 1rem;
      color: #667085;
    }
  }

  &.type-2 {
    border-radius: 0.5rem;
    background-color: #ffffff;
    th {
      font-weight: 700;
      font-size: 0.875rem;
      color: #344054;
      padding: 1rem;
    }
    tr:first-child th:first-child {
      border-top-left-radius: 0.5rem;
    }

    tr:last-child th:first-child {
      border-bottom-left-radius: 0.5rem;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 0.5rem;
    }

    tr:last-child th:last-child {
      border-bottom-right-radius: 0.5rem;
    }
  }
`;
export const TableHeader = styled.div`
  &.type-1 {
    display: none;
  }
  &.type-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    width: 100%;
    .item-container {
      width: 30%;
      display: flex;
      flex-direction: row;
    }
    .add-button {
      background-color: #6862f5 !important;
    }

    .item-count {
      font-size: 1rem;
      font-weight: 700;
      color: #000000;
    }
  }

  @media (max-width: 43.75rem) {
    .item-container {
      width: 100%;
    }
  }
`;

export const TableMainConatiner = styled.div`
  margin-bottom: 1rem;
  overflow-x: auto;
  &.type-1 {
    border: 0.0625rem solid #eaecf0;
    box-shadow: 0 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1),
      0 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.5rem;
    border-collapse: collapse;
  }
  .seperator {
    height: 0.75rem;
    width: 100%;
  }
`;
