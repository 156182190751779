import { Routes, Route } from "react-router-dom";
import Navbar from "../../components/common/Navbar";

import { PrivateRoute } from "../../PrivateRoute";
import { ProjectRoute } from "../../Routes/Project";
import { DashBoard } from "../DashBoard";
import { Add } from "../Projects/pages/Add";

import { SettingsRoutes } from "../Settings";
import { UserSettings } from "../UserSettings";

export const MainLayout = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashBoard />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/*"
          element={
            <PrivateRoute>
              <UserSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/org/:orgId/project/add"
          element={
            <PrivateRoute>
              <Add />
            </PrivateRoute>
          }
        />
        <Route
          path="/org/:orgId/project/:id/*"
          element={
            <PrivateRoute>
              <ProjectRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/org/:orgId/settings/*"
          element={
            <PrivateRoute>
              <SettingsRoutes />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};
