import { useState } from "react";
import classNames from "classnames";
import { Routes, Route } from "react-router-dom";

import { ChangePassword, SideNavBar } from "./components";
import { SettingsRoutesContainer } from "./elements";

export const UserSettings = () => {
  const [collapse, setCollapse] = useState<any | null>("");
  return (
    <>
      <SettingsRoutesContainer>
        <SideNavBar setCollapse={setCollapse} collapse={collapse} />
        <div
          className={classNames("main-container", {
            "is-collapsed": collapse,
          })}
        >
          <Routes>
            <Route path="/change-password" element={<ChangePassword />} />
          </Routes>
        </div>
      </SettingsRoutesContainer>
    </>
  );
};
