import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(
    rgb(101, 90, 236) 0%,
    rgba(101, 90, 236, 0.79) 100%
  );
  border-radius: 1.25rem;
  width: 100%;
  height: 100%;
`;
