import { Helmet } from "react-helmet-async";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";

import { Button, FormInput } from "../../../../components/common";
import { AuthNavbar, AuthSwitchLink, Container } from "../../components";
import { LoginContainer, MainContainer } from "./elements";
import { loginSchema } from "../../../../utils/validation";
import {
  authSelector,
  logIn,
  setError,
} from "../../../../redux/slices/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ToastMessage } from "../../../../components/common/ToastMessage";
// import { Form } from "react-bootstrap";

export const Login = () => {
  const { isLoading, error } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (e: { email: string; password: string }) => {
    dispatch(
      logIn(e, () => {
        navigate("/dashboard");
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>Login | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <MainContainer>
        <div className="w-50">
          <AuthNavbar />
          <LoginContainer>
            <div className="login-container">
              <div className="title-container">
                <div className="title">
                  Welcome to {process.env.REACT_APP_NAME}
                </div>
                <div className="sub-title">
                  Enter your credentials to access your account
                </div>
              </div>
              <div className="form-container">
                <form
                  className="align-items-center d-flex flex-column w-100"
                  onSubmit={handleSubmit(onSubmit)}
                  method="POST"
                  noValidate
                >
                  <div className="input-field">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="user-login-email-id"
                          type="email"
                          width="100%"
                          placeholder="Email"
                          autoComplete="off"
                          errorMessage={errors.email?.message}
                          hasError={!!errors.email}
                          label="Email"
                        />
                      )}
                      name="email"
                    />
                  </div>
                  <div className="input-field">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          {...field}
                          id="password"
                          type="password"
                          width="100%"
                          placeholder="Enter your Password"
                          autoComplete="new-password"
                          errorMessage={errors.password?.message}
                          hasError={!!errors.password}
                          label="Password"
                        />
                      )}
                      name="password"
                    />
                  </div>
                  <div className="forgot-password-container">
                    {/* <div className="align-items-start d-flex mt-4">
                      <Form.Check
                        type="checkbox"
                        id={`default-checkbox`}
                        label={`Remember for 30 days`}
                      />
                    </div> */}
                    <div className="forgot-password-div">
                      <Link
                        to="/auth/reset-password"
                        className="forgot-password"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>

                  <Button
                    isLarge
                    label="Log In"
                    type="submit"
                    isLoading={isLoading}
                    className="w-100 login-btn"
                  />
                </form>
              </div>
              <AuthSwitchLink
                linkHref="/auth/signup"
                linkText="Sign up"
                text="Don't have an account?"
                className="justify-content-center"
              />
            </div>
          </LoginContainer>
        </div>
        <div className="left-container">
          <Container />
        </div>
      </MainContainer>
      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
