import styled from "styled-components";
// import { ButtonProps } from "../index";

export const ButtonContainer = styled.button`
  background-color: #655aec;
  border: 0.0625rem solid #655aec;
  border-radius: 0.5rem;
  padding: 0.5rem;
  transition: background-color 0.1s, border-color 0.1s, box-shadow 0.1s;

  :disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.is-cancel {
    background-color: unset;
    border: 0.0625rem solid #655aec;

    span {
      color: #655aec !important;
      font-weight: 500;
    }
    :hover,
    :active {
      background-color: unset;
      opacity: 1;
      outline: none;
      box-shadow: none;
      border: 0.0625rem solid rgb(222, 222, 222);
    }
  }

  :focus {
    outline: none;
  }

  .icon {
    height: 1.25rem;
    width: 1.25rem;
  }

  .spinner {
    animation: spin 1s linear infinite;
    height: 0.875rem;
    width: 0.875rem;
    margin-right: 0.5rem;
    &.is-large {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .label {
    color: white;
    font-size: 0.875rem;
    font-weight: 600;

    &.is-large {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;
