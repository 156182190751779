import { useState } from "react";
import classNames from "classnames";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

import { CollapseAndExpand } from "../../../../../../../../components/common";
import { SideBarContainer } from "./elements";

import ActionsIcon from "../../../../../../../../assets/icons/actions.svg";
import RunsIcon from "../../../../../../../../assets/icons/runs.svg";
import ActionsActiveIcon from "../../../../../../../../assets/icons/actions-active.svg";
import RunsActiveIcon from "../../../../../../../../assets/icons/runs-active.svg";

import { CollapseAndExpandProps } from "../../../../../../../../utils/types";

export const SideBar = ({ setCollapse, collapse }: CollapseAndExpandProps) => {
  const [active, setActive] = useState<any | null>("");

  const { pathname, state } = useLocation();
  const { workflowId } = useParams();

  return (
    <SideBarContainer
      className={classNames({
        "is-collapsed": collapse,
      })}
    >
      <Nav
        activeKey={active}
        className="flex-column nav-container"
        onSelect={(selectedKey) => {
          setActive(selectedKey);
        }}
      >
        <Nav.Item
          className={classNames("bar-item", {
            "active-link": pathname.includes(`/actions`),
          })}
        >
          <NavLink
            to={`actions`}
            id="api"
            className="d-flex align-items-center flex-column justify-content-center"
            state={{ pw_name: state.pw_name, pw_id: state.pw_id }}
          >
            <div className="img-container">
              {pathname.includes(`${workflowId}/actions`) ? (
                <img src={ActionsActiveIcon} alt="" />
              ) : (
                <img src={ActionsIcon} alt="" />
              )}
            </div>
            {!collapse ? <div className="text">Actions</div> : null}
          </NavLink>
        </Nav.Item>

        <Nav.Item
          className={classNames("bar-item", {
            "active-link": pathname.includes(`/runs`),
          })}
        >
          <NavLink
            to={`runs`}
            id="workflows"
            className="d-flex align-items-center flex-column justify-content-center"
            state={{ pw_name: state.pw_name, pw_id: state.pw_id }}
          >
            <div className="img-container">
              {pathname.includes("/runs") ? (
                <img src={RunsActiveIcon} alt="" />
              ) : (
                <img src={RunsIcon} alt="" />
              )}
            </div>
            {!collapse ? <div className="text">Runs</div> : null}
          </NavLink>
        </Nav.Item>
        <Nav.Item
          className={classNames("bar-item", {
            "active-link": pathname.includes(`/flow`),
          })}
        >
          <NavLink
            to={`flow`}
            id="workflows"
            className="d-flex align-items-center flex-column justify-content-center"
            state={{ pw_name: state.pw_name, pw_id: state.pw_id }}
          >
            <div className="img-container">
              {pathname.includes("/flow") ? (
                <img src={RunsActiveIcon} alt="" />
              ) : (
                <img src={RunsIcon} alt="" />
              )}
            </div>
            {!collapse ? <div className="text">Flow</div> : null}
          </NavLink>
        </Nav.Item>
        {/* <Nav.Item
          className={classNames("bar-item", {
            "active-link": pathname.includes(`/steps`),
          })}
        >
          <NavLink
            to={`steps`}
            id="settings"
            className="d-flex align-items-center"
            state={{ pw_name: state.pw_name, pw_id: state.pw_id }}
          >
            <div className="img-container">
              {pathname.includes(`/steps`) ? (
                <img src={SettingsIcon} alt="" />
              ) : (
                <img src={SettingsIcon} alt="" />
              )}
            </div>
            <div className="text">Settings</div>
          </NavLink>
        </Nav.Item> */}
      </Nav>
      <CollapseAndExpand collapse={collapse} setCollapse={setCollapse} />
    </SideBarContainer>
  );
};
