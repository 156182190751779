import { useEffect, useState } from "react";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button, FormInput } from "../../../../../../../../components/common";
import { FormTextArea } from "../../../../../../../../components/common/FormTextArea";
import { ShowMessageContainer } from "./elements";

import { useAppDispatch } from "../../../../../../../../redux/hooks";
import { saveWorkflowAction } from "../../../../../../../../redux/slices/projectSlice";

import { StepsTypes } from "../../../../../../../../utils/types";
import { ShowMessageSchema } from "../../../../../../../../utils/validation";

import { Header } from "../../../../common";

interface FormValue {
  title: string;
  message: string;
}

export const ShowAMessage = ({
  workflowId,
  workflowStep,
  isActionOpen,
}: StepsTypes) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(isActionOpen ? true : false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(ShowMessageSchema),
    defaultValues: {
      title: "",
      message: "",
    },
  });

  useEffect(() => {
    if (workflowStep) {
      reset((formValues) => ({
        ...formValues,
        title: workflowStep?.pwst_configuration.message_title,
        message: workflowStep?.pwst_configuration.message_body,
      }));
    }
  }, [workflowStep]);

  const onSave = (data: FormValue) => {
    setIsLoading(true);
    const showMessageData = {
      ...data,
      workflow_step_id: workflowStep.pwst_id,
    };
    dispatch(
      saveWorkflowAction(showMessageData, workflowId, () => {
        setIsLoading(false);
      }),
    );
  };

  const onClose = () => {
    setOpen(!open);
    if (isDirty) {
      reset((formValues) => ({
        ...formValues,
        title: workflowStep?.pwst_configuration.message_title,
        message: workflowStep?.pwst_configuration.message_body,
      }));
    }
  };

  return (
    <ShowMessageContainer
      onSubmit={handleSubmit(onSave)}
      method="POST"
      noValidate
      className={classNames({ "is-open": open })}
    >
      <div className="header-container">
        <Header
          stepTitle="Show A Message"
          workflowId={workflowId}
          workflowStepId={workflowStep.pwst_id}
          onClose={onClose}
          stepType={2}
          isActionOpen={open}
        />
      </div>
      <div
        className={classNames("form-container", {
          "is-open": open,
        })}
      >
        <div>
          <div className="mt-3">
            <Controller
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  id="titlee-id"
                  type="text"
                  label="Title"
                  width="100%"
                  placeholder="Enter your Title"
                  errorMessage={errors.title?.message}
                  hasError={!!errors.title}
                />
              )}
              name="title"
            />
          </div>
          <div className="mt-3">
            <Controller
              control={control}
              render={({ field }) => (
                <FormTextArea
                  {...field}
                  id="message-id"
                  width="100%"
                  placeholder="Write a message to show after the sucessful execution of previous section"
                  errorMessage={errors.message?.message}
                  hasError={!!errors.message}
                  label="Message"
                  rows={6}
                />
              )}
              name="message"
            />
          </div>
        </div>
        {isDirty ? (
          <>
            <div className="button-divider" />
            <div className="button-container">
              <Button
                label="Save"
                type="submit"
                isLoading={isLoading}
                isDisabled={!isDirty}
              />
            </div>
          </>
        ) : null}
      </div>
    </ShowMessageContainer>
  );
};
