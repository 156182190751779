import styled from "styled-components";

export const HeaderContainer = styled.div`
  background-color: #ffffff;
  padding: 1.5rem 2.5rem;

  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.06);
  border-bottom: 0.0625rem solid #eaecf0;
  position: relative;
  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    margin-left: 1rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: Capitalize;
  }
  .back-btn {
    outline: none;
    border: none;
    background: transparent;
    padding: unset;
  }
  .title-container {
    display: flex;
    align-items: center;
  }
`;
