import { ReactElement } from "react";
import Modal from "react-bootstrap/Modal";
import Highlight from "react-highlight";

import { InstructionsContainer } from "./elements";
import CloseIcon from "../../../../../../assets/icons/close.svg";
import CopyIcon from "../../../../../../assets/icons/copy.svg";
import { Button } from "../../../../../../components/common";

interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  children?: ReactElement | null | undefined;
  modalSize?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  label?: string;
  title?: string;
}

const JSCode = `
  <script>
    var x_options = {
        app_id: 'WxbGrREqN4mfZaU',
        user_auth: '<place_user_auth_token_here>',
        customer_id : 'cwr-1234'
    };
  </script>
  <script src="https://api.lessy.in/prompt-widget.min.js"
   data-options="x_options"></script>`;

export const Instructions = ({ show, onHide, modalSize }: CustomModalProps) => {
  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(JSCode);
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <InstructionsContainer
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      size={modalSize}
      dialogClassName="instrctions-dialog-modal"
      contentClassName="instrcutions-content-modal"
    >
      <Modal.Body className="instruction-modal-body">
        <div className="header">
          <div className="title">
            <div>Add {process.env.REACT_APP_NAME} Widget to your Website</div>
            <button type="submit" onClick={onHide}>
              <img src={CloseIcon} alt="x" />
            </button>
          </div>
        </div>
        <div className="sub-title">
          To integrate our {process.env.REACT_APP_NAME} with your website,
          please follow these steps:
        </div>
        <div className="list">
          <span className="points">1.</span> Copy the following script and paste
          it in the section of your website where you want to display the
          widget:
        </div>
        {/* <div className="d-flex flex-row d-grid justify-content-end">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => copyContent()}
          >
            Copy Code
          </button>
        </div> */}

        <div className="copy-code">
          <button className="copy-icon" onClick={() => copyContent()}>
            <img src={CopyIcon} alt="" />
          </button>
          <Highlight className="javascript">{JSCode}</Highlight>
        </div>

        {/* <pre
          style={{
            borderRadius: "5px",
            backgroundColor: "#f1f1f1",
            color: "#000000",
            paddingTop: 10,
            padding: 20,
          }}
        >
          <div className="d-flex flex-row d-grid justify-content-end">
            <button
              type="button"
              className="btn btn-link"
              onClick={() => copyContent()}
            >
              Copy Code
            </button>
          </div>
          <code id="code">{JSCode}</code>
        </pre> */}
        <div className="list">
          <span className="points">2.</span> If you want to authenticate using a
          bearer token on behalf of the logged-in user, please replace
          &lt;place_user_auth_token_here&gt; with the actual token. If not, you
          can leave it empty.
        </div>
        <div className="list">
          <span className="points">3.</span> You can pass any unique identifier
          to recognise your customer with ‘customer_id’ key. It can be used for
          logging and monitoring purposes
        </div>
        <div className="list">
          <span className="points">4.</span> Save the changes to your website
          and the widget should now be displayed.
        </div>
        {/* <div className="d-flex flex-row d-grid justify-content-end m-5 ">
          <button className="btn btn-primary" onClick={onHide}>
            Close
          </button>
        </div> */}
        <div className="d-flex flex-row d-grid justify-content-end close-button-container">
          <Button label="Close" onClick={onHide} />
        </div>
      </Modal.Body>
    </InstructionsContainer>
  );
};
