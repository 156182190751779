import styled from "styled-components";

export const SettingsRoutesContainer = styled.div`
  min-height: 100vh;
  background-color: rgb(248, 247, 251);
  padding-top: 5rem;

  .main-container {
    background-color: #f8f7fb;
    display: flex;
    flex-direction: column;
    padding: 3.25rem 2.5rem 7rem 9.75rem;
    overflow-y: auto;
    width: 100%;
    &.is-collapsed {
      padding: 3.25rem 2.5rem 7rem 6rem;
    }
  }
`;
