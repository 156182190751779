import moment from "moment";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/hooks";
import {
  fetchWorkflowRun,
  projectSelector,
} from "../../../../../../../../redux/slices/projectSlice";

import { ViewLogContainer } from "./elements";
import { Title } from "../../../../common";
import { Button } from "../../../../../../../../components/common";
import { AddWorkflowRun } from "../AddWorkflowRun";

import DownIcon from "../../../../../../../../assets/icons/arrow-down.svg";
import UpIcon from "../../../../../../../../assets/icons/arrow-up.svg";
import SuccessIcon from "../../../../../../../../assets/icons/success.svg";
import FailedIcon from "../../../../../../../../assets/icons/failed.svg";
import RunAgainIcon from "../../../../../../../../assets/icons/run-again.svg";

export const ViewLogs = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { workflowRunId, orgId, workflowId, id } = useParams();

  const [logOpen, setLogOpen] = useState(false);
  const [logId, setLogId] = useState(0);
  const [show, setShow] = useState(false);

  const { workflow, workflowRun, selectedProject } =
    useAppSelector(projectSelector);

  useEffect(() => {
    if (workflow)
      dispatch(fetchWorkflowRun(workflow?.pw_id, Number(workflowRunId)));
  }, [workflow, workflowRunId]);

  const renderWorkflowStepTitle = (stepId: number) => {
    let title = "";
    if (stepId === 1) {
      title = "Make An API Request";
    }
    if (stepId === 2) {
      title = "Show a Message";
    }
    if (stepId === 3) {
      title = "Go To Url";
    }
    return title;
  };

  const renderTimeTaken = (startedAt: string, finishedAt: string) => {
    const startedTime = new Date(startedAt);
    const finishedTime = new Date(finishedAt);

    const timeTaken = finishedTime.getTime() - startedTime.getTime();

    const minutes = Math.floor(timeTaken / 60000);
    const seconds = ((timeTaken % 60000) / 1000).toFixed(0);
    if (minutes) {
      return minutes + "Min" + " " + seconds + " " + "Secs";
    }
    return seconds + " " + "Secs";
  };
  const renderOutput = (stepType: number, configuration: any, output: any) => {
    const stepConfiguration = JSON.parse(configuration);

    const statusAndResponse = `{ status: ${output?.status},
    output: ${JSON.stringify(output.data)} }`;

    if (stepType === 1) {
      return (
        <>
          <div className="request">
            <div>{stepConfiguration.method}</div>
            <div className="mx-3">{stepConfiguration.url}</div>
          </div>
          <div className="status-div d-flex">
            <div className="me-2">STATUS : </div>
            <div className="ms-2">
              HTTP {output?.status} {output?.statusText}
            </div>
          </div>
          <div className="response-div d-flex">
            <div className="me-2">RESPONSE </div>:
            <div className="ms-2">{statusAndResponse}</div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <ViewLogContainer>
        <div className="header-title">
          <div className="title-container">
            <Title
              titles={[
                {
                  id: 1,
                  title: "Workflows",
                  navigation: () =>
                    navigate(`/org/${orgId}/project/${id}/workflows`),
                },
                {
                  id: 2,
                  title: "Workflow Runs",
                  navigation: () =>
                    navigate(
                      `/org/${orgId}/project/${selectedProject?.value}/workflows/${workflowId}/runs`,
                      {
                        state: {
                          pw_name: workflow?.pw_name,
                          pw_id: workflow?.pw_id,
                        },
                      },
                    ),
                },
                {
                  id: 3,
                  title: moment(workflowRun?.pwr_started_at).format(
                    "MMM D YYYY, h:mm A",
                  ),
                },
              ]}
            />
            <Button
              label="Run Again"
              type="button"
              icon={RunAgainIcon}
              iconPosition="left"
              className="run-again"
              onClick={() => {
                setShow(true);
              }}
            />
          </div>
        </div>
        <div className="divider" />
        <div>
          {workflowRun?.workflowRunSteps
            ?.slice() // Create a shallow copy of the array to avoid modifying the original
            .sort((a, b) => a.pwst_order - b.pwst_order)
            .map((item, index) => (
              <div
                className={classNames("log-container", {
                  "is-failed": item.pwrs_status === 2,
                })}
                key={index}
              >
                <div
                  className="d-flex justify-content-between header"
                  onClick={() => {
                    setLogOpen(!logOpen);
                    setLogId(item?.pwrs_id);
                  }}
                >
                  <div className="header-left">
                    <div className="step-count">Step {index + 1}</div>
                    <div
                      className={classNames("vertical-divider", {
                        "is-failed": item.pwrs_status === 2,
                      })}
                    />
                    <div className="run-step">
                      <div className="step-title">
                        {renderWorkflowStepTitle(item.pwst_step_type)}
                      </div>
                      <div className="time me-4">
                        {moment(item?.pwrs_started_at).format(
                          "h:mm - DD MMMM YYYY",
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className={classNames("time-taken", {
                        "is-failed": item.pwrs_status === 2,
                      })}
                    >
                      {renderTimeTaken(
                        item.pwrs_started_at,
                        item.pwrs_finished_at,
                      )}
                    </div>
                    {item.pwrs_status === 2 ? (
                      <button
                        type="button"
                        className="edit-button"
                        onClick={() => {
                          navigate(
                            `/org/${orgId}/project/${selectedProject?.value}/workflows/${workflowId}/actions`,
                            {
                              state: {
                                actionId: item.pwst_id,
                              },
                            },
                          );
                        }}
                      >
                        <div>Edit</div>
                      </button>
                    ) : null}
                    <div
                      className={classNames("status", {
                        success: item.pwrs_status === 3,
                      })}
                    >
                      <img
                        src={item.pwrs_status === 3 ? SuccessIcon : FailedIcon}
                        alt=""
                      />
                    </div>
                    <button
                      onClick={() => {
                        setLogOpen(!logOpen);
                        setLogId(item?.pwrs_id);
                      }}
                      className="drop-button expand"
                      type="button"
                    >
                      <img
                        src={
                          item?.pwrs_id === logId && logOpen ? UpIcon : DownIcon
                        }
                        alt=""
                      />
                    </button>
                  </div>
                </div>

                <div
                  className={classNames("data-container", {
                    "is-open": item?.pwrs_id === logId && logOpen,
                  })}
                >
                  <div
                    className={classNames("splitter", {
                      "is-failed": item.pwrs_status === 2,
                    })}
                  />
                  <div className="output-data">
                    {renderOutput(
                      item.pwst_step_type,
                      item.pwst_configuration,
                      item.pwrs_output,
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </ViewLogContainer>
      <AddWorkflowRun
        show={show}
        onHide={() => {
          setShow(false);
        }}
        modalSize="lg"
        isRunAgain={true}
      />
    </>
  );
};
