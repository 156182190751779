import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2rem;
  .nav {
    display: flex;
    align-items: center;
    height: 100%;
  }
  a {
    text-decoration: none;
    color: #b1abff;
    font-weight: 500;
    font-size: 1rem;
  }
  .nav-item {
    margin-right: 2rem;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .active-link {
    border-bottom: 0.125rem solid #fff;

    a {
      color: #fff;
    }
  }
  .img-container {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
  }
`;
