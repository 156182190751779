import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import { FormTextArea } from "../../../../../../../../components/common/FormTextArea";
import { AddWorkflowRunModalContainer } from "./elements";
import { Button } from "../../../../../../../../components/common";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/hooks";
import {
  createWorkflowRun,
  projectSelector,
} from "../../../../../../../../redux/slices/projectSlice";

import { WorkflowRunSchema } from "../../../../../../../../utils/validation";

import CloseIcon from "../../../../../../../../assets/icons/close.svg";
import { WorkflowRuns } from "../../../../../../../../utils/types";

interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  modalSize?: string;
  isRunAgain?: boolean;
}
export const AddWorkflowRun = ({
  show,
  onHide,
  modalSize,
  isRunAgain,
}: CustomModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { workflowId, orgId, id } = useParams();

  const { workflow, isWorkflowRunLoading } = useAppSelector(projectSelector);

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(WorkflowRunSchema),
    defaultValues: {
      notes: "",
    },
  });

  const onRun = (data: { notes: string }) => {
    dispatch(
      createWorkflowRun(
        data,
        Number(workflowId),
        (workflowRun: WorkflowRuns) => {
          setTimeout(() => {
            if (isRunAgain) {
              navigate(
                `/org/${orgId}/project/${id}/workflows/${workflowId}/runs/${workflowRun?.pwr_id}`,
                {
                  state: {
                    pw_name: workflow?.pw_name,
                    pw_id: workflow?.pw_id,
                  },
                },
              );
            }
          }, 3000);
          onHide();
          setValue("notes", "");
        },
      ),
    );
  };
  useEffect(() => {
    if (show) {
      clearErrors();
    }
  }, [show]);

  return (
    <AddWorkflowRunModalContainer
      centered
      show={show}
      onHide={onHide}
      size={modalSize}
    >
      <Modal.Body className="workflow-run-modal-body">
        <div className="header">
          <div className="title">
            <div>Run {workflow?.pw_name} Manually</div>
            <button type="submit" onClick={onHide}>
              <img src={CloseIcon} alt="x" />
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onRun)}
          className="needs-validation"
          noValidate
        >
          <Controller
            control={control}
            render={({ field }) => (
              <FormTextArea
                {...field}
                id="notes"
                label="Add your notes here"
                errorMessage={errors.notes?.message}
                hasError={!!errors.notes}
                rows={6}
              />
            )}
            name="notes"
          />
          <div className="d-flex flex-row d-grid justify-content-end add-button-container mt-4">
            <div>
              <Button label="Cancel" type="reset" isCancel onClick={onHide} />
            </div>
            <div className="run-now-btn">
              <Button
                label="Run Now"
                type="submit"
                isLoading={isWorkflowRunLoading}
                isDisabled={!isDirty}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </AddWorkflowRunModalContainer>
  );
};
