import classNames from "classnames";
import React, { ButtonHTMLAttributes } from "react";
import { ButtonContainer } from "./elements";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  isLarge?: boolean;
  isLoading?: boolean;
  label?: string;
  id?: string;
  type?: "submit" | "reset" | "button" | undefined;
  autoComplete?: string;
  width?: string;
  icon?: string;
  isFullWidth?: boolean;
  isCancel?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  iconPosition?: string;
  buttonStyles?: React.CSSProperties;
}

export const Button = ({
  className,
  icon,
  isFullWidth,
  isLarge,
  isLoading,
  label,
  onClick,
  type,
  id,
  isCancel,
  isDisabled,
  width,
  iconPosition,
  buttonStyles,
}: ButtonProps) => {
  return (
    <ButtonContainer
      className={classNames(
        "align-items-center",
        "d-flex",
        "justify-content-center",
        className,
        {
          "py-2": isLarge,
          "w-100": isFullWidth,
          "is-cancel": isCancel,
        },
      )}
      style={{ width: width, ...buttonStyles }}
      onClick={
        isDisabled
          ? () => {
              // do nothing
            }
          : onClick
      }
      type={type}
      disabled={isDisabled}
      id={id}
    >
      {icon && iconPosition === "left" ? (
        <img alt="Icon" className="icon me-1" src={icon} />
      ) : null}
      {isLoading ? (
        <div
          className="spinner-border text-light me-2"
          role="status"
          style={{ width: "1.5rem", height: "1.5rem" }}
        />
      ) : null}
      {isLarge && isLoading ? null : (
        <span
          className={classNames("label", {
            "is-large": isLarge,
            "is-cancel": isCancel,
          })}
        >
          {label}
        </span>
      )}
      {icon && iconPosition === "right" ? (
        <img alt="Icon" className="icon ml-2" src={icon} />
      ) : null}
    </ButtonContainer>
  );
};
