import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "../../../../../../../../assets/icons/close.svg";
import TriggerIcon from "../../../../../../../../assets/icons/trigger.svg";
import searchIcon from "../../../../../../../../assets/icons/search.svg";
import ClockIcon from "../../../../../../../../assets/icons/clock.svg";
import TimeScheduleForm from "../../../AddWorkflowActions/components/Trigger/common/TimeScheduleForm";
import { SideBarMain } from "./elements";
import ApiRequest from "../../../../../../../../assets/icons/api-request-white.svg";
import { fieldsDataGenerator } from "../../../AddWorkflowActions/components/Trigger/common/FieldsDataGenerator";
import {
  FormValue,
  TriggerData,
  WorkflowSteps,
} from "../../../../../../../../utils/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { triggerSchema } from "../../../../../../../../utils/validation";
import ApiRequestForm from "../../../AddWorkflowActions/components/MakeAnAPIRequest/components/APIRequestForm";
import {
  projectSelector,
  saveWorkflowAction,
  setIsSelected,
  setSelectedNode,
} from "../../../../../../../../redux/slices/projectSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/hooks";
import { findLabelAndValue } from "../../../AddWorkflowActions/components/Trigger/common/FindLabelAndValue";

interface SidebarProp {
  selectedNode: any;
  workflowStep: WorkflowSteps;
}

//form initial values
const initialValues: FormValue = {
  httpMethod: { value: 1, label: "GET" },
  httpUrl: "",
  authUserName: "",
  authPassword: "",
  requestBody: "",
  requestHeaders: [
    { header: "User-Agent", value: "Lessy" },
    { header: "Content-Type", value: "application/json" },
  ],
};

const httpMethod = [
  { value: 1, label: "GET" },
  { value: 2, label: "POST" },
  { value: 3, label: "Delete" },
  { value: 4, label: "PUT" },
  { value: 5, label: "PATCH" },
];

export const SideBar = ({ selectedNode, workflowStep }: SidebarProp) => {
  const [showTimeSchedule, setShowTimeSchedule] = useState(false);
  const [scheduleType, setScheduleType] = useState(1);
  const [defaultFormValues, setDefaultFormValues] = useState(initialValues);
  const [currentNode, setCurrentNode] = useState<any>();
  const [rerenderKey, setRerenderKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [workflowId, setWorkflowId] = useState<number | undefined>();
  const { intervals, startFrom, Hours, Minutes } = fieldsDataGenerator();
  const isSidebar = true;
  const toggleTimeSchedule = () => {
    setShowTimeSchedule(!showTimeSchedule);
  };
  const { workflow, isTriggerLoading } = useAppSelector(projectSelector);
  const trigger = workflow?.workflowTrigger;
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TriggerData>({
    resolver: yupResolver(triggerSchema),
    defaultValues: {
      triggerType: 2,
      interval: null,
      startFrom: null,
      hrs: null,
      mins: null,
      cronExpression: "",
      scheduleType: 1,
    },
  });

  //this will create unique keys . it will help to rerender the sidebar whenever new node clicked
  const triggerRerender = () => {
    setRerenderKey((prevKey) => prevKey + 1);
  };

  //if any existing form values it will fetch and shows in fields
  useEffect(() => {
    if (!workflowStep?.api) {
      // Set default form values
      setDefaultFormValues({
        httpMethod: {
          value: httpMethod[0].value,
          label: httpMethod[0].label,
        },
        httpUrl: "",
        authUserName: "",
        authPassword: "",
        requestBody: "",
        requestHeaders: [
          { header: "User-Agent", value: "Less" },
          { header: "Content-Type", value: "application/json" },
        ],
      });
    } else {
      const makeRequest = workflowStep?.api;
      const method = httpMethod.find(
        (item) => item.label === makeRequest.piae_method,
      );
      if (method) {
        // Set default form values based on fetched data
        setDefaultFormValues({
          httpMethod: {
            value: method.value,
            label: method.label,
          },
          httpUrl: makeRequest.http_url,
          authUserName: makeRequest?.username,
          authPassword: makeRequest?.password,
          requestBody: JSON.parse(makeRequest?.body),
          requestHeaders: makeRequest?.headers || [], // Ensure requestHeaders is always an array
        });
      }
    }
  }, [workflowStep]);

  //whenever selected node changes for rerender the sidebar component
  useEffect(() => {
    setCurrentNode(selectedNode);
    triggerRerender();
    setWorkflowId(workflowStep?.pwst_id);
  }, [selectedNode, workflowStep]);

  useEffect(() => {
    if (trigger) {
      const { pwt_trigger_value } = trigger;
      const interval = findLabelAndValue({
        label: pwt_trigger_value?.simple.interval,
        type: "interval",
      });
      const startFrom = findLabelAndValue({
        label: pwt_trigger_value?.simple.start_at_day,
        type: "days",
      });
      const hrs = findLabelAndValue({
        label: pwt_trigger_value?.simple.start_at_time_hour,
        type: "hrs",
      });
      const mins = findLabelAndValue({
        label: pwt_trigger_value?.simple.start_at_time_min,
        type: "mins",
      });
      reset((formValues) => ({
        ...formValues,
        triggerType: 2,
        interval: interval ? interval : null,
        startFrom: startFrom ? startFrom : null,
        hrs: hrs ? hrs : null,
        mins: mins ? mins : null,
        scheduleType: 1,
        cronExpression: pwt_trigger_value?.cron ? pwt_trigger_value?.cron : "",
      }));
    }
  }, [workflow]);

  // Function to trigger re-render

  const onSave = (data: FormValue) => {
    setIsLoading(true);

    const apiRequestData = {
      ...data,
      workflow_step_id: workflowStep?.pwst_id,
    };
    if (
      apiRequestData?.httpMethod?.value == 1 ||
      apiRequestData?.httpMethod?.value == 3
    ) {
      apiRequestData.requestBody = "";
    }

    dispatch(
      saveWorkflowAction(apiRequestData, workflowId, () => {
        setIsLoading(false);
      }),
    );
  };

  return (
    <SideBarMain key={rerenderKey}>
      {currentNode?.data?.type === 0 ? (
        <div className="trigger">
          <div className="header">
            <div className="icon-container">
              <img
                className="icon-image"
                src={TriggerIcon}
                alt="trigger icon"
              />
              <span className="icon-text">Select a Trigger</span>
            </div>
            <div className="close-container">
              <img
                className="close-img"
                onClick={() => {
                  dispatch(setIsSelected(false));
                  dispatch(setSelectedNode(null));
                }}
                src={CloseIcon}
                alt="close icon"
              />
            </div>
          </div>
          <div className="input">
            <input placeholder="Search" className="input-field" type="text" />
            <img className="search-icon" src={searchIcon} alt="" />
          </div>

          <div className="schedule">
            <div className="time-schedule" onClick={toggleTimeSchedule}>
              <div className="icon-container">
                <img
                  className="schedule-icon"
                  src={ClockIcon}
                  alt="clock-icon"
                />
              </div>
              <span className="schedule-text">On a Scheduled Time</span>
            </div>
          </div>

          {showTimeSchedule && (
            <TimeScheduleForm
              control={control}
              scheduleType={scheduleType}
              setScheduleType={setScheduleType}
              setValue={setValue}
              errors={errors}
              formType={scheduleType === 1 ? "simple" : "cron"}
              intervals={intervals}
              startFrom={startFrom}
              Hours={Hours}
              Minutes={Minutes}
              isSidebar={isSidebar}
              isTriggerLoading={isTriggerLoading}
              handleSubmit={handleSubmit}
              workflow={workflow}
            />
          )}
        </div>
      ) : currentNode?.data?.type === 1 ? (
        <div className="action">
          <div className="header">
            <div className="icon-container">
              <div className="icon-container-api">
                <img src={ApiRequest} alt="apirequest" />
              </div>
              <span className="icon-text">Make an API Request</span>
            </div>
            <div className="close-container">
              <img
                className="close-img"
                onClick={() => {
                  dispatch(setIsSelected(false));
                  dispatch(setSelectedNode(null));
                }}
                src={CloseIcon}
                alt="close icon"
              />
            </div>
          </div>
          <div className="form">
            <ApiRequestForm
              isLoading={isLoading}
              isSidebar={isSidebar}
              defaultValues={defaultFormValues}
              onSubmit={onSave}
            />
          </div>
        </div>
      ) : null}
    </SideBarMain>
  );
};
