/* eslint-disable react/no-children-prop */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { Button, FormInput, ToastMessage } from "../../../../components/common";
import { SelectInput } from "../../../../components/common/SelectInput";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  organisationSelector,
  setUserType,
} from "../../../../redux/slices/organisationSlice";
import {
  fetchAllOrgUser,
  fetchAllUserType,
  inviteUser,
  setError,
  usersSelector,
} from "../../../../redux/slices/usersSlice";

import { inviteUserSchema } from "../../../../utils/validation";
import { UserList } from "./component";
import { UsersContainer } from "./elements";
import Loader from "../../../../components/common/Loader";
import { InviteUserModal } from "./component/InviteUserModal";

export const Users = () => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useAppDispatch();
  const { orgId } = useParams();

  const { users, userTypes, isLoading, error } = useAppSelector(usersSelector);
  const { usersOrganisation, userType } = useAppSelector(organisationSelector);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchAllOrgUser(Number(orgId)));
      // find current organisation
      const orgDetails = usersOrganisation?.find(
        (item: { organisation: { or_id: number } }) =>
          item.organisation.or_id === Number(orgId),
      );

      if (orgDetails) {
        // set organisation user type
        dispatch(setUserType(orgDetails?.ou_user_type));
      }
    }
  }, [orgId]);

  useEffect(() => {
    if (!userTypes?.length) {
      dispatch(fetchAllUserType());
    }
  }, []);

  const {
    control,
    setValue,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(inviteUserSchema),
    defaultValues: {
      userName: "",
      email: "",
      role: null,
    },
  });

  const clearFields = () => {
    setValue("userName", "");
    setValue("email", "");
    setValue("role", null);
    clearErrors("userName");
    clearErrors("email");
    clearErrors("role");
  };

  const onSubmit = (data: {
    userName: string;
    email: string;
    role: { label: string; value: number } | null;
  }) => {
    dispatch(
      inviteUser(data, Number(orgId), () => {
        setModalShow(false);
        clearFields();
      }),
    );
  };

  const Roles = useMemo(
    () =>
      userTypes?.map(
        (item: { ut_id: number; ut_name: string }) =>
          ({
            value: item.ut_id,
            label: item.ut_name,
          } || []),
      ),
    [userTypes],
  );
  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Users | {process.env.REACT_APP_NAME} </title>
      </Helmet>
      <UsersContainer>
        {users.length ? (
          <UserList
            onClick={() => {
              setModalShow(true);
            }}
            userType={userType}
          />
        ) : (
          <div className="button-container">
            {userType ? (
              <Button
                label="Invite Users"
                type="submit"
                isLoading={isLoading}
                className="mt-3"
                isFullWidth={false}
                isLarge={false}
                onClick={() => {
                  setModalShow(true);
                }}
              />
            ) : null}
          </div>
        )}
      </UsersContainer>

      <InviteUserModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          clearFields();
        }}
        dialogClassName="invite-user-modal"
        contentClassName="invite-user-content-modal"
        onClick={handleSubmit(onSubmit)}
        label="Invite"
        isLoading={isLoading}
        children={
          <form
            className="d-flex flex-column w-100 form"
            method="POST"
            noValidate
          >
            <div className="input-field">
              <Controller
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    id="user-name-id"
                    type="name"
                    label="Name"
                    width="100%"
                    placeholder="Name"
                    errorMessage={errors.userName?.message}
                    hasError={!!errors.userName}
                  />
                )}
                name="userName"
              />
            </div>
            <div className="input-field mt-3">
              <Controller
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    id="user-email-id"
                    type="email"
                    label="Email"
                    width="100%"
                    placeholder="Email"
                    errorMessage={errors.email?.message}
                    hasError={!!errors.email}
                  />
                )}
                name="email"
              />
            </div>
            <div className="input-field mt-3">
              <Controller
                control={control}
                render={({ field }) => (
                  <SelectInput
                    {...field}
                    errorMessage={errors.role?.message}
                    label="Role"
                    options={Roles}
                    placeholder="Select the Role"
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    id="role"
                  />
                )}
                name="role"
              />
            </div>
          </form>
        }
      />
      <ToastMessage
        isVisible={!!error}
        message={error}
        type="danger"
        position="top-center"
        onClose={() => {
          dispatch(setError(null));
        }}
      />
    </>
  );
};
