import styled from "styled-components";

export const CollapseAndExpandContainer = styled.button`
  background: transparent;
  outline: none;
  border: none;
  margin-bottom: 7rem;
  .inactive-icon {
    display: flex;
  }
  .active-icon {
    display: none;
  }
  :hover {
    .active-icon {
      display: flex;
    }
    .inactive-icon {
      display: none;
    }
  }
`;
