import styled from "styled-components";
import BackIcon from "../../../../../../assets/icons/back.svg";
import ConfigureIcon from "../../../../../../assets/icons/configure.svg";
import AttachIcon from "../../../../../../assets/icons/attach.svg";
import CodeIcon from "../../../../../../assets/icons/code.svg";
import TokenIcon from "../../../../../../assets/icons/token.svg";
import { Button } from "../../../../../../components/common";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../../redux/hooks";
import { projectSelector } from "../../../../../../redux/slices/projectSlice";

interface HeaderProps {
  onConfig: () => any;
}

const HeaderContainer = styled.div`
  background-color: #ffffff;
  padding: 2.5rem;

  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    margin-left: 1rem;
  }
  .back-btn {
    outline: none;
    border: none;
    background: transparent;
    padding: unset;
  }
  .title-container {
    display: flex;
    align-items: center;
  }
  .header-body {
    padding-top: 1.25rem;
    font-weight: 500;
    font-size: 1rem;
    color: #605f5f;
  }
  .header-footer {
    padding-top: 1.25rem;
    display: flex;
    align-items: center;
  }
  .swagger-btn,
  .token-btn {
    margin-left: 1.25rem;
  }
  .token-btn {
    span {
      font-weight: 600;
      font-size: 0.875rem;
      color: #655aec !important;
    }
  }
`;

export const Header = ({ onConfig }: HeaderProps) => {
  const navigate = useNavigate();
  const { api } = useAppSelector(projectSelector);
  return (
    <HeaderContainer>
      <div className="header-title">
        <div className="title-container">
          <button
            type="submit"
            onClick={() => {
              navigate(-1);
            }}
            className="back-btn"
          >
            <img src={BackIcon} alt="x" />
          </button>
          <div className="title">{api?.pia_title}</div>
        </div>
        <div>
          <Button
            label="Configure"
            icon={ConfigureIcon}
            iconPosition="left"
            onClick={onConfig}
          />
        </div>
      </div>
      <div className="header-body">{api?.pia_description}</div>
      <div className="header-footer">
        <div className="api-btn">
          <Button
            label={api?.pia_server_url}
            icon={AttachIcon}
            iconPosition="left"
            isCancel
            buttonStyles={{
              borderRadius: "0.5rem",
            }}
          />
        </div>

        {api?.pia_spec_url ? (
          <div className="swagger-btn">
            <a
              href={api?.pia_spec_url}
              className="swagger-link"
              target="_blank"
              rel="noreferrer"
            >
              <img src={CodeIcon} />
              <span className="label">Swagger</span>
            </a>
          </div>
        ) : null}
        {api?.pia_auth_method && api?.pia_auth_method === 2 ? (
          <div className="token-btn">
            <Button
              label="Bearer Token"
              icon={TokenIcon}
              iconPosition="left"
              buttonStyles={{
                background: "rgba(101, 90, 236, 0.1)",
                borderRadius: "0.5rem",
                border: "none",
                color: "#655AEC",
                padding: "0.7rem 0.875rem",
              }}
            />
          </div>
        ) : null}
      </div>
    </HeaderContainer>
  );
};
