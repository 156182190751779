import { useState } from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

import { CollapseAndExpand } from "../../../../../components/common";

import { SideNavBarContainer } from "./elements";

import UsersActiveIcon from "../../../../../assets/icons/users-active.svg";
import UsersIcon from "../../../../../assets/icons/users.svg";
import BasicDetailsActiveIcon from "../../../../../assets/icons/detail-active.svg";
import BasicDetailsIcon from "../../../../../assets/icons/details.svg";
import BillingsIcon from "../../../../../assets/icons/billings.svg";
import BillingsActiveIcon from "../../../../../assets/icons/billings-active.svg";

import { CollapseAndExpandProps } from "../../../../../utils/types";

export const SideNavBar = ({
  setCollapse,
  collapse,
}: CollapseAndExpandProps) => {
  const [active, setActive] = useState<any | null>("");
  const { pathname } = useLocation();

  return (
    <SideNavBarContainer
      className={classNames({
        "is-collapsed": collapse,
      })}
    >
      <Nav
        activeKey={active}
        onSelect={(selectedKey) => {
          setActive(selectedKey);
        }}
        className="d-flex flex-column nav-container"
      >
        <Nav.Item
          className={classNames({
            "active-link": pathname.includes("/basic"),
          })}
        >
          <NavLink
            to="basic"
            id="basic-details"
            className="d-flex align-items-center flex-column justify-content-center"
          >
            <div className="img-container">
              {pathname.includes("/basic") ? (
                <img src={BasicDetailsActiveIcon} alt="" />
              ) : (
                <img src={BasicDetailsIcon} alt="" />
              )}
            </div>
            {!collapse ? <div className="text">Basic Details</div> : null}
          </NavLink>
        </Nav.Item>

        <Nav.Item
          className={classNames({
            "active-link": pathname.includes("/users"),
          })}
        >
          <NavLink
            to="users"
            id="users"
            className="d-flex align-items-center flex-column justify-content-center"
          >
            <div className="img-container">
              {pathname.includes("/users") ? (
                <img src={UsersActiveIcon} alt="" />
              ) : (
                <img src={UsersIcon} alt="" />
              )}
            </div>
            {!collapse ? <div className="text">Users</div> : null}
          </NavLink>
        </Nav.Item>
        <Nav.Item
          className={classNames({
            "active-link": pathname.includes("/billing"),
          })}
        >
          <NavLink
            to="billing"
            id="billing"
            className="d-flex align-items-center flex-column justify-content-center"
          >
            <div className="img-container">
              {pathname.includes("/billing") ? (
                <img src={BillingsActiveIcon} alt="" />
              ) : (
                <img src={BillingsIcon} alt="" />
              )}
            </div>
            {!collapse ? <div className="text">Billing</div> : null}
          </NavLink>
        </Nav.Item>
        {/* <Nav.Item
          className={classNames("mt-1", {
            "active-link": pathname.includes("/setting-page"),
          })}
        >
          <NavLink to="setting-page" id="setting-page">
            Settings
          </NavLink>
        </Nav.Item> */}
      </Nav>
      <CollapseAndExpand collapse={collapse} setCollapse={setCollapse} />
    </SideNavBarContainer>
  );
};
