import { yupResolver } from "@hookform/resolvers/yup";
import { ReactElement, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "../../../../../../../assets/icons/close.svg";
import {
  Button,
  FormInput,
  SelectInput,
} from "../../../../../../../components/common";
import { FormTextArea } from "../../../../../../../components/common/FormTextArea";
import { RadioButton } from "../../../../../../../components/common/Radio";
import { configureSchema } from "../../../../../../../utils/validation";
import { ConfigureModalContainer } from "./elements";
import { APIResponse } from "../../../../../../../utils/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../redux/hooks";
import {
  configureAPI,
  projectSelector,
} from "../../../../../../../redux/slices/projectSlice";

interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  children?: ReactElement | null | undefined;
  modalSize?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  label?: string;
  title?: string;
  api: APIResponse | null;
}
// const Authentication = [
//   {
//     label: "No Authentication",
//     value: 0,
//   },
//   {
//     label: "Bearer Token",
//     value: 1,
//   },
// ];

interface FormValue {
  apiName: string | undefined;
  baseUrl: string | undefined;
  description: string | undefined;
  authentication:
    | { value: number | null; label: string | undefined }
    | null
    | undefined;
  apiKey: string | undefined;
  authenticationOption: string;
}

export const ConfigureModal = ({
  show,
  onHide,
  modalSize,
  api,
}: CustomModalProps) => {
  const dispatch = useAppDispatch();
  // const [isConfigureLoading, setIsConfigureLoading] = useState(false);
  const {
    handleSubmit,
    watch,
    clearErrors,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValue>({
    resolver: yupResolver(configureSchema),
    defaultValues: {
      apiName: "",
      baseUrl: "",
      description: "",
      authentication: null,
      apiKey: "",
      authenticationOption: "no",
    },
  });

  const { apiAuthMethods, isAPILoading } = useAppSelector(projectSelector);

  useEffect(() => {
    if (api) {
      reset((formValues) => ({
        ...formValues,
        apiName: api?.pia_title,
        baseUrl: api?.pia_server_url,
        description: api?.pia_description,
        authentication: api?.authorisation?.aam_id
          ? {
              value: api?.authorisation?.aam_id,
              label: api?.authorisation?.aam_auth_method,
            }
          : {
              value: 0,
              label: "Select the Authentication",
            },
        apiKey: api?.pia_common_auth_key,
        authenticationOption: api?.pia_common_auth === 1 ? "yes" : "no",
      }));
    }
  }, [api]);

  const clearFields = () => {
    setValue("apiName", "");
    setValue("baseUrl", "");
    setValue("description", "");
    setValue("apiKey", "");
    setValue("authenticationOption", "yes");
    setValue("authentication", null);
  };

  const onSubmit = (data: any) => {
    // setIsConfigureLoading(true);
    dispatch(
      configureAPI(data, api?.pia_id, () => {
        // setIsConfigureLoading(false);
        onHide();
        clearFields();
      }),
    );
  };

  useEffect(() => {
    if (show) {
      clearErrors();
    }
  }, [show]);

  const apiAuthMethodsOptions = useMemo(
    () =>
      apiAuthMethods?.map(
        (item: { aam_id: number; aam_auth_method: string }) =>
          ({
            value: item.aam_id,
            label: item.aam_auth_method,
          } || []),
      ),
    [apiAuthMethods],
  );

  return (
    <ConfigureModalContainer
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      size={modalSize}
    >
      <Modal.Body className="configure-modal-body">
        <div className="header">
          <div className="title">
            <div>Configure {api?.pia_title}</div>
            <button type="submit" onClick={onHide}>
              <img src={CloseIcon} alt="x" />
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="needs-validation"
          noValidate
        >
          <div className="fields-container">
            <div className="row first-row">
              <div className="col-sm">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      id="api-name-id"
                      label="API Name"
                      placeholder="Enter API Name"
                      errorMessage={errors?.apiName?.message}
                      hasError={!!errors.apiName}
                    />
                  )}
                  name="apiName"
                />
              </div>
              <div className="col-sm">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      id="base-url-id"
                      label="API Base Url"
                      placeholder="Enter Base URL"
                      errorMessage={errors?.baseUrl?.message}
                      hasError={!!errors.baseUrl}
                    />
                  )}
                  name="baseUrl"
                />
              </div>
            </div>
            <div className="col">
              <Controller
                control={control}
                render={({ field }) => (
                  <FormTextArea
                    {...field}
                    id="description"
                    label="API Description"
                    errorMessage={errors.description?.message}
                    hasError={!!errors.description}
                    rows={6}
                  />
                )}
                name="description"
              />
            </div>
            <div className="col">
              <div className="authentication-title">Authentication</div>
              <div className="w-50 authentication-field">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <SelectInput
                      {...field}
                      errorMessage={errors.authentication?.message}
                      label="Select The Authentication Method"
                      options={apiAuthMethodsOptions}
                      placeholder="Select the Authentication"
                      onChange={(e) => {
                        field.onChange(e);
                        if (watch("authentication")?.value === 1) {
                          setValue("authenticationOption", "no");
                        }
                      }}
                      id="authentication"
                    />
                  )}
                  name="authentication"
                />
              </div>
            </div>
            {watch("authentication")?.value === 2 ? (
              <>
                <div className="col-10 mt-4">
                  <div className="d-flex flex-column unit-type-input-container">
                    <span className="mb-2 unit-type-label">
                      Do you want to use a common API key to authenticate this
                      API?
                    </span>
                    <div className="align-items-center d-flex flex-grow-1">
                      <div className="yes-button">
                        <Controller
                          control={control}
                          name="authenticationOption"
                          render={(fields) => (
                            <RadioButton
                              {...fields}
                              hasError={!!errors.authenticationOption}
                              label="Yes"
                              value="yes"
                              onClick={() => {
                                setValue("authenticationOption", "yes");
                              }}
                              id="yes"
                              checked={watch("authenticationOption") === "yes"}
                            />
                          )}
                        />
                      </div>
                      <div className="no-button">
                        <Controller
                          control={control}
                          name="authenticationOption"
                          render={(fields) => (
                            <RadioButton
                              {...fields}
                              className="mr-4"
                              hasError={!!errors.authenticationOption}
                              label="No"
                              value="no"
                              onClick={() => {
                                setValue("authenticationOption", "no");
                                clearErrors("apiKey");
                              }}
                              id="no"
                              checked={watch("authenticationOption") === "no"}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col input-fields">
                  {watch("authenticationOption") === "yes" ? (
                    <div>
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            id="api-key-id"
                            label="API Key"
                            placeholder="Enter API Key"
                            errorMessage={errors?.apiKey?.message}
                            hasError={!!errors.apiKey}
                          />
                        )}
                        name="apiKey"
                      />
                    </div>
                  ) : (
                    <small id="apiKey" className="form-text text-muted">
                      If you don't choose the option mentioned above, the
                      Authentication process will rely on the token of the
                      logged-in user in your application. This means that you
                      will have to pass the token dynamically through the header
                      script when initializing the prompt UI.{" "}
                      <a href="">Read here for more info.</a>
                    </small>
                  )}
                </div>
              </>
            ) : null}
          </div>

          <div className="d-flex flex-row d-grid justify-content-end add-button-container">
            <div>
              <Button label="Cancel" type="reset" isCancel onClick={onHide} />
            </div>
            <div className="configure-api-btn">
              <Button
                label="Save Changes"
                type="submit"
                isLoading={isAPILoading}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </ConfigureModalContainer>
  );
};
