import styled from "styled-components";

export const CreateProjectContainer = styled.form`
  padding: 0 0.5rem;
  .input-field {
    .label {
      font-weight: 500;
      line-height: 24%;
    }
    .sub-label {
      line-height: 110%; /* 13.2px */
      letter-spacing: -0.0225rem;
    }
  }
  .input-container {
    height: 2.875rem;
  }
`;
