import styled from "styled-components";

export const AddWorkFlowContainer = styled.div`
  height: 100%;
  .title {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: Capitalize;
  }
  .add-button {
    border: 0.1rem solid #6862f5;
    background-color: transparent;
    border-radius: 0.25rem;
    padding: 0.325rem 0.5rem;
    display: grid;
    grid-template-columns: auto auto;
    span {
      color: #6862f5;
    }

    img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .actions-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #000000;
    padding-bottom: 2rem;
  }

  .border-div {
    height: 0.375rem;
    margin-left: 2.5rem;
    border-left: 0.0625rem solid #655aec;
  }
  .select-container {
    margin-top: 0.5rem;
    max-width: 18rem;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 0.0625rem solid #d0d5dd;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.03),
      0 1.25rem 0.5rem -0.25rem rgba(16, 24, 40, 0.08);
    /* padding: 0 1rem; */
    .add-action-field {
      border: none;
      outline: none;
      background: transparent;
      text-align: left;
      padding: 0.75rem 1rem;
      display: flex;
      align-items: center;
      :not(:last-child) {
        border-bottom: 0.0625rem solid #d0d5dd;
      }
      .action-text {
        color: #000;
        font-size: 0.875rem;
        font-weight: 500;
        margin-left: 1rem;
        font-weight: 500;
        line-height: 1.3125rem;
      }
      .icon-container {
        display: flex;
        height: 18px;
        width: 18px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .divider {
    border-bottom: 0.0625rem solid #eaecf0;
    margin-top: 1.5rem;
  }
  .modal-container {
    height: 100%;
    padding: 2.5rem;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .workflow-modal-body {
    padding: 0;
  }

  .add-button-container {
    margin-left: 2rem;

    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .add-actions-button {
      border-radius: 0.25rem;
      border: 0.0625rem solid rgba(101, 90, 236, 0.1);
      background: #fff;
      width: 100%;
      height: 100%;
      padding: 0.0625rem 0;
      .add-icon-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  form {
    border: 0.0625rem solid rgba(101, 90, 236, 0.1);
    border-radius: 0.5rem;
    background: #fff;
    /* padding: 1rem 1.5rem 1rem 1.5rem; */
    cursor: pointer;
    &.is-open {
      border: 0.0625rem solid #655aec !important;
      filter: drop-shadow(0rem 0.25rem 0.3125rem #f0eefd);
      /* box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12); */
      .header {
        border-radius: 0.5rem 0.5rem 0 !important;
      }
    }
    :hover,
    :active {
      border: 0.0625rem solid rgba(101, 90, 236, 0.1);
      filter: drop-shadow(0px 3px 5px #f0eefd);
    }

    .form-container {
      padding: 0rem 1.5rem 1rem 1.5rem;
      display: none;
      &.is-open {
        display: block;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    /* padding-bottom: 0.5rem; */
    button {
      padding: 0.407rem 0.962rem;
    }
  }
  .action-title {
    padding: 1.5rem 0;
    color: #000;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
  .button-divider {
    border-bottom: 0.0625rem solid #f1effc;
    margin: 1.5rem 0rem 1rem 0rem;
  }
  .header-container {
    height: 4.75rem;
    padding: 1rem 1.5rem;
  }
  .btn-divider {
    border-bottom: 0.0625rem solid #e3e5ea;
    margin-bottom: 1.5rem;
  }
  .button-container {
    button {
      padding: 0.438rem 0.875rem !important;
      line-height: 1.25rem;
      .label {
        font-weight: 600;
      }
    }
  }
`;
