import { OverlayTrigger, Popover } from "react-bootstrap";

import CollapseIcon from "../../../assets/icons/collapse.svg";
import CollapseActiveIcon from "../../../assets/icons/collapse-active.svg";
import ExpandActiveIcon from "../../../assets/icons/expand-active.svg";
import ExpandIcon from "../../../assets/icons/expand.svg";

import { CollapseAndExpandProps } from "../../../utils/types";

import { CollapseAndExpandContainer } from "./elements";

export const CollapseAndExpand = ({
  collapse,
  setCollapse,
}: CollapseAndExpandProps) => {
  const popoverHoverFocus = (
    <Popover
      id="popover-trigger-hover-focus popover-contained"
      title="Collapse Sidebar"
      className="popover-div"
    >
      {!collapse ? "Collapse Sidebar" : "Exapand Sidebar"}
    </Popover>
  );
  return (
    <>
      <CollapseAndExpandContainer
        type="button"
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        {!collapse ? (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={popoverHoverFocus}
            containerPadding={collapse ? 10 : 20}
          >
            <div>
              <img src={CollapseActiveIcon} alt="|||" className="active-icon" />
              <img src={CollapseIcon} alt="|||" className="inactive-icon" />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={popoverHoverFocus}
            containerPadding={collapse ? 10 : 20}
          >
            <div>
              <img src={ExpandActiveIcon} alt="|||" className="active-icon" />
              <img src={ExpandIcon} alt="|||" className="inactive-icon" />
            </div>
          </OverlayTrigger>
        )}
      </CollapseAndExpandContainer>
    </>
  );
};
