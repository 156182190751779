import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button } from "../../../../components/common";
// import { Table } from "../../../../components/common/Table";
import { Instructions } from "./components/Modal/Instructions";
import InstrcutionsIcon from "../../../../assets/icons/instruction.svg";

import { SettingsContainer } from "./elements";

// const logs = [
//   {
//     id: 1,
//     workflow: "Create_Employee",
//     customer: "McDonalds",
//     status: "Success",
//     time: "02:00 PM",
//     error: "-",
//   },
//   {
//     id: 2,
//     workflow: "Create_Employee",
//     customer: "McDonalds",
//     status: "Failed",
//     time: "02:01 PM",
//     error: "-",
//   },
// ];

export const Settings = () => {
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  // const logsColumn = useMemo(
  //   () => [
  //     {
  //       Header: "#",
  //       id: "index",
  //       accessor: (_row: any, i: number) => i + 1,
  //     },
  //     {
  //       Header: "Workflow",
  //       accessor: "workflow",
  //     },
  //     {
  //       Header: "Customer",
  //       accessor: "customer",
  //     },
  //     {
  //       Header: "Status",
  //       accessor: "status",
  //     },
  //     {
  //       Header: "Time",
  //       accessor: "time",
  //     },
  //     {
  //       Header: "Error",
  //       accessor: "error",
  //     },

  //     {
  //       Header: "",
  //       Cell: () => (
  //         <button className="view-logs-btn">
  //           <div className="view-logs">View logs</div>
  //         </button>
  //       ),
  //       accessor: "id",
  //     },
  //   ],
  //   [],
  // );
  return (
    <>
      <Helmet>
        <title>Settings | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <SettingsContainer>
        <div className="title-container d-flex justify-content-between align-items-center">
          <div className="title">
            Add {process.env.REACT_APP_NAME} Widget to your Website
          </div>
          <div>
            <Button
              label="Instructions"
              icon={InstrcutionsIcon}
              iconPosition="left"
              onClick={() => setShowInstructionsModal(true)}
            />
          </div>
        </div>
        {/* <div className="recent-activity">Recent Activity</div>
        <div className="table mt-3">
          <Table
            columns={logsColumn}
            data={logs}
            itemName="Recent Activity"
            type={1}
          />
        </div> */}
      </SettingsContainer>
      <Instructions
        show={showInstructionsModal}
        onHide={() => setShowInstructionsModal(false)}
        // modalSize="xl"
      />
    </>
  );
};
