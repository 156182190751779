import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { forwardRef, ReactElement } from "react";
import Select, { Props as SelectProps, components } from "react-select";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import TickIcon from "../../../../src/assets/icons/tick.png";

import { SelectContainer } from "./elements";

export interface Props extends SelectProps {
  className?: string;
  errorMessage?: string | undefined | null;
  isTooltipShown?: boolean;
  label?: string;
  id?: string;
  maxWidth?: string | undefined | null;
  width?: string;
  noOptionMessage?: string;
  customComponent?: ReactElement | undefined;
  placeholderColor?: string;
  borderColor?: string;
  isConfigure?: boolean;
}
export type SelectRef = React.RefObject<HTMLDivElement>;

export const SelectInput = forwardRef<any, Props>(
  (
    {
      className,
      errorMessage,
      isTooltipShown,
      label,
      id,
      maxWidth,
      width,
      noOptionMessage,
      customComponent,
      placeholderColor,
      borderColor,
      isConfigure,
      ...rest
    },
    ref,
  ) => {
    return (
      <SelectContainer
        className={classNames("d-flex", "flex-column", "w-100", className)}
        maxWidth={maxWidth}
        width={width}
        placeholderColor={placeholderColor}
      >
        {label ? (
          <label className="label" htmlFor={label}>
            {label}
          </label>
        ) : null}
        <Select
          {...rest}
          classNamePrefix={classNames("react-select", {
            "has-error": errorMessage,
            "is-navbar": borderColor,
            "is-configure": isConfigure,
          })}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              cursor: "pointer",
              borderRadius: 8,
              height: 44,
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              border: borderColor ? borderColor : "1px solid #D0D5DD",
              // ":hover": {
              //   transition: "borderColor 0.1s, boxShadow 0.1s",
              //   boxShadow:
              //     "0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem rgba(101, 90, 236, 0.1)",
              // },
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              border: "1px solid #D0D5DD",
              boxShadow:
                "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
              borderRadius: "8",
            }),
            option: (styles) => {
              return {
                ...styles,
                cursor: "pointer",
                color: "#FFFFFF",
              };
            },
            singleValue: (styles) => {
              return {
                ...styles,
                color: "#FFFFFF",
              };
            },
            placeholder: (baseStyles) => ({
              ...baseStyles,
              fontSize: "0.875rem",
              color: placeholderColor ? placeholderColor : "#c3cfd9",
            }),
            dropdownIndicator: (styles) => ({
              color: "#ffffff",
              width: "2rem",
              display: "flex",
              ":hover": {
                ...styles[":hover"],
                color: "#ffffff !important",
              },
            }),
            indicatorSeparator: () => ({ display: "none" }),
          }}
          noOptionsMessage={() => <div>{noOptionMessage}</div>}
          components={{
            Menu: ({ children, ...properties }: any) => {
              return (
                <components.Menu {...properties}>
                  <div>
                    <div>{children}</div>

                    {customComponent}
                  </div>
                </components.Menu>
              );
            },
            Option: ({ children, isSelected, ...properties }: any) => {
              return (
                <>
                  <components.Option {...properties}>
                    <div
                      className="align-items-center d-flex justify-content-between"
                      id="data"
                      {...(isTooltipShown && {
                        title: children,
                      })}
                    >
                      <span className="value">{children}</span>
                      {isSelected ? (
                        <img
                          alt="Selected"
                          className="tick-icon"
                          src={TickIcon}
                        />
                      ) : null}
                    </div>
                  </components.Option>
                </>
              );
            },
            // eslint-disable-next-line react/prop-types
            SingleValue: ({ children, ...properties }) => (
              <components.SingleValue {...properties}>
                <div
                  className="align-items-center d-flex"
                  data-for="single-value"
                  data-tip
                >
                  <span className="is-truncated selected-value">
                    {children}
                  </span>
                </div>
                {isTooltipShown ? (
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}
                  >
                    <span className="d-inline-block">
                      <Button disabled style={{ pointerEvents: "none" }}>
                        {children}
                      </Button>
                    </span>
                  </OverlayTrigger>
                ) : null}
              </components.SingleValue>
            ),
          }}
          maxMenuHeight={100}
          // hasError={hasError}
          ref={ref}
          id={id}
          theme={(currentTheme) => ({
            ...currentTheme,
            colors: {
              ...currentTheme.colors,
            },
          })}
        />
        <AnimatePresence>
          {errorMessage ? (
            <motion.span
              animate={{
                opacity: 1,
                translateY: 0,
              }}
              className="error-message position-relative"
              exit={{
                opacity: 0,
                translateY: "-0.25rem",
              }}
              initial={{
                opacity: 0,
                translateY: "-0.25rem",
              }}
              transition={{
                duration: 0.2,
                type: "keyframes",
              }}
            >
              {errorMessage}
            </motion.span>
          ) : null}
        </AnimatePresence>
      </SelectContainer>
    );
  },
);

SelectInput.displayName = "SelectInput";
