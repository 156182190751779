import styled from "styled-components";

export const ViewWorkflowContainer = styled.div`
  width: 100%;
  .title-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .workflow-run-container {
    padding: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .title {
    font-weight: 500;
    font-size: 1.5rem;
    color: #000000;
  }

  .view-logs-button {
    background-color: transparent;
    border: 0.0625rem solid rgb(101, 90, 236);
    padding: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
    font-weight: 500;
    color: #655aec;
    font-size: 0.75rem;
    line-height: normal;
    :hover {
      font-weight: 600;
      background-color: rgb(101, 90, 236, 0.1);
    }
    .text {
      margin-left: 0.375rem;
    }
  }
  .workflow-run-table {
    width: 100%;
    table {
      tr td:first-child {
        /* width: 0.1%; */
        padding-right: unset;
      }

      th {
        text-transform: uppercase;
        color: #667085;
        font-size: 1rem;
        font-weight: 500;
      }
      .triggered-by {
        color: #101828;
        font-size: 0.875rem;
        font-weight: 600;
        display: flex;

        .run-type {
          text-transform: lowercase !important;
          margin-left: 1rem;
        }
        .user-name {
          margin-left: 0.5rem;
        }
      }
    }
  }
  .divider {
    height: 0.0625rem;
    width: 100%;
    background: #eaecf0;
    margin: 0.875rem 0 2.0625rem 0;
  }
  .side-container {
    height: 90vh;
    padding: 0 0 0rem 7rem;
    overflow-y: auto;
    width: 100%;
    &.is-collapsed {
      padding: 0 0 0rem 5rem;
    }
  }
  .workflow-container {
    width: 100%;
  }
  .run-manual-button {
    .label {
      line-height: 1.25rem;
    }
  }
`;
