import styled from "styled-components";

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;

  .status-0,
  .status-1 {
    border-radius: 1.875rem;
    display: inline-block;
    font-size: 0.625rem;
    padding: 0.3125rem;
    font-weight: 500;
    min-width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
  }
  .status-0 {
    background-color: #fdf7ea;
    color: #ecb22e;
    border: 0.0625rem solid #ecb22e;
  }
  .status-1 {
    background-color: rgba(46, 173, 98, 0.1);
    color: #10a14b;
    border: 0.0625rem solid #10a14b;
  }

  @media (max-width: 75rem) {
    & {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
    .status-0,
    .status-1 {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.21;
      padding: 8px 16px;
    }
  }
`;
