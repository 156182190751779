import styled from "styled-components";

export const UsersContainer = styled.div`
  width: 100%;
  .form {
    padding: 3rem 4rem;
  }
  .input-field {
    width: 50%;
  }

  .button-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .add-button {
      padding: 0.625rem 1rem;
    }
  }
  .remove-button {
    display: flex;
    justify-content: flex-end;
  }
`;
