import * as yup from "yup";

export const loginSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email address.")
    .required("Enter your email address."),
  password: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .required("Enter your password."),
});

export const signUpSchema = yup.object({
  name: yup
    .string()
    .trim("Enter your full name.")
    .required("Enter your full name.")
    .max(50, "Full name must not be longer than 50 characters."),
  email: yup.string().email().required("Enter Your Email"),
  password: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .required("Enter your Password")
    .min(6, "Password must be atleast 6 characters long."),
  confirmPassword: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .oneOf([yup.ref("password")], "Passwords must match.")
    .required("Confirm your password."),
  accept: yup
    .boolean()
    .oneOf([true], "Accept the terms and privacy to continue"),
});

export const resetPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email address.")
    .required("Enter your email address."),
});

export const updatePasswordSchema = yup.object({
  password: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .min(6, "Password must be atleast 6 characters long.")
    .required("Enter your password."),
  confirmPassword: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .oneOf([yup.ref("password")], "Passwords must match.")
    .required("Confirm your password."),
  // accept: yup
  //   .boolean()
  //   .oneOf([true], "Accept the terms and privacy to continue"),
});

export const basicDetailsSchema = yup.object({
  organisationName: yup
    .string()
    .trim("Organisation Name cannot be empty ")
    .max(100, "Organisation Name must not be longer than 100 characters.")
    .required("Organisation Name cannot be empty"),
});

export const inviteUserSchema = yup.object({
  userName: yup
    .string()
    .trim("Name cannot be empty ")
    .required("Enter the name")
    .max(50, "Name must not be longer than 50 characters"),
  email: yup.string().email().required("Enter the EmailId"),
  role: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .required("Select the Role"),
});

export const activateAccountSchema = yup.object({
  password: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .min(6, "Password must be atleast 6 characters long.")
    .required("Enter your password."),
  confirmPassword: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .oneOf([yup.ref("password")], "Passwords must match.")
    .required("Confirm your password."),
  // accept: yup
  //   .boolean()
  //   .oneOf([true], "Accept the terms and privacy to continue"),
});

export const createProjectsSchema = yup.object({
  projectName: yup
    .string()
    .trim("Project Name cannot be empty")
    .max(100, "Project Name must not be longer than 100 characters.")
    .required("Project Name cannot be empty"),
});

export const createApiSchema = yup.object({
  name: yup.string().required("Enter name for this API"),
  baseUrl: yup.string().required("Enter base url"),
  description: yup.string().required("Enter description for url"),
});

export const createWorkFlowSchema = yup.object({
  workflowName: yup
    .string()
    .trim("WorkFlow Name cannot be empty")
    .max(100, "WorkFlow Name must not be longer than 100 characters.")
    .required("WorkFlow Name cannot be empty"),
});
export const resetPassword = yup.object({
  currentPassword: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .required("Enter your password."),
  password: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .required("Enter new password."),
  confirmPassword: yup
    .string()
    .trim("Password cannot be empty or spaces")
    .oneOf([yup.ref("password")], "Passwords must match.")
    .required("Confirm new password."),
});

export const MakeAPIRequestSChema = yup.object({
  httpMethod: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .required("Select the HTTP method"),
  httpUrl: yup
    .string()
    .url("HTTP url mush e valid")
    .required("Enter valid http url"),

  authUserName: yup.string().trim("Enter your auth user name.").notRequired(),
  authPassword: yup
    .string()
    .trim("Enter your auth user Password.")
    .notRequired(),
  // requestBody: yup.string().when(["httpMethod"], {
  //   is: (httpMethod: { value: string; label: string }) =>
  //     httpMethod.label == "PUT" ||
  //     httpMethod.label == "PATCH" ||
  //     httpMethod.label == "POST",
  //   then: (configureSchema) => configureSchema.required("Body cannot be empty"),
  //   otherwise: (configureSchema) => configureSchema.notRequired(),
  // }),
  requestBody: yup.string().notRequired(),
  requestHeaders: yup
    .array()
    .of(
      yup.object({
        header: yup.string().required("Header cannot be empty"),
        value: yup.string().required("Value cannot be empty"),
      }),
    )
    .required(),
});

export const ShowMessageSchema = yup.object({
  title: yup
    .string()
    .trim("Enter your Title")
    .required("Enter your Title")
    .max(150, "Title must not be longer than 150 characters."),
  message: yup.string().required("Message cannot be empty"),
});

export const GoToUrlSchema = yup.object({
  url: yup.string().trim("Enter your Title").required("Enter your Title"),
});

export const configureSchema = yup.object({
  apiName: yup.string().required("Enter name for this API"),
  baseUrl: yup.string().required("Enter base url"),
  description: yup.string().required("Enter description for url"),
  authentication: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .required("Select the authentication"),
  authenticationOption: yup
    .string()
    .oneOf(["yes", "no"], "Common API Key must be yes or no")
    .required("Common API Key must be yes or no"),

  apiKey: yup.string().when(["authenticationOption"], {
    is: (authenticationOption: string) => authenticationOption === "yes",
    then: (configureSchema) => configureSchema.required("Enter your API Key"),
    otherwise: (configureSchema) => configureSchema.notRequired(),
  }),
});

export const WorkflowRunSchema = yup.object({
  notes: yup.string().required("Notes cannot be empty"),
});

export const triggerSchema = yup.object({
  triggerType: yup.number().oneOf([1, 2], "Trigger must be manual or schedule"),
  scheduleType: yup.number().oneOf([1, 2]),
  interval: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .when(["triggerType"], {
      is: (type: number) => type === 2,
      then: (triggerSchema) =>
        triggerSchema.when(["scheduleType"], {
          is: (type: number) => type === 1,
          then: (triggerSchema) => triggerSchema.required("Select interval"),
          otherwise: (triggerSchema) => triggerSchema.notRequired(),
        }),
    }),
  startFrom: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .when(["triggerType"], {
      is: (type: number) => type === 2,
      then: (triggerSchema) =>
        triggerSchema.when(["scheduleType"], {
          is: (type: number) => type === 1,
          then: (triggerSchema) => triggerSchema.required("Select start from"),
          otherwise: (triggerSchema) => triggerSchema.notRequired(),
        }),
    }),
  hrs: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .when(["triggerType"], {
      is: (type: number) => type === 2,
      then: (triggerSchema) =>
        triggerSchema.when(["scheduleType"], {
          is: (type: number) => type === 1,
          then: (triggerSchema) => triggerSchema.required("Select Hours"),
          otherwise: (triggerSchema) => triggerSchema.notRequired(),
        }),
    }),
  mins: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .when(["triggerType"], {
      is: (type: number) => type === 2,
      then: (triggerSchema) =>
        triggerSchema.when(["scheduleType"], {
          is: (type: number) => type === 1,
          then: (triggerSchema) => triggerSchema.required("Select Minutes"),
          otherwise: (triggerSchema) => triggerSchema.notRequired(),
        }),
    }),

  cronExpression: yup.string().when(["triggerType"], {
    is: (type: number) => type === 2,
    then: (triggerSchema) =>
      triggerSchema.when(["scheduleType"], {
        is: (type: number) => type === 2,
        then: (triggerSchema) =>
          triggerSchema.required("Enter the Cron Expression"),
        otherwise: (triggerSchema) => triggerSchema.notRequired(),
      }),
  }),
});
