/* eslint-disable react/no-children-prop */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";

import {
  Product,
  billingSelector,
  getPrices,
  getSubscription,
  subscribe,
} from "../../../../redux/slices/billingSlice";

import RecommendedImage from "../../../../assets/icons/billing-image.svg";
import TickIcon from "../../../../assets/icons/tick-voilet.svg";
import RecommendedTickIcon from "../../../../assets/icons/tick-white.svg";

import { BillingContainer } from "./elements";
import Loader from "../../../../components/common/Loader";

import { SubscriptionPlan } from "../../../../utils/types";
import { PlanAndBilling } from "./components";

export const Billing = () => {
  const dispatch = useDispatch();
  const [lookupKey, setLookupKey] = useState("team_year");
  const [subscribedPlan, setSubscribedPlan] = useState<SubscriptionPlan | null>(
    null,
  );

  const { products, isLoading } = useSelector(billingSelector);

  useEffect(() => {
    dispatch(getPrices());
    dispatch(
      getSubscription((subscription: SubscriptionPlan) => {
        if (subscription.plan === "basic") {
          setSubscribedPlan(null);
        } else {
          setSubscribedPlan(subscription);
        }
      }),
    );
  }, []);

  const showPlan = (product: Product) => {
    const price = product?.prices.find(
      (price) => price.lookup_key === lookupKey,
    );

    let planPrice, output;
    switch (product?.name) {
      case "Basic":
        planPrice = 0;

        // per month cost on yearly package
        if (lookupKey === "team_year") {
          planPrice = planPrice / 12;
        }
        output = (
          <div className="basic-plan-period">
            <div className="basic d-flex">
              <div className="symbol">$</div>
              <div className="cost">{planPrice}</div>
            </div>
            <div className="period">
              <div className="slash">/</div>
              <div className="period-text">
                {lookupKey === "team_year" ? "month" : "month"}
              </div>
            </div>
          </div>
        );
        break;
      case "Team":
        if (price) {
          planPrice = price?.unit_amount / 100;

          // per month cost on yearly package
          if (lookupKey === "team_year") {
            planPrice = planPrice / 12;
          }
        }

        output = (
          <div className="team-plan-period">
            <div className="team d-flex">
              <div className="symbol">
                <div className="text">$</div>
              </div>
              <div className="cost">{planPrice}</div>
            </div>
            <div className="period">
              <div className="slash">/</div>
              <div className="period-text">
                {lookupKey === "team_year" ? "month" : "month"}
              </div>
              {lookupKey === "team_year" ? (
                <div className="billed-yearly">
                  <div className="text">Billed Yearly</div>
                </div>
              ) : null}
            </div>
          </div>
        );
        break;

      default:
        output = <div className="enterprise">Custom</div>;
        break;
    }
    return output;
  };

  const textBasedOnPlan = (planName: string) => {
    switch (planName) {
      case "Basic":
        return "Current Plan";
      case "Team":
        return "Choose Plan";
      case "Enterprise":
        return "Contact With Us";
      default:
        return;
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>Billing | {process.env.REACT_APP_NAME} </title>
      </Helmet>
      {subscribedPlan ? (
        <PlanAndBilling
          planName={subscribedPlan.planName}
          price={subscribedPlan.price}
          plan={subscribedPlan.plan}
          id={subscribedPlan.id}
          customer={subscribedPlan.customer}
          paymentFrequency={subscribedPlan.paymentFrequency}
        />
      ) : (
        <BillingContainer>
          <div className="header">
            <div className="title">Unlock premium features with paid plan</div>
            <div className="sub-title">
              <div>
                We present to you 2 premium plans which will unleash the full
                potential of lessy
              </div>
            </div>
          </div>
          {/* 
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            checked={lookupKey === "team_monthly"}
            onChange={() => setLookupKey("team_monthly")}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            Monthly
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            checked={lookupKey === "team_yearly"}
            onChange={() => setLookupKey("team_yearly")}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault2">
            Yearly
          </label>
        </div> */}
          <div className="switch-button-container">
            <div className="switch-tabs-container">
              <button
                className={classNames("switch-tab", {
                  "is-yearly": lookupKey === "team_year",
                })}
                onClick={() => setLookupKey("team_year")}
              >
                <div className="text">Yearly</div>
              </button>
              <button
                className={classNames("switch-tab", {
                  "is-monthly": lookupKey === "team_month",
                })}
                onClick={() => setLookupKey("team_month")}
              >
                <div className="text">Monthly</div>
              </button>
            </div>
          </div>
          <div className="main-billing-container">
            <div className="billing-container">
              {products?.map((product) => {
                return (
                  <div
                    key={product.id}
                    className={classNames("billing-card", {
                      "is-team": product.name == "Team",
                      "is-basic": product.name == "Basic",
                    })}
                  >
                    {/* <form action="/create-checkout-session" method="POST"> */}
                    <div>
                      <div>
                        <div>
                          {product.name === "Team" ? (
                            <div className="team-header">
                              <div className="d-flex justify-content-between">
                                <div className="product-name">
                                  {product.name}
                                </div>
                                <div className="header-left">
                                  <img src={RecommendedImage} alt="" />

                                  <div className="tag">
                                    <div>Recommended</div>
                                  </div>
                                </div>
                              </div>
                              <div className="show-plan-team">
                                {showPlan(product)}
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="product-name">{product.name}</div>
                              <div className="show-plan">
                                {showPlan(product)}
                              </div>
                            </>
                          )}
                        </div>
                        {product.name === "Team" ? (
                          <div className="team-divider">
                            <div className="divider" />
                          </div>
                        ) : (
                          <div className="divider" />
                        )}
                      </div>
                      <div className="features-div">
                        {Object.keys(product.metadata).map((key) => (
                          <div key={key} className="features-list">
                            <div>
                              <img
                                src={
                                  product.name === "Team"
                                    ? RecommendedTickIcon
                                    : TickIcon
                                }
                                alt=""
                              />
                            </div>
                            <div className="text">{product.metadata[key]}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <input
                      type="hidden"
                      name={product.name}
                      value={product.name}
                    />
                    <button
                      id="checkout-and-portal-button"
                      className={classNames("plan-button", {
                        "is-team": product.name == "Team",
                        "is-basic": product.name == "Basic",
                      })}
                      disabled={product.name === "Basic"}
                      type="submit"
                      onClick={() => {
                        const price = product?.prices.find(
                          (price) => price.lookup_key === lookupKey,
                        );

                        if (price) {
                          setLookupKey(price?.lookup_key);

                          dispatch(
                            subscribe(price?.lookup_key, (redirect_url) => {
                              window.location.replace(redirect_url);
                            }),
                          );
                        }
                      }}
                    >
                      <div className="plan-btn-text">
                        {textBasedOnPlan(product.name)}
                      </div>
                    </button>
                    {/* </form> */}
                  </div>
                );
              })}
            </div>
          </div>
        </BillingContainer>
      )}
    </>
  );
};
