import React from "react";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";

import { LandingPage } from "./pages/Landing";
import { ActivateAccount, Verification } from "./pages/Auth/pages";
import { AuthRoute } from "./Routes/Auth";
import { NotFound } from "./pages/404";
import { UpdatePassword } from "./pages/Auth/pages/UpdatePassword";
import { MainLayout } from "./pages/MainLayout";

function Router() {
  return (
    <HelmetProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute path="LoginRoute">
                  <LandingPage />
                </PrivateRoute>
              }
            />
            <Route path="/*" element={<MainLayout />} />
            <Route path="/auth/set-password" element={<UpdatePassword />} />
            <Route
              path="/auth/*"
              element={
                <PrivateRoute path="LoginRoute">
                  <AuthRoute />
                </PrivateRoute>
              }
            />

            <Route path="/accept-invitation" element={<ActivateAccount />} />
            <Route path="/auth/verify" element={<Verification />} />
            <Route path="*" element={<NotFound to="/" />} />
          </Routes>
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default Router;
